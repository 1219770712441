import Empty from "@/components/Empty";
import Page from "@/components/page";
import { RouterOutputs, trpc } from "@/helpers/trpc";
import { Button } from "@heffl/ui/components/primitives/button";
import { Card } from "@heffl/ui/components/primitives/card";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@heffl/ui/components/primitives/grid-table";
import MiniRichTextEditor from "@heffl/ui/components/primitives/mini-rich-text-editor";
import { TableHeader } from "@heffl/ui/components/primitives/table";

import { ContactCard } from "@/pages/crm/deals/details";
import {
  capitalize,
  cn,
  downloadFile,
  formatCurrency,
  formatValue,
  isMobile,
} from "@heffl/ui/lib/utils";

import DataTable from "@/components/DataTable";
import DropMenu from "@/components/DropMenu";
import { getS3URL } from "@/helpers/s3Helpers";
import usePermissions from "@/lib/hooks/usePermissions";
import Expenses from "@/pages/projects/details/components/Expenses";
import {
  FileCard,
  UploadFileModalNew,
} from "@/pages/projects/details/components/Files";
import Invoices from "@/pages/projects/details/components/Invoices";
import { downloadPdf } from "@/pages/sales/invoices/details";
import calculateInvoice from "@heffl/server/src/helpers/lineItems/calculateInvoice";
import { calculateLineItems } from "@heffl/server/src/helpers/lineItems/calculateLineItems";
import Schemas from "@heffl/server/src/schemas";
import enums from "@heffl/server/src/schemas/enums";
import TagsInput from "@heffl/ui/components/TagInput";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import TabsInput from "@heffl/ui/components/primitives/TabsInput";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@heffl/ui/components/primitives/accordion";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { copyToClipboard } from "@heffl/ui/components/primitives/copy-to-clipboard";
import Select from "@heffl/ui/components/primitives/creatable-select";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import { Input } from "@heffl/ui/components/primitives/input";
import RenderHtml from "@heffl/ui/components/render-html";
import NextTabs from "@heffl/ui/components/tabs";
import TemplateRender from "@heffl/ui/components/template-render";
import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";
import { makeEllipsis } from "@heffl/ui/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import dayjs from "dayjs";
import humanizeDuration from "humanize-duration";
import { debounce } from "lodash";
import {
  ArrowUpRightFromSquare,
  Banknote,
  Calendar,
  CalendarDays,
  CheckCircle,
  CircleDollarSign,
  CircleUserRound,
  Copy,
  Download,
  File,
  FileText,
  Hammer,
  Loader,
  MapPin,
  MoreHorizontal,
  Navigation,
  Pencil,
  Plus,
  Printer,
  RefreshCcw,
  StickyNote,
  Timer,
  Trash2,
  User,
  User2,
  XCircle,
} from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { rrulestr } from "rrule";
import { z } from "zod";
import {
  generateGoogleMapsLink,
  openGoogleMaps,
} from "../components/property-selector";
import ScheduleDetailsModal, {
  AddScheduleDrawer,
  calculateWorkerTime,
  scheduleStatusOptions,
} from "../schedules/components/schduleDetailsModal";
import { jobStatusOptions } from "./components/job-form";
import JobCloseModal from "./components/jobCloseModal";
import { getJobId, ScheduleInvoiceBadge } from "./list";
import RenderTimeline from "@/helpers/userActivity/render-timeline";

export const getDateDuration = (start: Date, end: Date): string => {
  const shortEnglishHumanizer = humanizeDuration.humanizer({
    language: "shortEn",
    languages: {
      shortEn: {
        y: () => "y",
        mo: () => "mo",
        w: () => "w",
        d: () => "d",
        h: () => "h",
        m: () => "m",
        s: () => "s",
        ms: () => "ms",
      },
    },
  });
  return shortEnglishHumanizer(dayjs(end).diff(dayjs(start)), {
    units: ["h", "m"],
    delimiter: " ",
    spacer: "",
    maxDecimalPoints: 0,
    round: true,
  });
};

type FsSchedule =
  RouterOutputs["fieldService"]["schedules"]["list"]["schedules"][number];

interface ScheduleStatusBadgeProps {
  schedule: { status: FsSchedule["status"] };
  iconOnly?: boolean;
  small?: boolean;
}

export const JobStatusBadge = ({
  status,
  iconOnly = false,
  small = false,
}: {
  status: z.infer<typeof enums.jobStatus>;
  iconOnly?: boolean;
  small?: boolean;
}) => {
  const {
    icon: Icon,
    label,
    hex,
  } = jobStatusOptions.find((s) => s.value === status) || jobStatusOptions[0];
  return iconOnly ? (
    <Icon
      className="w-4 h-4 rounded-full bg-white p-0.5"
      style={{ color: hex, borderColor: hex, borderWidth: 1 }}
    />
  ) : (
    <Badge icon={Icon} background={hex} small={small}>
      {label}
    </Badge>
  );
};

export const ScheduleStatusBadge: React.FC<ScheduleStatusBadgeProps> = ({
  schedule,
  iconOnly = false,
  small = false,
}) => {
  const {
    icon: Icon,
    label,
    hex,
  } = scheduleStatusOptions.find((s) => s.value === schedule.status) ||
  scheduleStatusOptions[0];
  return iconOnly ? (
    <Icon
      className="w-4 h-4 rounded-full bg-white p-0.5"
      style={{ color: hex, borderColor: hex, borderWidth: 1 }}
    />
  ) : (
    <Badge icon={Icon} background={hex} small={small}>
      {label}
    </Badge>
  );
};

const ScheduleCard = ({
  schedule,
  onClick,
}: {
  schedule: FsSchedule;
  onClick: () => void;
}) => {
  const isDelayed =
    schedule.status !== "COMPLETED" &&
    dayjs(schedule.startDate).isBefore(dayjs());

  const isToday = dayjs().isSame(dayjs(schedule.startDate), "day");

  const workerTime = calculateWorkerTime(schedule.fsScheduleStatusTimeline);

  return (
    <div
      className="flex flex-col gap-2 p-3 bg-gray-50 rounded-lg cursor-pointer hover:bg-gray-100"
      onClick={onClick}
    >
      <div className="flex gap-2">
        <div className="flex flex-col gap-1 w-full">
          <div className="flex flex-col justify-between w-full sm:flex-row">
            <div className="flex gap-1 items-center w-7/12 text-base font-medium">
              {schedule.fsJobs.fsJobServices
                .map((service) => service.name)
                .join(", ")}
            </div>
            <p
              className={cn(
                "text-sm font-semibold flex items-center gap-1",
                isDelayed && "text-red-500",
                isToday && "text-green-600"
              )}
            >
              {dayjs(schedule.startDate).format("MMM DD, YYYY")} -{" "}
              <span className="flex gap-1 items-center font-medium text-gray-600">
                {!schedule.timeDisabled &&
                  dayjs(schedule.startDate).format("hh:mm A")}
                <span className="flex items-center text-orange-600">
                  (<Timer className="w-4 h-4" />
                  {workerTime
                    ? workerTime.workDuration
                    : schedule.timeDisabled
                    ? "Anytime"
                    : getDateDuration(schedule.startDate, schedule.endDate)}
                  )
                </span>
              </span>
            </p>
          </div>

          <div className="flex flex-col gap-1 justify-between items-start w-full sm:flex-row">
            <div className="flex flex-wrap gap-1 items-start sm:flex-row sm:items-center">
              {schedule.status === "CONFIRMED" && (
                <Badge variant="solidSuccess" icon={CheckCircle}>
                  CONFIRMED
                </Badge>
              )}
              <ScheduleInvoiceBadge schedule={schedule} />
              {schedule.fsScheduleTags.map((tag) => (
                <Badge key={tag.id} background={tag.tags.color}>
                  {tag.tags.name}
                </Badge>
              ))}
            </div>
            {!schedule.fsScheduleAssignees.length && (
              <Badge variant="error" icon={User}>
                Not assigned
              </Badge>
            )}
            {schedule.fsScheduleAssignees.length > 0 && (
              <TagsInput
                className="flex-wrap justify-end mt-2"
                small
                value={schedule.fsScheduleAssignees.map(
                  (assignee) => assignee.userId
                )}
                tags={schedule.fsScheduleAssignees.map((assignee) => ({
                  id: assignee.userId,
                  name: assignee.users.firstName,
                  icon: CircleUserRound,
                }))}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const Documents = ({ fsJobId }: { fsJobId: number }) => {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const documentPrintRef = useRef<HTMLDivElement>(null);

  const [selectedTemplate, setSelectedTemplate] = useState<number | "all">(
    "all"
  );
  const [showAddDocumentModal, setShowAddDocumentModal] = useState(false);
  const [printDocumentId, setPrintDocumentId] = useState<number | null>(null);

  const { data: jobTemplates } = trpc.templates.list.useQuery({
    type: "JOB",
  });
  const { data: printDocument, isFetching: printingLoading } =
    trpc.documents.details.useQuery(printDocumentId!, {
      enabled: !!printDocumentId,
    });

  const { data: documents, isLoading: documentsLoading } =
    trpc.documents.list.useQuery({
      fsJobId: fsJobId,
      templateId: selectedTemplate === "all" ? undefined : selectedTemplate,
    });

  const print = useReactToPrint({
    content: () => documentPrintRef.current,
    documentTitle: printDocument?.name || "Job Document",
  });

  const documentDeleteMutation = trpc.documents.delete.useMutation({
    onSuccess: () => {
      toast.success("Document deleted successfully");
    },
  });

  useEffect(() => {
    if (printDocument && !printingLoading && printDocumentId) {
      print();
      setTimeout(() => {
        setPrintDocumentId(null);
      }, 200);
    }
  }, [printDocument, printingLoading, print, printDocumentId]);

  return (
    <Card
      title="Documents"
      suffix={
        <Button
          icon={Plus}
          variant="primary"
          onClick={() => setShowAddDocumentModal(true)}
        >
          Document
        </Button>
      }
    >
      <div ref={documentPrintRef} className="hidden print:block">
        {printDocument && (
          <TemplateRender
            contentHtml={printDocument.contentInjected}
            template={printDocument.documentTemplates}
            previewMode={false}
          />
        )}
      </div>
      <AddDocumentModal
        open={showAddDocumentModal}
        onClose={() => setShowAddDocumentModal(false)}
        fsJobId={fsJobId}
        type="JOB"
      />
      <TabsInput
        value={selectedTemplate.toString()}
        onChange={(v) => setSelectedTemplate(v === "all" ? "all" : Number(v))}
        options={[
          { value: "all", label: "All" },
          // @ts-ignore
          ...(jobTemplates?.map((template) => ({
            value: template.id,
            label: template.name,
          })) || []),
        ]}
      />

      <DataTable
        className="mt-4"
        data={documents || []}
        onRowClick={(row) => navigate(`/documents/edit/${row.id}`)}
        columns={[
          {
            title: "Id",
            dataIndex: "id",
            className: "w-[70px]",
            render: (row) => <p>#{row.number}</p>,
          },
          {
            title: "Name",
            dataIndex: "name",
          },
          {
            title: "Template",
            dataIndex: "templateId",
            render: (row) => (
              <Badge variant="unique">
                {makeEllipsis(row.documentTemplates.name, 15)}
              </Badge>
            ),
          },
          {
            title: "Created",
            dataIndex: "createdAt",
            render: (row) =>
              dayjs(row.createdAt).format("MMM DD, YYYY hh:mm A"),
          },
          {
            title: "Actions",
            render: (row) => (
              <div className="flex gap-2">
                <Button
                  loading={printingLoading}
                  onClick={(e) => {
                    e.stopPropagation();
                    setPrintDocumentId(row.id);
                  }}
                  icon={Printer}
                  size="icon"
                  variant="ghost"
                />

                <Button
                  onClick={async (e) => {
                    e.stopPropagation();
                    await downloadPdf({
                      name: row.name,
                      url: `print?document=${row.uuid}`,
                    });
                  }}
                  icon={Download}
                  size="icon"
                  variant="ghost"
                />

                <Button
                  onClick={async (e) => {
                    e.stopPropagation();
                    const confirmed = await confirm({
                      title: "Are you sure you want to delete this document?",
                      body: "This action cannot be undone",
                    });
                    if (confirmed) {
                      documentDeleteMutation.mutate(row.id);
                    }
                  }}
                  icon={Trash2}
                  iconClassName="text-red-500"
                  size="icon"
                  variant="ghost"
                />
              </div>
            ),
          },
        ]}
        loading={documentsLoading}
        rowKey="id"
        empty={{
          icon: FileText,
          title: "No documents",
          description: "Add a document to get started",
        }}
      />
    </Card>
  );
};

export const AddDocumentModal = ({
  open,
  fsJobId,
  onClose,
  type,
}: {
  type: z.infer<typeof enums.templateTypes>;
  open: boolean;
  fsJobId?: number;
  onClose: (documentId?: number) => void;
}) => {
  const navigate = useNavigate();

  const form = useForm<z.infer<typeof Schemas.documents.document>>({
    resolver: zodResolver(Schemas.documents.document),
  });

  const { data: documentTemplates } = trpc.templates.list.useQuery({
    type: type,
  });

  const addDocumentMutation = trpc.documents.add.useMutation({
    onSuccess(newDocument) {
      onClose(newDocument.id);
      navigate(`/documents/edit/${newDocument.id}`);
    },
  });

  return (
    <ModalDrawer
      title="Add document"
      open={open}
      onClose={() => onClose(undefined)}
      modalClassName="max-w-[400px]"
    >
      <Form
        {...form}
        onSubmit={(values) => {
          addDocumentMutation.mutate({
            ...values,
            fsJobId: fsJobId,
          });
        }}
      >
        <FormField label="Template" name="templateId">
          <Select
            options={
              documentTemplates?.map((template) => ({
                value: template.id,
                label: template.name,
              })) || []
            }
          />
        </FormField>
        <FormField label="Name" name="name">
          <Input placeholder="Document name" />
        </FormField>

        <div className="self-end space-x-2">
          <Button>Cancel</Button>
          <Button
            loading={addDocumentMutation.isLoading}
            type="submit"
            variant="primary"
          >
            Add document
          </Button>
        </div>
      </Form>
    </ModalDrawer>
  );
};

const JobDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const fsJobId = Number(params.id);
  const confirm = useConfirm();

  const permissions = usePermissions();

  const [scheduleType, setScheduleType] = useState<
    "PENDING" | "COMPLETED" | "CANCELLED"
  >("PENDING");
  const [scheduleDetailsId, setScheduleDetailsId] = useState<number | null>(
    null
  );
  const [showAddScheduleDrawer, setShowAddScheduleDrawer] = useState(false);
  const [showFinance, setShowFinance] = useState<"invoices" | "expenses">(
    "invoices"
  );
  const [showFiles, setShowFiles] = useState(false);
  const [showPendingSchedulesModal, setShowPendingSchedulesModal] =
    useState(false);

  const { data: team } = trpc.teams.currentTeam.useQuery();

  const { data: jobTags } = trpc.tags.list.useQuery({
    type: "JOB",
  });
  const { data: job } = trpc.fieldService.jobs.details.useQuery(fsJobId);
  const { data: schedulesData, isLoading: schedulesLoading } =
    trpc.fieldService.schedules.list.useQuery({
      fsJobId: fsJobId,
      status:
        scheduleType === "PENDING"
          ? ["SCHEDULED", "CONFIRMED", "IN_PROGRESS", "ON_MY_WAY", "ARRIVED"]
          : [scheduleType],
      orderBy: {
        startDate: scheduleType === "COMPLETED" ? "desc" : "asc",
      },
    });
  const schedules = schedulesData?.schedules || [];

  const updateJobMutation = trpc.fieldService.jobs.update.useMutation({
    onSuccess: () => {
      toast.success("Job updated successfully");
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const jobDeleteMutation = trpc.fieldService.jobs.delete.useMutation({
    onSuccess: () => {
      toast.success("Job deleted successfully");
      navigate("/field-service/jobs");
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const notesUpdateMutation = trpc.fieldService.jobs.update.useMutation({
    onSuccess() {
      return { invalidate: false };
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });
  const debouncedUpdateNotes = debounce(notesUpdateMutation.mutate, 500);

  const delayedSchedules = schedules?.filter((schedule) =>
    dayjs(schedule.startDate).isBefore(dayjs(), "day")
  );

  const todaySchedules = schedules?.filter((schedule) =>
    dayjs(schedule.startDate).isSame(dayjs(), "day")
  );

  const upcomingSchedules = schedules?.filter((schedule) =>
    dayjs(schedule.startDate).isAfter(dayjs(), "day")
  );

  useEffect(() => {
    if (job) {
      if (job.scheduleStats.pending === 0) {
        if (job.scheduleStats.completed > 0) {
          setScheduleType("COMPLETED");
        } else if (job.scheduleStats.cancelled > 0) {
          setScheduleType("CANCELLED");
        } else {
          setScheduleType("PENDING");
        }
      }
    }
  }, [job]);

  if (!job) return <FullScreenSpinner />;

  const totalInvoiceAmount = job.fsJobInvoices.reduce(
    (total, invoice) => total + calculateInvoice(invoice.invoices).invoiceTotal,
    0
  );

  const totalExpenses = job.expenses.reduce(
    (total, expense) => total + expense.amount,
    0
  );
  const profit = totalInvoiceAmount - totalExpenses;
  const profitMargin = (profit / totalInvoiceAmount) * 100;

  const isJobClosed = job.status === "CANCELLED" || job.status === "COMPLETED";

  const reOpenJob = async () => {
    if (!permissions || !permissions.REOPEN_JOBS.allowed)
      return toast.error("You are not allowed to re-open jobs");
    const confirmed = await confirm({
      title: "Are you sure you want to reactivate this job?",
      body: "This will show this job in the schedule again",
    });

    if (confirmed) {
      updateJobMutation.mutate({
        id: job.id,
        fsJob: {
          status: "ACTIVE",
        },
      });
    }
  };

  return (
    <>
      {scheduleDetailsId && (
        <ScheduleDetailsModal
          onClose={() => setScheduleDetailsId(null)}
          open={true}
          id={scheduleDetailsId}
        />
      )}
      <AddScheduleDrawer
        open={showAddScheduleDrawer}
        onClose={() => setShowAddScheduleDrawer(false)}
        fsJobId={job.id}
        jobItems={job.fsJobServices}
      />
      <UploadFileModalNew
        open={showFiles}
        onClose={() => setShowFiles(false)}
        section="field-service/jobs"
        entity="FS_JOB"
        entityId={job.id}
      />
      <JobCloseModal
        open={showPendingSchedulesModal}
        onClose={() => setShowPendingSchedulesModal(false)}
        jobId={job.id}
      />
      <Page
        className="w-full h-full bg-gray-100 sm:min-h-screen"
        title="Job Details"
        description={`Created on ${dayjs(job.createdAt).format(
          "MMM DD, YYYY"
        )}`}
        showBack
        fullWidth
        tags={
          <div>
            <JobStatusBadge status={job.status} />
          </div>
        }
        suffix={
          <div className="flex gap-2">
            <Button
              loading={jobDeleteMutation.isLoading}
              onClick={async () => {
                const confirmed = await confirm({
                  title: "Are you sure you want to delete this job?",
                  body: "This action cannot be undone. This will delete all schedules, expenses and history associated with this job.",
                });
                if (confirmed) {
                  jobDeleteMutation.mutate(job.id);
                }
              }}
              variant="destructiveOutline"
              size={isMobile() ? "sm" : "md"}
              icon={Trash2}
            />
            <Button
              onClick={() => navigate(`/field-service/jobs/edit/${job.id}`)}
              icon={Pencil}
              variant="linkOutline"
              size={isMobile() ? "sm" : "md"}
              disabled={
                isJobClosed &&
                !team?.teamSettings.fsJobs.allowJobEditingAfterClosed
              }
            >
              Edit
            </Button>
            <DropMenu
              items={[
                {
                  label: "Duplicate",
                  icon: Copy,
                  onClick: () =>
                    navigate(`/field-service/jobs/add?duplicate=${job.id}`),
                },
                {
                  hidden: isJobClosed,
                  label: "Complete Job",
                  icon: CheckCircle,
                  onClick: () => {
                    if (
                      job.fsSchedules.filter(
                        (schedule) =>
                          schedule.status === "COMPLETED" ||
                          schedule.status === "CANCELLED"
                      ).length === job.fsSchedules.length
                    ) {
                      updateJobMutation.mutate({
                        id: job.id,
                        fsJob: {
                          status: "COMPLETED",
                        },
                      });
                    } else {
                      setShowPendingSchedulesModal(true);
                    }
                  },
                  className: "text-green-500",
                },
                {
                  hidden: !isJobClosed,
                  label: "Reopen Job",
                  icon: RefreshCcw,
                  onClick: reOpenJob,
                  className: "text-orange-500",
                },
                // {
                //   label: "Freeze Job",
                //   icon: Pause,
                //   onClick: async () => {},
                //   className: "text-yellow-500",
                // },
                {
                  hidden: isJobClosed,
                  label: "Cancel Job",
                  icon: XCircle,
                  onClick: async () => {
                    const cancelReason = window.prompt(
                      "Enter cancellation reason"
                    );
                    if (cancelReason) {
                      updateJobMutation.mutate({
                        id: job.id,
                        fsJob: {
                          status: "CANCELLED",
                          cancelReason: cancelReason,
                        },
                      });
                    }
                  },
                  className: "text-red-500",
                },
              ]}
            >
              <Button size={isMobile() ? "sm" : "md"} icon={MoreHorizontal}>
                More
              </Button>
            </DropMenu>
          </div>
        }
      >
        <div className="p-1 sm:p-4">
          <div className="flex flex-col bg-white rounded-lg border border-gray-100">
            <div className="w-full h-2 rounded-lg bg-primary" />
            <div className="flex flex-col gap-3 p-5">
              <div className="flex gap-2 justify-end items-center">
                <p className="text-lg font-medium">{getJobId(job)}</p>
                <JobStatusBadge status={job.status} />
              </div>
              <div className="flex flex-col justify-between sm:flex-row">
                <div className="flex flex-col gap-3">
                  <h1
                    onClick={() => {
                      navigate(
                        `/sales/clients/details/${job.fsProperties.clients.id}`
                      );
                    }}
                    className="text-2xl font-bold cursor-pointer hover:underline hover:text-primary"
                  >
                    {job.fsProperties.clients.name}
                  </h1>
                  <TagsInput
                    emptyLabel="No tags"
                    value={job.fsJobTags.map((tag) => tag.tagId)}
                    tags={jobTags}
                    onChange={(tags) => {
                      updateJobMutation.mutate({
                        id: job.id,
                        fsJob: {
                          fsJobTags: tags,
                        },
                      });
                    }}
                  />
                  <p className="text-sm font-semibold text-gray-8000">
                    Property address
                  </p>
                  <div className="flex gap-2 items-center">
                    <div className="grid place-items-center p-3 rounded-lg border border-gray-200">
                      <MapPin className="h-5 text-primary" />
                    </div>
                    <div className="flex flex-col gap-1">
                      <p className="text-sm">
                        {formatValue.propertyName(job.fsProperties)}
                      </p>
                      <p className="text-xs text-gray-500">
                        {formatValue.address(job.fsProperties)}
                      </p>
                      <div className="flex gap-2 items-center">
                        <Button
                          onClick={() => {
                            const link = generateGoogleMapsLink(
                              job.fsProperties
                            );
                            copyToClipboard(link);
                          }}
                          variant="primaryOutline"
                          size="xs"
                          icon={Copy}
                        >
                          Copy link
                        </Button>
                        <Button
                          onClick={() => openGoogleMaps(job.fsProperties)}
                          variant="primaryOutline"
                          size="xs"
                          icon={Navigation}
                        >
                          Open map
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col gap-2 mt-3 sm:mt-0">
                  <p className="text-sm font-semibold text-gray-800">
                    Job details
                  </p>
                  <table className="border-none">
                    <tbody>
                      {job.cancelReason && job.status === "CANCELLED" && (
                        <tr className="border-b">
                          <td className="py-2">Cancel reason</td>
                          <td className="pl-12 text-red-500">
                            {job.cancelReason}
                          </td>
                        </tr>
                      )}
                      {!job.cancelReason && job.status === "CANCELLED" && (
                        <tr className="border-b">
                          <td className="py-2">Cancel reason</td>
                          <td className="pl-12 text-red-500">
                            {job.fsSchedules
                              .filter(
                                (schedule) => schedule.status === "CANCELLED"
                              )
                              .map((schedule) => schedule.cancelReason || "")
                              .join(", ")}
                          </td>
                        </tr>
                      )}
                      {job.cancelledOn && job.status === "CANCELLED" && (
                        <tr className="border-b">
                          <td className="py-2">Cancelled on</td>
                          <td className="pl-12">
                            {dayjs(job.cancelledOn).format(
                              "MMM DD, YYYY, hh:mm A"
                            )}
                          </td>
                        </tr>
                      )}
                      <tr className="border-b">
                        <td className="py-2">Job type</td>
                        <td className="pl-12">{capitalize(job.type)}</td>
                      </tr>
                      <tr className="border-b">
                        <td className="py-2">Starts on</td>
                        <td className="pl-12">
                          {dayjs(job.startDate).format("MMM DD, YYYY")}
                        </td>
                      </tr>
                      {!!job.endDate && (
                        <tr className="border-b">
                          <td className="py-2">Ends on</td>
                          <td className="py-2 pl-12">
                            {dayjs(job.endDate).format("MMM DD, YYYY")}
                          </td>
                        </tr>
                      )}
                      {job.frequencyRule && job.endDate && (
                        <tr className="border-b">
                          <td className="py-2">Job frequency</td>
                          <td className="pl-12 capitalize">
                            {rrulestr(job.frequencyRule).toText()}
                          </td>
                        </tr>
                      )}
                      {job.paymentMethods && (
                        <tr>
                          <td className="py-2">Payment method</td>
                          <td className="pl-12">{job.paymentMethods.name}</td>
                        </tr>
                      )}
                      {job.lpoNumber && (
                        <tr>
                          <td className="py-2">LPO Number</td>
                          <td className="pl-12">{job.lpoNumber}</td>
                        </tr>
                      )}
                      {job.files?.fields.fs_job_lpo && (
                        <tr>
                          <td className="py-2">LPO file</td>
                          <td className="flex gap-2 items-center pl-12">
                            <Button
                              icon={ArrowUpRightFromSquare}
                              size="xs"
                              onClick={() => {
                                if (job.files) {
                                  window.open(
                                    getS3URL(job.files.fields.fs_job_lpo),
                                    "_blank"
                                  );
                                }
                              }}
                            />
                            <Button
                              variant="primaryOutline"
                              size="xs"
                              icon={Download}
                              onClick={() => {
                                if (job.files) {
                                  downloadFile(
                                    job.files.fields.fs_job_lpo.name,
                                    getS3URL(job.files.fields.fs_job_lpo)
                                  );
                                }
                              }}
                            />
                          </td>
                        </tr>
                      )}
                      {job.fsJobQuotations.length > 0 && (
                        <tr>
                          <td className="py-2">Quotations</td>
                          <td className="flex flex-wrap gap-2 py-2 pl-12 w-60">
                            {job.fsJobQuotations.map((q) => (
                              <p
                                onClick={() => {
                                  navigate(
                                    `/sales/quotations/details/${q.quotations.id}`
                                  );
                                }}
                                key={q.quotations.id}
                                className="underline cursor-pointer hover:text-primary-600"
                              >
                                #{q.quotations.number}
                              </p>
                            ))}
                          </td>
                        </tr>
                      )}
                      {job.salesPerson && (
                        <tr>
                          <td className="py-2">Sales person</td>
                          <td className="pl-12">
                            {job.salesPerson.firstName}{" "}
                            {job.salesPerson.lastName}
                          </td>
                        </tr>
                      )}

                      {job.paymentsCount > 0 && (
                        <tr>
                          <td className="py-2">Total payments</td>
                          <td className="pl-12">{job.paymentsCount}</td>
                        </tr>
                      )}

                      {job.vendors && (
                        <tr>
                          <td className="py-2">Vendor</td>
                          <td className="pl-12">{job.vendors.name}</td>
                        </tr>
                      )}
                      {job.vendors && job.vendorCharge && (
                        <tr>
                          <td className="py-2">Vendor charge</td>
                          <td className="pl-12">
                            {formatCurrency(job.vendorCharge, "AED")}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col p-4 mt-3 bg-white rounded-lg">
            <h3 className="text-sm font-medium text-gray-500">Profitability</h3>
            <div className="flex flex-col justify-between mt-4 w-full sm:flex-row">
              <div className="flex flex-col gap-1">
                <h3 className="text-2xl font-semibold">
                  {typeof profitMargin === "number" &&
                  !isNaN(profitMargin) &&
                  isFinite(profitMargin)
                    ? `${profitMargin.toFixed()}%`
                    : "Nil"}
                </h3>
                <p className="text-sm text-gray-500">Profit margin</p>
              </div>

              <div className="flex flex-row gap-10 mt-3 sm:mt-0">
                <div className="flex flex-col gap-0 sm:gap-1">
                  <h3 className="text-sm font-medium text-gray-500 sm:text-lg">
                    Total
                  </h3>
                  <p className="text-sm font-semibold sm:text-xl">
                    {formatCurrency(totalInvoiceAmount, "AED")}
                  </p>
                </div>
                <div className="flex flex-col gap-1">
                  <div className="flex gap-2 items-center">
                    <div className="w-2 h-2 bg-violet-600 sm:h-3 sm:w-3" />
                    <h3 className="text-sm font-medium text-gray-500 sm:text-lg">
                      Expenses
                    </h3>
                  </div>
                  <p className="text-sm font-semibold sm:text-xl">
                    {formatCurrency(totalExpenses, "AED")}
                  </p>
                </div>
                <div className="flex flex-col gap-1">
                  <div className="flex gap-2 items-center">
                    <div className="w-2 h-2 bg-green-600 sm:h-3 sm:w-3" />
                    <h3 className="text-sm font-medium text-gray-500 sm:text-lg">
                      Profit
                    </h3>
                  </div>
                  <p className="text-sm font-semibold sm:text-xl">
                    {formatCurrency(profit, "AED")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-4 p-1 sm:p-4 sm:grid-cols-[65%_35%]">
          {/* Left side section */}
          <div className="flex flex-col order-2 gap-2 sm:order-1">
            {/* Services card */}
            <Card
              hideBorder
              title="Line items"
              icon={<Hammer className="text-green-500" />}
            >
              <Table className="w-full">
                <TableHeader className="border-b border-gray-200">
                  <TableRow className="border-none">
                    <TableHead className="w-10 border-none"></TableHead>
                    <TableHead className="col-span-2 border-none">
                      NAME
                    </TableHead>
                    <TableHead className="w-10 border-none">QTY</TableHead>
                    <TableHead className="w-14 border-none">PRICE</TableHead>
                    <TableHead className="w-10 border-none">TAX</TableHead>
                    <TableHead className="w-14 border-none">TOTAL</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {job.fsJobServices.map((lineItem, index) => (
                    <TableRow className="border-none" key={lineItem.id}>
                      <TableCell className="border-none">
                        {index + 1}.
                      </TableCell>
                      <TableCell className="flex flex-col gap-1 border-none h-fit">
                        <span className="font-semibold">{lineItem.name}</span>
                        <span className="text-xs text-gray-500">
                          <RenderHtml>{lineItem.description}</RenderHtml>
                        </span>
                      </TableCell>
                      <TableCell className="border-none">
                        {lineItem.quantity}
                      </TableCell>
                      <TableCell className="border-none">
                        {formatCurrency(lineItem.price, "AED")}
                      </TableCell>
                      <TableCell className="border-none">
                        {lineItem.tax}%
                      </TableCell>
                      <TableCell className="font-medium border-none">
                        {formatCurrency(
                          lineItem.price * lineItem.quantity,
                          "AED"
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                  {/* TableRow for displaying subtotal in last fields */}
                  <div className="h-6" />
                  <TableRow className="border-none">
                    <TableCell className="border-none"></TableCell>
                    <TableCell className="border-none"></TableCell>
                    <TableCell className="border-none"></TableCell>
                    <TableCell className="border-none"></TableCell>
                    <TableCell className="border-none">
                      <div className="flex flex-col gap-2.5 text-right">
                        <p>Subtotal</p>
                        <p>Discount</p>
                        <p>Tax</p>
                        <p className="text-base font-medium text-primary">
                          Total
                        </p>
                      </div>
                    </TableCell>
                    <TableCell className="text-right border-none">
                      <div className="flex flex-col gap-2.5">
                        <p>
                          {formatCurrency(
                            calculateLineItems({
                              lineItems: job.fsJobServices.map((service) => ({
                                ...service,
                                viewType: "LINE_ITEM",
                              })),
                              discount: job.discount,
                            }).subTotal,
                            "AED"
                          )}
                        </p>
                        <p>{formatCurrency(job.discount, "AED")}</p>
                        <p>
                          {formatCurrency(
                            calculateLineItems({
                              lineItems: job.fsJobServices.map((service) => ({
                                ...service,
                                viewType: "LINE_ITEM",
                              })),
                              discount: job.discount,
                            }).totalTax,
                            "AED"
                          )}
                        </p>
                        <p className="font-bold">
                          {formatCurrency(
                            calculateLineItems({
                              lineItems: job.fsJobServices.map((service) => ({
                                ...service,
                                viewType: "LINE_ITEM",
                              })),
                              discount: job.discount,
                            }).total,
                            "AED"
                          )}
                        </p>
                      </div>
                    </TableCell>
                  </TableRow>

                  <TableRow className="border-none"></TableRow>
                </TableBody>
              </Table>
            </Card>

            {/* Schedules card */}
            <Card
              watermark={job.status === "CANCELLED" ? "Cancelled" : undefined}
              hideBorder
              titleClassName="flex-col sm:flex-row"
              parentClassName="mt-2"
              // @ts-ignore
              title={
                <div className="flex gap-2 items-center">
                  Schedules -
                  <span className="text-sm font-normal text-gray-500">
                    {schedules?.length} Schedules {scheduleType.toLowerCase()}
                  </span>
                </div>
              }
              icon={<Calendar className="text-red-500" />}
              suffix={
                <div className="flex flex-col gap-2 justify-center w-full sm:w-auto sm:flex-row">
                  <TabsInput
                    options={[
                      {
                        label: "Pending",
                        value: "PENDING",
                        count: job.scheduleStats.pending,
                      },
                      {
                        label: "Completed",
                        value: "COMPLETED",
                        count: job.scheduleStats.completed,
                      },
                      {
                        label: "Cancelled",
                        value: "CANCELLED",
                        count: job.scheduleStats.cancelled,
                      },
                    ]}
                    value={scheduleType}
                    onChange={(value) => {
                      setScheduleType(
                        value as "PENDING" | "COMPLETED" | "CANCELLED"
                      );
                    }}
                  />
                  {!isJobClosed ? (
                    <Button
                      variant={"primary"}
                      size={"md"}
                      onClick={() => setShowAddScheduleDrawer(true)}
                      icon={Plus}
                    >
                      Schedule
                    </Button>
                  ) : (
                    <Button
                      variant={"primary"}
                      size={"md"}
                      onClick={reOpenJob}
                      icon={RefreshCcw}
                      loading={updateJobMutation.isLoading}
                    >
                      Reopen job
                    </Button>
                  )}
                </div>
              }
            >
              {scheduleType === "PENDING" ? (
                <div className="flex flex-col gap-2">
                  {!!delayedSchedules?.length && (
                    <div className="flex flex-col gap-2">
                      <p className="text-base font-medium text-red-500">
                        Pending
                      </p>
                      {delayedSchedules?.map((schedule) => (
                        <ScheduleCard
                          key={schedule.id}
                          schedule={schedule}
                          onClick={() => setScheduleDetailsId(schedule.id)}
                        />
                      ))}
                    </div>
                  )}
                  {!!todaySchedules?.length && (
                    <div className="flex flex-col gap-2">
                      <p className="text-base font-medium text-green-600">
                        Today
                      </p>
                      {todaySchedules?.map((schedule) => (
                        <ScheduleCard
                          key={schedule.id}
                          schedule={schedule}
                          onClick={() => setScheduleDetailsId(schedule.id)}
                        />
                      ))}
                    </div>
                  )}
                  {!!upcomingSchedules?.length && (
                    <Accordion
                      type="single"
                      collapsible
                      value={
                        upcomingSchedules.length <= 3 ||
                        (!delayedSchedules.length && !todaySchedules.length)
                          ? "upcoming"
                          : undefined
                      }
                    >
                      <AccordionItem value="upcoming">
                        <AccordionTrigger
                          className="text-base font-medium"
                          iconPosition="end"
                        >
                          <div className="flex gap-3 items-center">
                            Upcoming
                            <div className="flex justify-center items-center w-5 h-5 text-xs text-white bg-green-500 rounded-full">
                              {upcomingSchedules?.length}
                            </div>
                          </div>
                        </AccordionTrigger>
                        <AccordionContent>
                          <div className="flex flex-col gap-2">
                            {upcomingSchedules?.map((schedule) => (
                              <ScheduleCard
                                key={schedule.id}
                                schedule={schedule}
                                onClick={() =>
                                  setScheduleDetailsId(schedule.id)
                                }
                              />
                            ))}
                          </div>
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>
                  )}
                  {!schedules?.length && (
                    <Empty
                      title="No schedules pending"
                      description="Your pending schedules will appear here."
                      icon={Calendar}
                    />
                  )}
                </div>
              ) : (
                <div>
                  {schedulesLoading && <FullScreenSpinner />}
                  {!schedulesLoading && !schedules?.length && (
                    <Empty
                      description={`Your ${scheduleType.toLowerCase()} schedules will appear here.`}
                      title={`No ${scheduleType.toLowerCase()} schedules`}
                      icon={CheckCircle}
                    />
                  )}
                  {!!schedules?.length && (
                    <div className="flex flex-col gap-2">
                      <p
                        className={cn(
                          "text-base font-medium",
                          scheduleType === "COMPLETED"
                            ? "text-green-600"
                            : "text-red-600"
                        )}
                      >
                        {capitalize(scheduleType)}
                      </p>
                      {schedules?.map((schedule) => (
                        <ScheduleCard
                          key={schedule.id}
                          schedule={schedule}
                          onClick={() => setScheduleDetailsId(schedule.id)}
                        />
                      ))}
                    </div>
                  )}
                </div>
              )}
            </Card>

            <NextTabs
              autoScrollDisabled
              variant="bordered"
              value={showFinance}
              onChange={setShowFinance}
              className="px-0 mt-6 w-fit"
              tabs={[
                {
                  label: (
                    <div className="flex gap-2 items-center">
                      <CircleDollarSign className="h-4 text-green-500" />
                      Invoices
                    </div>
                  ),
                  content: null,
                  id: "invoices",
                },
                {
                  label: (
                    <div className="flex gap-2 items-center">
                      <Banknote className="h-4 text-blue-500" />
                      Expenses
                    </div>
                  ),
                  content: null,
                  id: "expenses",
                },
              ]}
            />
            {showFinance === "invoices" ? (
              <Invoices
                fsJobId={job.id}
                clientId={job.fsProperties.clients.id}
              />
            ) : (
              <Expenses
                fsJobId={job.id}
                // defaultValues={{
                //   aggregatorCharge: job.aggragatorCharge || undefined,
                //   paidToAggregatorId: job.fsAggregatorId || undefined,
                // }}
              />
            )}
            <Documents fsJobId={job.id} />
          </div>

          <div className="flex flex-col order-1 gap-2 sm:order-2">
            {/* Contract details */}
            <div>
              <div className="flex flex-col gap-2">
                {job.contactPerson && (
                  <ContactCard
                    title="Contact person"
                    contact={job.contactPerson}
                    icon={<User2 className="text-purple-600" />}
                  />
                )}
                {job.siteContactPerson && (
                  <ContactCard
                    contact={job.siteContactPerson}
                    title="Site contact person"
                    icon={<MapPin className="text-green-600" />}
                  />
                )}
              </div>
            </div>
            <Card
              hideBorder
              title="Notes"
              icon={<StickyNote className="text-yellow-500" />}
            >
              <div className="mt-4">
                <MiniRichTextEditor
                  placeholder="Add notes about job..."
                  value={job.internalNotes || ""}
                  onChange={(v) => {
                    debouncedUpdateNotes({
                      id: fsJobId,
                      fsJob: {
                        internalNotes: v,
                      },
                    });
                  }}
                  height={170}
                />
                {notesUpdateMutation.isLoading && (
                  <div className="flex gap-2 items-center mt-1 float-end">
                    <p>Updating</p>
                    <Loader className="h-5 animate-spin text-primary" />
                  </div>
                )}
              </div>
            </Card>
            <Card
              hideBorder
              title="Files"
              icon={<File className="text-red-500" />}
              suffix={
                <Button
                  variant="primary"
                  size="sm"
                  icon={Plus}
                  onClick={() => setShowFiles(true)}
                >
                  File
                </Button>
              }
            >
              <div className="flex justify-center items-center">
                {!job.files?.files.length && (
                  <Empty
                    title="No files"
                    description="Your files will appear here."
                    icon={File}
                    actionText="Upload file"
                    actionIcon={Plus}
                    hideAction={false}
                    onAction={() => setShowFiles(true)}
                  />
                )}
                {!!job.files?.files.length && (
                  <div className="flex flex-col gap-2 w-full">
                    {job.files?.files.map((file) => (
                      <FileCard key={file.id} file={file} />
                    ))}
                  </div>
                )}
              </div>
            </Card>
            <Card
              hideBorder
              title="Timeline"
              icon={<CalendarDays className="h-5 text-green-500" />}
            >
              <RenderTimeline userActivities={job.userActivities} />
            </Card>
          </div>
        </div>
        <div className="sm:mb-0 mb-[100px]" />
      </Page>
    </>
  );
};

export default JobDetails;
