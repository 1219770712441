import { formatName } from "@heffl/ui/lib/utils";
import dayjs from "dayjs";

const format = {
  name: formatName,
  date: (date: Date) => {
    return dayjs(date).format("DD/MM/YYYY");
  },
  dateTime: (date: Date) => {
    return dayjs(date).format("DD/MM/YYYY hh:mm A");
  },
};

const heffl = { format };

export default heffl;
