import Empty from "@/components/Empty";
import FilterBar from "@/components/filters";
import Page from "@/components/page";
import { RouterInputs, trpc } from "@/helpers/trpc";
import { formatName, isMobile } from "@heffl/ui/lib/utils";

import { useParamsState } from "@/lib/hooks/useParamsState";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import StripeTabs from "@heffl/ui/components/primitives/stripe-tabs";
import { CheckSquare, KanbanIcon, List } from "lucide-react";
import { useState } from "react";
import { P, match } from "ts-pattern";
import TasksKanban, { taskStatuses } from "../details/components/kanban";
import TaskCard from "../details/components/kanban/TaskCard";
import { ProjectTaskDetailsModal } from "./components/project-task-modals";
import ProjectTaskList from "./list";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";
import enums from "@heffl/server/src/schemas/enums";

type TaskFilter = {
  pipelineIds: number[];
  statuses: z.infer<typeof enums.projectTaskStatusTypes>[];
  clients: number[];
  tags: number[];
  assignees: number[];
  dates: [Date, Date] | undefined;
};

const Tasks = () => {
  const trpcUtils = trpc.useUtils();
  const navigate = useNavigate();
  const { view } = useParams<{ view: "board" | "table" }>();

  const [filters, setFilters] = useParamsState<TaskFilter>({
    pipelineIds: [],
    clients: [],
    tags: [],
    assignees: [],
    statuses: [],
    dates: undefined,
  });

  const [clientSearch, setClientSearch] = useState("");
  const [editTaskId, setEditTaskId] = useState<number | undefined>();

  const { data: pipelines } = trpc.projects.pipelines.list.useQuery();
  const { data: clients } = trpc.clients.list.useQuery({
    search: clientSearch,
    pageNo: 1,
    pageSize: 10,
  });
  const { data: tags } = trpc.tags.list.useQuery({
    type: "PROJECT_TASK",
  });
  const { data: users } = trpc.users.list.useQuery({
    excludeType: ["FIELD_STAFF"],
  });

  const tasksParams: RouterInputs["projects"]["tasks"]["list"] = {
    pipelineIds: filters.pipelineIds,
    clients: filters.clients,
    tags: filters.tags,
    assignees: filters.assignees,
    orderBy: "createdAt",
    statuses: filters.statuses,
    dates: filters.dates,
  };

  const tasksQuery = trpc.projects.tasks.list.useQuery(tasksParams);

  return (
    <Page title="Tasks" fullWidth className="!p-0">
      {editTaskId && (
        <ProjectTaskDetailsModal
          open={!!editTaskId}
          onClose={() => setEditTaskId(undefined)}
          taskId={editTaskId}
        />
      )}

      <StripeTabs
        contentClassName="pt-0"
        className="pt-2 w-full"
        tabParentClassName="pl-4"
        value={view}
        onChange={(tab) => navigate(`/projects/tasks/${tab}`)}
        items={[
          { label: "Board", key: "board", icon: KanbanIcon },
          { label: "Table", key: "table", icon: List },
        ]}
      />

      <FilterBar
        className="p-3 mt-0 mb-3"
        defaultFilters={["dates", "pipelineId"]}
        filters={[
          {
            key: "dates",
            type: "date-range",
            label: "Date",
            value: filters.dates,
            onChange: (value) => setFilters({ dates: value as [Date, Date] }),
          },
          {
            key: "pipelineId",
            type: "checkbox",
            label: "Pipeline",
            value: filters.pipelineIds,
            onChange: (value) => setFilters({ pipelineIds: value as number[] }),
            options: pipelines?.map((pipeline) => ({
              label: pipeline.name,
              value: pipeline.id,
            })),
          },
          {
            key: "status",
            type: "checkbox",
            label: "Status",
            value: filters.statuses,
            onChange: (value) =>
              setFilters({
                statuses: value as z.infer<
                  typeof enums.projectTaskStatusTypes
                >[],
              }),
            options: taskStatuses.map((status) => ({
              label: status.label,
              value: status.value,
            })),
          },
          {
            key: "clients",
            type: "checkbox",
            label: "Clients",
            showSearch: true,
            value: filters.clients,
            onChange: (value) => setFilters({ clients: value as number[] }),
            options: clients?.clients.map((client) => ({
              label: client.name,
              value: client.id,
            })),
            onSearch: (e) => setClientSearch(e),
          },
          {
            key: "assignees",
            type: "checkbox",
            label: "Assignees",
            value: filters.assignees,
            onChange: (value) => setFilters({ assignees: value as number[] }),
            options: users?.map((user) => ({
              label: formatName(user),
              value: user.id,
            })),
          },
          {
            key: "tags",
            type: "checkbox",
            label: "Tags",
            value: filters.tags,
            onChange: (value) => setFilters({ tags: value as number[] }),
            options: tags?.map((tag) => ({
              label: tag.name,
              value: tag.id,
            })),
          },
        ]}
      />

      <div>
        {view === "board" ? (
          <div className="px-4">
            <TasksKanban
              tasks={tasksQuery.data || []}
              onChange={(updated) => {
                trpcUtils.projects.tasks.list.setData(
                  tasksParams,
                  () => updated
                );
              }}
            />
          </div>
        ) : (
          match(tasksQuery)
            .with({ data: [] }, () => (
              <Empty
                title="No Pending tasks"
                icon={CheckSquare}
                description="Add tasks to get started with your projects."
              />
            ))
            .with({ data: P.array() }, ({ data: tasks }) =>
              isMobile() ? (
                <div className="flex flex-col gap-2 mt-4 mb-[100px] w-full px-3">
                  {tasks.map((task) => (
                    <TaskCard
                      key={task.id}
                      task={task}
                      onTaskClick={(id) => setEditTaskId(id)}
                      className="!w-full !max-w-none"
                    />
                  ))}
                </div>
              ) : (
                <ProjectTaskList tasks={tasksQuery.data || []} />
              )
            )
            .with({ isLoading: true }, () => <FullScreenSpinner />)
            .otherwise(() => <FullScreenSpinner />)
        )}
      </div>
    </Page>
  );
};

export default Tasks;
