import { z } from "zod";
import * as files from "./files";

const moduleNumberSchema = z.object({
  prefix: z.string().trim(),
  startingNumber: z.coerce
    .string()
    .trim()
    .refine((val) => !isNaN(Number(val)) && Number.isInteger(Number(val)), {
      message: "Starting number must be a valid integer",
    }),
});

const defaultValues = {
  clientVendor: {
    allowDuplicateNames: false,
  },
  quotation: {
    initialTags: [],
  },
  invoice: {
    dueDateDays: 30,
    addJobDescription: false,
  },
  leads: {
    requiredFields: {
      mobile: false,
      crmSourceId: false,
    },
  },
  fsJobs: {
    requiredFields: {
      paymentMethodId: false,
      lpoNumber: false,
    },
    allowScheduleCompletionWithoutAssignees: false,
    allowConversionAboveQuotationValue: false,
    oneoffDefaultConfirmed: true,
    showScheduleRevenue: false,
    allowJobEditingAfterClosed: false,
  },
  moduleNumbers: {
    quotation: {
      prefix: "",
      startingNumber: "1",
    },
    invoice: {
      prefix: "",
      startingNumber: "1",
    },
    proformaInvoice: {
      prefix: "",
      startingNumber: "1",
    },
    payment: {
      prefix: "",
      startingNumber: "1",
    },
    purchaseOrder: {
      prefix: "",
      startingNumber: "1",
    },
    vendor: {
      prefix: "",
      startingNumber: "1",
    },
    lead: {
      prefix: "",
      startingNumber: "1",
    },
    bill: {
      prefix: "",
      startingNumber: "1",
    },
  },
};

export const teamSettingsSchema = z.object({
  clientVendor: z
    .object({
      allowDuplicateNames: z.boolean().default(false),
    })
    .default(defaultValues.clientVendor),
  quotation: z
    .object({
      initialTags: z.array(z.coerce.number()).default([]),
    })
    .default(defaultValues.quotation),
  invoice: z
    .object({
      dueDateDays: z.coerce.number().default(defaultValues.invoice.dueDateDays),
      addJobDescription: z
        .boolean()
        .default(defaultValues.invoice.addJobDescription),
    })
    .default(defaultValues.invoice),
  leads: z
    .object({
      requiredFields: z
        .object({
          mobile: z.boolean(),
          crmSourceId: z.boolean(),
        })
        .default(defaultValues.leads.requiredFields),
    })
    .default(defaultValues.leads),
  fsJobs: z
    .object({
      requiredFields: z
        .object({
          paymentMethodId: z.boolean(),
          lpoNumber: z.boolean(),
        })
        .default(defaultValues.fsJobs.requiredFields),
      allowScheduleCompletionWithoutAssignees: z
        .boolean()
        .default(defaultValues.fsJobs.allowScheduleCompletionWithoutAssignees),
      allowConversionAboveQuotationValue: z
        .boolean()
        .default(defaultValues.fsJobs.allowConversionAboveQuotationValue),
      oneoffDefaultConfirmed: z
        .boolean()
        .default(defaultValues.fsJobs.oneoffDefaultConfirmed),
      showScheduleRevenue: z
        .boolean()
        .default(defaultValues.fsJobs.showScheduleRevenue),
      allowJobEditingAfterClosed: z
        .boolean()
        .default(defaultValues.fsJobs.allowJobEditingAfterClosed),
    })
    .default(defaultValues.fsJobs),
  moduleNumbers: z
    .object({
      quotation: moduleNumberSchema.default(
        defaultValues.moduleNumbers.quotation
      ),
      invoice: moduleNumberSchema.default(defaultValues.moduleNumbers.invoice),
      proformaInvoice: moduleNumberSchema.default(
        defaultValues.moduleNumbers.proformaInvoice
      ),
      payment: moduleNumberSchema.default(defaultValues.moduleNumbers.payment),
      purchaseOrder: moduleNumberSchema.default(
        defaultValues.moduleNumbers.purchaseOrder
      ),
      vendor: moduleNumberSchema.default(defaultValues.moduleNumbers.vendor),
      lead: moduleNumberSchema.default(defaultValues.moduleNumbers.lead),
      bill: moduleNumberSchema.default(defaultValues.moduleNumbers.bill),
    })
    .default(defaultValues.moduleNumbers),
});

export const team = z.object({
  name: z.string(),
  files: z
    .object({
      team_logo: files.filesInput,
    })
    .optional(),
});

export type TeamSettings = z.infer<typeof teamSettingsSchema>;
