import { z } from "zod";
import { getServerUrl } from "../getHostUrl";
import { CustomFieldInput } from "../workflows/types";

export const integrationProvidersSchema = z.enum([
  "zoho_books",
  "telr",
  "zoho_mail",
]);

export type IntegrationProviders = z.infer<typeof integrationProvidersSchema>;

export type Integration = {
  name: IntegrationProviders;
  label: string;
  description: string;
  type: "TEAM" | "USER";
  clientId: string;
  clientSecret: string;
  scope: string[];
  authUrl: string;
  tokenUrl: string;
  redirectUrl: string;
  inputs: CustomFieldInput[];
  schema: z.Schema;
};

export const integrationSchemas = {
  zoho_books: z.object({
    organization_id: z.string().min(1, "Organization ID is required"),
    tax_id: z.string().optional(),
  }),
  telr: z.object({
    store_id: z.string().min(1, "Store ID is required"),
    auth_key: z.string().min(1, "Auth Key is required"),
  }),
  zoho_mail: z.object({
    email: z
      .string()
      .email("Invalid email address")
      .min(1, "Email is required"),
    userId: z.string().optional(),
  }),
};

const getRedirectUrl = (provider: IntegrationProviders) => {
  return `${getServerUrl()}/api/integrations/redirect/${provider}`;
};

export const integrationProviders: Record<IntegrationProviders, Integration> = {
  zoho_books: {
    name: "zoho_books",
    label: "Zoho Books",
    schema: integrationSchemas.zoho_books,
    description: "Connect your Zoho Books account to Heffl",
    type: "TEAM",
    clientId: "1000.8IHRVKQWOX4TTCQYMB0FQYMGE1YVXP",
    clientSecret: "dfef29cc0c31900559aaaf9f674f3e4d0fdb675d87",
    scope: ["ZohoBooks.fullaccess.all"],
    authUrl: "https://accounts.zoho.com/oauth/v2/auth",
    tokenUrl: "https://accounts.zoho.com/oauth/v2/token",
    redirectUrl: `${getServerUrl()}/api/integrations/zoho_books`,
    inputs: [
      {
        name: "organization_id",
        label: "Organization ID",
        type: "TEXT",
        required: true,
        placeholder: "Zoho organization id",
      },
      {
        name: "tax_id",
        label: "Tax ID",
        type: "TEXT",
        required: false,
        placeholder: "Tax id for services and products",
      },
    ],
  },
  telr: {
    name: "telr",
    label: "Telr",
    schema: integrationSchemas.telr,
    description: "Connect your Telr account to Heffl",
    type: "TEAM",
    clientId: "",
    clientSecret: "",
    scope: [],
    authUrl: "",
    tokenUrl: "",
    redirectUrl: "",
    inputs: [
      {
        name: "store_id",
        label: "Store ID",
        type: "TEXT",
        required: true,
        placeholder: "Telr store id",
      },
      {
        name: "auth_key",
        label: "Auth Key",
        type: "TEXT",
        required: true,
        placeholder: "Telr auth key",
      },
    ],
  },
  zoho_mail: {
    name: "zoho_mail",
    label: "Zoho Mail",
    schema: integrationSchemas.zoho_mail,
    description: "Connect your Zoho Mail account to Heffl",
    type: "USER",
    clientId: "1000.8IHRVKQWOX4TTCQYMB0FQYMGE1YVXP",
    clientSecret: "dfef29cc0c31900559aaaf9f674f3e4d0fdb675d87",
    scope: ["ZohoMail.accounts.READ", "ZohoMail.messages.ALL"],
    authUrl: "https://accounts.zoho.com/oauth/v2/auth",
    tokenUrl: "https://accounts.zoho.com/oauth/v2/token",
    redirectUrl: getRedirectUrl("zoho_mail"),
    inputs: [
      {
        name: "email",
        label: "Email",
        type: "TEXT",
        required: true,
        placeholder: "Zoho mail email",
      },
      {
        name: "userId",
        label: "User ID",
        type: "TEXT",
        required: false,
        placeholder: "Zoho mail user id",
      },
    ],
  },
};
