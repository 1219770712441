import {
  BadgeDollarSign,
  Banknote,
  Briefcase,
  Building,
  CircleDollarSignIcon,
  ClipboardList,
  CreditCard,
  Handshake,
  KanbanSquare,
  LucideIcon,
  ReceiptText,
  Rows3,
  ScrollText,
  ShoppingBag,
  Target,
  TextCursorInput,
} from "lucide-react";

type AppIcon = {
  icon: LucideIcon;
  color: string;
};

interface AppIcons {
  sales: {
    icon: LucideIcon;
    color: string;

    invoices: AppIcon;
    payments: AppIcon;
    deals: AppIcon;
    leads: AppIcon;
    clients: AppIcon;
    quotes: AppIcon;
  };
  purchases: {
    icon: LucideIcon;
    color: string;
    vendors: AppIcon;
    expenses: AppIcon;
    bills: AppIcon;
    purchaseOrders: AppIcon;
  };
  projects: {
    project: AppIcon;
  };
  common: {
    customFields: AppIcon;
    lineItems: AppIcon;
  };
}

const appIcons: AppIcons = {
  sales: {
    icon: BadgeDollarSign,
    color: "#3b82f6",
    invoices: { icon: CircleDollarSignIcon, color: "#EF4444" },
    payments: { icon: Banknote, color: "#F97316" },
    deals: { icon: Handshake, color: "#EF4444" },
    leads: { icon: Target, color: "#F97316" },
    clients: { icon: Building, color: "#10B981" },
    quotes: { icon: ScrollText, color: "#F97316" },
  },
  purchases: {
    icon: ShoppingBag,
    color: "#10B981",

    vendors: { icon: Briefcase, color: "#10B981" },
    expenses: { icon: CreditCard, color: "#F97316" },
    bills: { icon: ReceiptText, color: "#F97316" },
    purchaseOrders: { icon: ClipboardList, color: "#F97316" },
  },
  projects: {
    project: { icon: KanbanSquare, color: "#A855F7" },
  },
  common: {
    customFields: { icon: TextCursorInput, color: "#F97316" },
    lineItems: { icon: Rows3, color: "#F97316" },
  },
};

export default appIcons;
