import { Editor } from "@tinymce/tinymce-react";
import { useRef } from "react";
import { Editor as TinyMCEEditor } from "tinymce";

interface Props {
  onChange?: (value: string) => void;
  value?: string;
  disabled?: boolean;
  height?: string;
  dynamicTags?: {
    title: string;
    menu: {
      value: string;
      title: string;
    }[];
  }[];
}

const defaultTinyConfig = {
  menubar: false,
  branding: false,
  toolbar_sticky: true,
  formats: {
    h1: { block: "h1", styles: { fontSize: "32px" } },
    h2: { block: "h2", styles: { fontSize: "24px" } },
    h3: { block: "h3", styles: { fontSize: "18.72px" } },
    p: { block: "p" },
  },
  line_height_formats: "0.5 0.75 1 1.2 1.4 1.6 2",
  style_formats: [
    { title: "Heading 1", format: "h1" },
    { title: "Heading 2", format: "h2" },
    { title: "Paragraph", format: "p" },
  ],
  plugins:
    "anchor autolink image lists media searchreplace table visualblocks wordcount code pagebreak hr",
  toolbar:
    "undo redo | fontfamily | styles fontsizeinput forecolor lineheight | bold italic underline strikethrough | image table | align | numlist bullist code pagebreak hr ",
  pagebreak_separator:
    "<div style='page-break-before: always; clear:both'></div>",
};

// nasimda, kabali,getsaav,fiveweeksstudio
export const tinyMCEkeys = [
  "ttbkryzh4eik8dq3nfzd6ipr5eif4p88ycv7d2rpxrw9kfca",
  "b1m7ctpemp4wqvrdr1dbhtwvh4ydre525ere3sep3tu5eur2",
  "p73dn6kxz2um6jrqzlzq9uk8nj2fiefzogfvvfl4l8xkn0bf",
  "31v39tcqnjr4dmuhbo7sqh4k415drnvbe6rcnwzsl5xmopn3",
];

const RichTextEditor = ({
  onChange,
  value,
  disabled = false,
  height = "800px",
}: Props) => {
  const editorRef = useRef<TinyMCEEditor | null>(null);

  return (
    <Editor
      disabled={disabled}
      onInit={(_, editor) => {
        // @ts-ignore
        editorRef.current = editor;
      }}
      onEditorChange={(content) => {
        if (onChange) onChange(content);
      }}
      value={value}
      apiKey={tinyMCEkeys[Math.floor(Math.random() * tinyMCEkeys.length)]}
      init={{
        ...defaultTinyConfig,
        height: height,
      }}
    />
  );
};

export default RichTextEditor;
