import { MultipleContactsInput } from "@/components/FormComponents";
import SelectableDateicker from "@/components/selectable-date-picker";
import { trpc } from "@/helpers/trpc";
import { activityItems } from "@/lib/constants";
import { capitalize } from "@heffl/ui/lib/utils";
import ActivityTimeline from "@/pages/crm/components/activity-timeline";
import { openGoogleMaps } from "@/pages/field-service/components/property-selector";
import {
  ActivityLinkBadge,
  remindersToPlainText,
} from "@/pages/personal-dashboard";
import {
  DateStringOption,
  deformatReminderDate,
} from "@heffl/server/src/helpers/utils/formatReminderDate";
import Schemas from "@heffl/server/src/schemas";
import TagsInput from "@heffl/ui/components/TagInput";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import TabsInput from "@heffl/ui/components/primitives/TabsInput";
import { Button } from "@heffl/ui/components/primitives/button";
import { DateTimePicker } from "@heffl/ui/components/primitives/datetime-picker";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Input } from "@heffl/ui/components/primitives/input";
import MiniRichTextEditor from "@heffl/ui/components/primitives/mini-rich-text-editor";
import { MultiSelect } from "@heffl/ui/components/primitives/multi-select";
import StripeTabs from "@heffl/ui/components/primitives/stripe-tabs";
import { Switch } from "@heffl/ui/components/primitives/switch";
import RenderHtml from "@heffl/ui/components/render-html";
import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";
import { dynamicDateFormatting } from "@heffl/ui/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import dayjs from "dayjs";
import {
  AlarmClock,
  CircleUserRound,
  Clock,
  MapPin,
  MessageCircle,
  NavigationIcon,
  Pencil,
  Trash2,
} from "lucide-react";
import { useEffect, useState } from "react";
import { UseFormReturn, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";
import Empty from "../Empty";
import InfoItemsHZ from "../InfoItemHZ";

export const activityDurations: DateStringOption[] = [
  {
    label: "15m",
    value: "15_minutes_after",
  },
  {
    label: "30m",
    value: "30_minutes_after",
  },
  {
    label: "1h",
    value: "1_hours_after",
  },
  {
    label: "2h",
    value: "2_hours_after",
  },
  {
    label: "1d",
    value: "1_days_after",
  },
];

export const reminderDurations: DateStringOption[] = [
  { label: "At event time", value: "AT_TASK_DUE_TIME" },
  {
    label: "30 minutes before",
    value: "30_minutes_before",
  },
  {
    label: "1 hour before",
    value: "1_hours_before",
  },
  {
    label: "1 day before",
    value: "1_days_before",
  },
];

const ActivityForm = ({
  form,
  edit,
}: {
  form: UseFormReturn<z.infer<typeof Schemas.crm.activity>>;
  edit?: boolean;
}) => {
  const type = form.watch("type");
  const startDate = form.watch("startDate");
  const title = form.watch("title");

  const { data: users } = trpc.users.list.useQuery();

  return (
    <>
      <FormField name="type">
        <TabsInput
          options={activityItems.map((item) => ({
            label: (
              <div key={item.value} className="flex items-center">
                <item.icon className="h-3.5" /> {item.label}
              </div>
            ),
            value: item.value,
          }))}
          onChange={(value) => {
            if (
              !edit &&
              (!title || activityItems.some((item) => item.label === title))
            ) {
              form.setValue(
                "title",
                activityItems.find((item) => item.value === value)?.label || ""
              );
            }
          }}
        />
      </FormField>
      <FormField name="title">
        <Input placeholder={capitalize(type)} />
      </FormField>

      <FormField name="description">
        <MiniRichTextEditor
          placeholder="Describe the activity, add notes etc.."
          height={100}
        />
      </FormField>
      <FormField label="Assigned to" name="activityAssignees">
        <MultiSelect
          placeholder="Assign team"
          icon={CircleUserRound}
          options={
            users?.map((user) => ({
              label: user.firstName,
              value: user.id,
            })) || []
          }
        />
      </FormField>
      <MultipleContactsInput
        name="activityAttendees"
        label="Guests"
        className="w-full"
        placeholder="Select contacts"
      />
      <FormField name="locationLink">
        <Input
          placeholder="Link to map"
          prefix={<MapPin className="w-5 h-5" />}
        />
      </FormField>
      <div className="flex flex-wrap gap-1 pb-4 sm:pb-0">
        <FormField className="w-fit" name="startDate">
          <DateTimePicker placeholder="Date" />
        </FormField>
        <FormField className="w-fit" name="endDate">
          <SelectableDateicker
            renderClassName="text-violet-500"
            icon={Clock}
            placeholder="Duration"
            ogDate={startDate || null}
            options={activityDurations}
          />
        </FormField>
        <FormField name="reminders" className="w-fit">
          <SelectableDateicker
            placeholder="Reminders"
            icon={AlarmClock}
            ogDate={startDate || null}
            options={reminderDurations}
          />
        </FormField>
      </div>
    </>
  );
};

type AddActivityModalProps = {
  open: boolean;
  onClose: () => void;
  leadId?: number;
  dealId?: number;
  contactId?: number;
  clientId?: number;
  defaultValues?: Partial<z.infer<typeof Schemas.crm.activity>>;
  quotationId?: number;
};

const AddActivityModal = (props: AddActivityModalProps) => {
  const form = useForm<z.infer<typeof Schemas.crm.activity>>({
    resolver: zodResolver(Schemas.crm.activity),
    defaultValues: {
      type: "todo",
      startDate: dayjs().toDate(),
      endDate: {
        date: dayjs().add(15, "minutes").toDate(),
        dateType: "15_minutes_after",
      },
    },
  });

  useEffect(() => {
    if (props.defaultValues) {
      form.reset({ ...form.getValues(), ...props.defaultValues });
    }
  }, [props.defaultValues, form]);

  const onClose = () => {
    form.reset();
    props.onClose();
  };

  const activityAddMutation = trpc.activities.add.useMutation({
    onSuccess: () => {
      toast.success("Activity added successfully");
      onClose();
    },
  });

  const onSubmit = (values: z.infer<typeof Schemas.crm.activity>) => {
    activityAddMutation.mutate({
      ...values,
      leadId: props.leadId,
      dealId: props.dealId,
      clientId: props.clientId,
      contactId: props.contactId,
      quotationId: props.quotationId,
    });
  };

  return (
    <ModalDrawer
      open={props.open}
      onClose={onClose}
      title="Add Activity"
      footer={
        <Button
          loading={activityAddMutation.isLoading}
          onClick={form.handleSubmit(onSubmit)}
          variant="primary"
          className="w-full"
          size="md"
        >
          Add Activity
        </Button>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <ActivityForm form={form} />
      </Form>
    </ModalDrawer>
  );
};

type EditActivityModalProps = {
  open: boolean;
  onClose: () => void;
  activityId: number;
};

const EditActivityModal = ({
  open,
  onClose,
  activityId,
}: EditActivityModalProps) => {
  const confirm = useConfirm();
  const form = useForm<z.infer<typeof Schemas.crm.activity>>({
    resolver: zodResolver(Schemas.crm.activity),
  });

  const { data: activity } = trpc.activities.details.useQuery(activityId!);

  const activityUpdateMutation = trpc.activities.update.useMutation({
    onSuccess: () => {
      toast.success("Activity updated successfully");
      onClose();
      form.reset();
    },
  });

  const activityDeleteMutation = trpc.activities.delete.useMutation({
    onSuccess: () => {
      toast.success("Activity deleted successfully");
      onClose();
    },
  });

  const onSubmit = (values: z.infer<typeof Schemas.crm.activity>) => {
    activityUpdateMutation.mutate({ id: activityId, activity: values });
  };

  useEffect(() => {
    if (activity) {
      form.reset({
        ...activity,
        endDate: deformatReminderDate({
          availableUnits: activityDurations,
          date: activity.endDate,
          ogDate: activity.startDate,
        }),
        activityAssignees: activity.activityAssignees.map((v) => v.users.id),
        activityAttendees: activity.activityAttendees.map((v) => v.contacts.id),
        reminders: activity?.reminders.length
          ? deformatReminderDate({
              availableUnits: reminderDurations,
              date: activity.reminders[0].date,
              ogDate: activity.startDate,
            })
          : undefined,
      });
    }
  }, [activity, form]);

  return (
    <ModalDrawer
      open={open}
      onClose={onClose}
      title="Edit Activity"
      footer={
        <div className="flex gap-2 w-full">
          <Button
            icon={Trash2}
            variant="destructiveOutline"
            onClick={async () => {
              const confirmed = await confirm({
                title: "Are you sure you want to delete this activity?",
              });
              if (confirmed) {
                activityDeleteMutation.mutate(activityId!);
              }
            }}
            size="md"
          />
          <Button
            size="md"
            loading={activityUpdateMutation.isLoading}
            onClick={form.handleSubmit(onSubmit)}
            variant="primary"
            className="w-full"
          >
            Update Activity
          </Button>
        </div>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <ActivityForm form={form} edit />
      </Form>
    </ModalDrawer>
  );
};

interface ActivityDetailsModalProps {
  open: boolean;
  onClose: () => void;
  activityId: number;
}

const ActivityDetailsModal: React.FC<ActivityDetailsModalProps> = ({
  open,
  onClose,
  activityId,
}) => {
  const [showEditActivityModal, setShowEditActivityModal] = useState(false);

  const { data: activity } = trpc.activities.details.useQuery(activityId);
  const { data: users } = trpc.users.list.useQuery();

  const activityUpdateMutation = trpc.activities.update.useMutation();

  return (
    <ModalDrawer
      open={open}
      onClose={onClose}
      title={
        <div className="flex justify-between w-full sm:pr-12">
          <p className="w-4/5 text-lg font-medium">{activity?.title}</p>
          <div className="flex gap-2 items-center">
            {activity?.locationLink && (
              <Button
                icon={NavigationIcon}
                size="xs"
                variant="primary"
                onClick={() =>
                  openGoogleMaps({
                    googleMapsLink: activity?.locationLink,
                  })
                }
              >
                Open Map
              </Button>
            )}
            <Button
              icon={Pencil}
              size="xs"
              variant="primaryOutline"
              onClick={() => setShowEditActivityModal(true)}
            >
              Edit
            </Button>
          </div>
        </div>
      }
    >
      <EditActivityModal
        open={showEditActivityModal}
        onClose={() => setShowEditActivityModal(false)}
        activityId={activityId}
      />
      {activity ? (
        <>
          <RenderHtml className="text-gray-600">
            {activity.description || "No description added"}
          </RenderHtml>
          <ActivityLinkBadge
            activity={activity}
            tooltipSide="right"
            className="mt-3"
          />
          <InfoItemsHZ
            className="mt-3"
            labelClassName="text-gray-500 font-normal"
            items={[
              {
                label: "Status",
                value: (
                  <Switch
                    checked={activity.completed}
                    onChange={() => {
                      activityUpdateMutation.mutate({
                        id: activityId,
                        activity: {
                          completed: !activity.completed,
                        },
                      });
                    }}
                  />
                ),
              },
              {
                label: "Assigned to",
                value: (
                  <TagsInput
                    emptyLabel="Not assigned"
                    className="flex-wrap mt-3"
                    value={activity.activityAssignees.map(
                      (user) => user.userId
                    )}
                    uniqueColor={true}
                    tags={users?.map((user) => ({
                      id: user.id,
                      name: user.firstName,
                    }))}
                    onChange={(value) => {
                      activityUpdateMutation.mutate({
                        id: activityId,
                        activity: {
                          activityAssignees: value,
                        },
                      });
                    }}
                  />
                ),
              },
              {
                label: "Date",
                value: <span>{dynamicDateFormatting(activity.startDate)}</span>,
              },
              {
                label: "Reminders",
                value: activity.reminders.length
                  ? activity.reminders
                      .map((reminder) =>
                        remindersToPlainText(reminder.date, reminder.dateType)
                      )
                      .join(", ")
                  : "No reminders",
              },
              {
                label: "Created By",
                value: activity.createdBy.firstName,
              },
              {
                label: "Completed at",
                value: activity.completedOn
                  ? dynamicDateFormatting(activity.completedOn)
                  : "Not completed",
              },
            ]}
          />
          <StripeTabs
            className="mt-4"
            items={[
              {
                label: "Messages",
                key: "messages",
                children: (
                  <div className="p-3 bg-gray-100 rounded-lg">
                    {!activity.comments?.length && (
                      <Empty
                        title="No messages"
                        description="Add a message to get started"
                        icon={MessageCircle}
                      />
                    )}
                    {activity.comments && (
                      <ActivityTimeline
                        activities={activity.comments.map((comment) => ({
                          type: "comment",
                          value: {
                            ...comment,
                            date: comment.createdAt,
                            commentFiles: [],
                          },
                        }))}
                      />
                    )}
                  </div>
                ),
                icon: MessageCircle,
                count: activity.comments.length,
              },
            ]}
          />
        </>
      ) : (
        <FullScreenSpinner />
      )}
    </ModalDrawer>
  );
};

export { ActivityDetailsModal, AddActivityModal, EditActivityModal };
