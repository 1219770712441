import DataTable from "@/components/DataTable";
import Empty from "@/components/Empty";
import { MultipleUserInput } from "@/components/FormComponents";
import InfoItemsHZ from "@/components/InfoItemHZ";
import { reminderDurations } from "@/components/actionsModals/activities-modals";
import SelectableDateicker from "@/components/selectable-date-picker";
import { trpc } from "@/helpers/trpc";
import { calculateCompleted } from "@heffl/ui/lib/utils";
import CircleTick from "@/pages/crm/deals/details/components/circle-tick";
import { remindersToPlainText } from "@/pages/personal-dashboard";
import Schemas from "@heffl/server/src/schemas";
import TagsInput from "@heffl/ui/components/TagInput";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Button } from "@heffl/ui/components/primitives/button";
import Select from "@heffl/ui/components/primitives/creatable-select";
import { DatePicker } from "@heffl/ui/components/primitives/datepicker";
import { DateTimePicker } from "@heffl/ui/components/primitives/datetime-picker";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import FormList from "@heffl/ui/components/primitives/form-list";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Input } from "@heffl/ui/components/primitives/input";
import MiniRichTextEditor from "@heffl/ui/components/primitives/mini-rich-text-editor";
import StripeTabs from "@heffl/ui/components/primitives/stripe-tabs";
import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";
import { cn } from "@heffl/ui/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import dayjs from "dayjs";
import { debounce } from "lodash";
import {
  AlarmClock,
  Clock,
  List,
  Pencil,
  Plus,
  Tag,
  ThumbsUp,
  Timer,
  Trash,
  Trash2,
  UserCircle,
} from "lucide-react";
import { useEffect, useState } from "react";
import { UseFormReturn, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";
import { AddTimesheetDrawer, EditTimesheetDrawer } from "../../timesheets/list";
import RenderHtml from "@heffl/ui/components/render-html";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { MultiSelect } from "@heffl/ui/components/primitives/multi-select";
import { getProjectTaskStatus } from "../../details/components/kanban";
import FilePicker from "@heffl/ui/components/file-picker";
import { map } from "radash";
import axios from "axios";
import { defaultFilesInput } from "@/pages/purchases/expenses/list";
import { FileCard } from "../../details/components/Files";
import { useNavigate } from "react-router-dom";

const defaultSubTask: {
  title: string;
  status: z.infer<typeof Schemas.project.projectSubTask>["status"];
} = {
  title: "",
  status: "OPEN",
};

const ProjectTaskForm = ({
  form,
  projectId,
}: {
  form: UseFormReturn<z.infer<typeof Schemas.project.projectTask>, unknown>;
  projectId: number;
}) => {
  const dueDate = form.watch("date");

  const { data: sections } = trpc.projects.sections.list.useQuery({
    projectId: projectId,
  });
  const { data: projectAssignees } = trpc.projects.details.useQuery(projectId);
  const { data: tags } = trpc.tags.list.useQuery({
    type: "PROJECT_TASK",
  });

  return (
    <div className="flex flex-col gap-2">
      <FormField name="title">
        <Input placeholder="Task title" />
      </FormField>
      <FormField name="description">
        <MiniRichTextEditor
          height={100}
          placeholder="Describe the task, note down points etc...."
        />
      </FormField>
      <FormList name="projectSubTasks">
        {({ fields, append, remove, _name }) => {
          return (
            <div className="flex flex-col gap-2">
              {fields.map((field, index) => (
                <div
                  className="flex flex-row gap-1 items-center w-full"
                  key={field.id}
                >
                  <FormField name={_name(index, "title")}>
                    <Input placeholder="Enter subtask" />
                  </FormField>
                  <Button
                    onClick={() => {
                      remove(index);
                    }}
                    className="ml-1 rounded-sm"
                  >
                    <Trash className="w-4 h-4 text-red-500" />
                  </Button>
                </div>
              ))}

              <Button
                onClick={() => {
                  append(defaultSubTask);
                }}
                size="sm"
                className="w-fit"
              >
                + Add Subtask
              </Button>
            </div>
          );
        }}
      </FormList>
      <div className="flex gap-2 items-center">
        <FormField name="projectSectionId" label="Section" className="w-1/3">
          <Select
            allowClear
            placeholder="Section"
            options={
              sections?.map((section) => ({
                label: section.title,
                value: section.id,
              })) || []
            }
          />
        </FormField>
        <MultipleUserInput
          name="projectTaskAssignees"
          label="Assignees"
          filter={
            projectAssignees?.projectAssignees.map((user) => user.userId) || []
          }
        />
      </div>
      <FormField name="projectTaskTags" label="Tags">
        <MultiSelect
          icon={Tag}
          placeholder="Add tags"
          options={tags?.map((tag) => ({
            label: tag.name,
            value: tag.id,
            color: tag.color,
          }))}
        />
      </FormField>
      <FormField name="files" label="Files">
        <FilePicker label="Upload file" allowMultiple />
      </FormField>
      <div className="flex flex-wrap gap-1 pb-4 sm:pb-0">
        <FormField name="date" className="w-fit">
          <DateTimePicker />
        </FormField>
        <FormField name="reminders" className="w-fit">
          <SelectableDateicker
            renderClassName="text-purple-500"
            placeholder="Reminders"
            icon={AlarmClock}
            ogDate={dueDate || null}
            options={reminderDurations}
          />
        </FormField>
      </div>
    </div>
  );
};

export const AddProjectTaskModal = ({
  open,
  onClose,
  defaultValues,
  projectId,
}: {
  open: boolean;
  onClose: () => void;
  defaultValues?: Partial<z.infer<typeof Schemas.project.projectTask>>;
  projectId: number;
}) => {
  const form = useForm<z.infer<typeof Schemas.project.projectTask>>({
    resolver: zodResolver(Schemas.project.projectTask),
    defaultValues: {
      title: "",
      projectSubTasks: [],
      date: dayjs().add(2, "days").set("h", 8).set("m", 0).toDate(),
      ...defaultValues,
      projectId,
    },
  });

  const [loading, setLoading] = useState(false);

  const { mutateAsync: getPresignedUrls } =
    trpc.files.presignedUrls.useMutation();

  const onCloseModal = () => {
    onClose();
    form.reset();
  };

  const addTaskMutation = trpc.projects.tasks.add.useMutation({
    onSuccess() {
      toast.success("Succesfully added task.");
      onCloseModal();
      form.reset();
    },
  });

  const onSubmit = async (
    values: z.infer<typeof Schemas.project.projectTask>
  ) => {
    setLoading(true);
    const presignedUrls = values?.files?.new.length
      ? await getPresignedUrls({
          files: values?.files?.new,
          section: "projects/tasks",
        })
      : [];
    addTaskMutation.mutate({
      ...values,
      files: {
        new: presignedUrls,
        deleted: [],
      },
    });
    await map(presignedUrls, async (file, index) => {
      const fileToUpload = values?.files?.new[index].file;
      await axios.put(file.presignedUrl, fileToUpload, {
        headers: {
          "Content-Type": file.format,
        },
      });
    });
    setLoading(false);
  };

  return (
    <ModalDrawer
      open={open}
      onClose={onCloseModal}
      title="Add task"
      footer={
        <Button
          loading={loading || addTaskMutation.isLoading}
          onClick={() => form.handleSubmit(onSubmit)()}
          variant="primary"
          className="w-full"
          size="md"
        >
          Add task
        </Button>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <ProjectTaskForm form={form} projectId={projectId} />
      </Form>
    </ModalDrawer>
  );
};

export const EditProjectTaskModal = ({
  open,
  onClose,
  id,
}: {
  open: boolean;
  onClose: ({ deleted }: { deleted: boolean }) => void;
  id: number;
}) => {
  const confirm = useConfirm();
  const form = useForm<z.infer<typeof Schemas.project.projectTask>>({
    resolver: zodResolver(Schemas.project.projectTask),
  });

  const { mutateAsync: getPresignedUrls } =
    trpc.files.presignedUrls.useMutation();

  const { data: taskDetails } = trpc.projects.tasks.details.useQuery(id);
  const taskDeleteMutation = trpc.projects.tasks.delete.useMutation({
    onSuccess() {
      toast.success("Succesfully deleted task.");
      onClose({ deleted: true });
      form.reset();
    },
  });

  useEffect(() => {
    if (taskDetails) {
      form.reset({
        ...taskDetails,
        projectTaskTags: taskDetails?.projectTaskTags.map((tag) => tag.tags.id),
        reminders: taskDetails?.reminders.length
          ? {
              date: taskDetails.reminders[0].date,
              dateType: taskDetails.reminders[0].dateType,
            }
          : undefined,
        projectTaskAssignees:
          taskDetails?.projectTaskAssignees.map((user) => user.userId) || [],
        files: defaultFilesInput(
          taskDetails?.files?.files?.length ? taskDetails?.files?.files : []
        ),
      });
    }
  }, [taskDetails, form]);

  const onCloseModal = () => {
    onClose({ deleted: false });
    form.reset();
  };

  const taskUpdateMutation = trpc.projects.tasks.update.useMutation({
    onSuccess() {
      toast.success("Succesfully updated task.");
      onCloseModal();
      form.reset();
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  const onSubmit = async (
    values: z.infer<typeof Schemas.project.projectTask>
  ) => {
    const presignedUrls = values?.files?.new.length
      ? await getPresignedUrls({
          files: values?.files?.new,
          section: "projects/tasks",
        })
      : [];

    taskUpdateMutation.mutate({
      id: id,
      task: {
        ...values,
        files: {
          deleted: [],
          ...values?.files,
          new: presignedUrls,
        },
      },
    });
    await map(presignedUrls, async (file, index) => {
      const fileToUpload = values?.files?.new[index].file;
      await axios.put(file.presignedUrl, fileToUpload, {
        headers: {
          "Content-Type": file.format,
        },
      });
    });
  };

  return (
    <ModalDrawer
      open={open}
      onClose={onCloseModal}
      title="Edit task"
      footer={
        <div className="flex gap-2 w-full">
          <Button
            size="md"
            loading={taskDeleteMutation.isLoading}
            icon={Trash2}
            variant="destructiveOutline"
            onClick={async () => {
              const confirmed = await confirm({
                title: "Are you sure you want to delete this task?",
              });
              if (confirmed) {
                taskDeleteMutation.mutate(id);
              }
            }}
          />
          <Button
            className="w-full"
            size="md"
            loading={taskUpdateMutation.isLoading}
            variant="primary"
            onClick={() => form.handleSubmit(onSubmit)()}
          >
            Update task
          </Button>
        </div>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        {taskDetails ? (
          <ProjectTaskForm form={form} projectId={taskDetails?.projectId} />
        ) : (
          <FullScreenSpinner />
        )}
      </Form>
    </ModalDrawer>
  );
};

export const ProjectTaskDetailsModal = ({
  open,
  onClose,
  taskId,
}: {
  open: boolean;
  onClose: () => void;
  taskId: number;
}) => {
  const navigate = useNavigate();

  const [showEditTaskModal, setShowEditTaskModal] = useState(false);
  const [tab, setTab] = useState<"timesheets" | "subtasks">("subtasks");
  const [addTimesheet, setAddTimesheet] = useState(false);
  const [editTimesheet, setEditTimesheet] = useState<number | undefined>(
    undefined
  );

  const { data: currentUser } = trpc.users.currentUser.useQuery();
  const { data: task } = trpc.projects.tasks.details.useQuery(taskId);
  const { data: users } = trpc.users.list.useQuery();
  const { data: tags } = trpc.tags.list.useQuery({
    type: "PROJECT_TASK",
  });
  const { data: projectDetails } = trpc.projects.details.useQuery(
    task?.projectId || 0,
    {
      enabled: !!task?.projectId,
    }
  );

  const realtimeTaskUpdateMutation = trpc.projects.tasks.update.useMutation({
    onSuccess() {
      return { invalidate: false };
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  const taskUpdateMutation = trpc.projects.tasks.update.useMutation({
    onError(error) {
      toast.error(error.message);
    },
  });

  const subTaskUpdateMutation = trpc.projects.tasks.updateSubTask.useMutation();
  const addSubTaskMutation = trpc.projects.tasks.addSubTask.useMutation();

  const debouncedTaskUpdateMutation = debounce(
    realtimeTaskUpdateMutation.mutate,
    500
  );

  const timesheetUpdateMutation = trpc.timesheets.update.useMutation();

  return (
    <ModalDrawer
      closeOnOverlayClick
      title={
        <div className="flex justify-between sm:pr-14">
          <p>{task?.title}</p>
          <Button
            icon={Pencil}
            size="xs"
            variant="primaryOutline"
            onClick={() => setShowEditTaskModal(true)}
          >
            Edit
          </Button>
        </div>
      }
      open={open}
      onClose={onClose}
      modalClassName="min-w-[700px]"
    >
      <EditProjectTaskModal
        open={showEditTaskModal}
        onClose={({ deleted }) => {
          setShowEditTaskModal(false);
          if (deleted) {
            onClose();
          }
        }}
        id={taskId}
      />
      <AddTimesheetDrawer
        open={addTimesheet}
        onClose={() => setAddTimesheet(false)}
        defaultValues={{ projectTaskId: taskId }}
        showTaskSelect={false}
      />
      {editTimesheet && (
        <EditTimesheetDrawer
          open={!!editTimesheet}
          onClose={() => setEditTimesheet(undefined)}
          timesheetId={editTimesheet}
        />
      )}

      {task ? (
        <>
          <div className="flex flex-col w-full sm:flex-row">
            <div className="pr-4 w-full sm:w-2/4">
              <MiniRichTextEditor
                inlineEdit
                placeholder="Describe the task, note down points etc...."
                value={task.description || ""}
                onChange={(value) => {
                  debouncedTaskUpdateMutation({
                    id: taskId,
                    task: {
                      description: value,
                    },
                  });
                }}
              />
              {!!task.files?.files.length && (
                <div className="flex flex-col gap-2 mt-4 w-full">
                  {task.files?.files.map((file) => (
                    <FileCard key={file.id} file={file} />
                  ))}
                </div>
              )}
            </div>
            <div className="flex mt-4 w-full sm:mt-0 sm:w-2/4">
              <InfoItemsHZ
                className="w-full"
                labelClassName="text-gray-500 font-normal"
                items={[
                  {
                    label: "Status",
                    value: (
                      <Badge
                        background={getProjectTaskStatus(task.status).hex}
                        small={false}
                      >
                        {getProjectTaskStatus(task.status).label}
                      </Badge>
                    ),
                  },
                  {
                    label: "Assigned",
                    value: (
                      <TagsInput
                        small={false}
                        emptyLabel="Not assigned"
                        className="flex-wrap mt-1"
                        value={task.projectTaskAssignees.map(
                          (user) => user.userId
                        )}
                        uniqueColor={true}
                        tags={users
                          ?.filter(
                            (user) =>
                              projectDetails?.projectAssignees?.some(
                                (projectAssignee) =>
                                  projectAssignee.userId === user.id
                              ) ?? true
                          )
                          ?.map((user) => ({
                            id: user.id,
                            name: user.firstName,
                            icon: UserCircle,
                          }))}
                        onChange={(value) => {
                          taskUpdateMutation.mutate({
                            id: taskId,
                            task: {
                              projectTaskAssignees: value,
                            },
                          });
                        }}
                      />
                    ),
                  },
                  {
                    label: "Due Date",
                    value: task.date ? (
                      <span
                        className={cn(
                          dayjs(task.date).isBefore(dayjs()) &&
                            task.status !== "COMPLETED" &&
                            "text-red-500"
                        )}
                      >
                        <DatePicker
                          value={task.date}
                          onChange={(value) => {
                            taskUpdateMutation.mutate({
                              id: taskId,
                              task: { date: value },
                            });
                          }}
                        />
                      </span>
                    ) : (
                      <span className="text-gray-500">No due date</span>
                    ),
                  },
                  {
                    label: "Project",
                    onClick: () => {
                      navigate(`/projects/details/${projectDetails?.id}`);
                    },
                    value: projectDetails?.title,
                  },
                  {
                    label: "Tags",
                    value: (
                      <TagsInput
                        tags={tags}
                        value={task?.projectTaskTags.map((tag) => tag.tags.id)}
                        onChange={(value) => {
                          taskUpdateMutation.mutate({
                            id: taskId,
                            task: { projectTaskTags: value },
                          });
                        }}
                      />
                    ),
                  },
                  {
                    label: "Reminders",
                    value: task.reminders.length
                      ? task.reminders
                          .map((reminder) =>
                            remindersToPlainText(
                              reminder.date,
                              reminder.dateType
                            )
                          )
                          .join(", ")
                      : "No reminders",
                  },
                  {
                    label: "Created By",
                    value: task.createdBy.firstName,
                  },
                ]}
              />
            </div>
          </div>

          <StripeTabs
            className="mt-3"
            items={[
              {
                label: "Subtasks",
                key: "subtasks",
                icon: List,
                count:
                  task.projectSubTasks.length -
                  calculateCompleted(
                    task.projectSubTasks,
                    "status",
                    "COMPLETED"
                  ),
              },
              {
                label: "Timesheets",
                key: "timesheets",
                icon: Timer,
                count: task.timesheets.length,
              },
            ]}
            value={tab}
            onChange={setTab}
          />
          {tab === "subtasks" && (
            <div className="flex flex-col gap-3 mt-2">
              <div className="flex justify-end">
                <Button
                  onClick={() => {
                    const subTask = prompt("Enter subtask title");
                    if (subTask) {
                      addSubTaskMutation.mutate({
                        parentTaskId: taskId,
                        title: subTask,
                      });
                    }
                  }}
                  variant="primary"
                  icon={Plus}
                  size="sm"
                >
                  Subtask
                </Button>
              </div>
              {task.projectSubTasks.length === 0 && (
                <Empty
                  title="No subtasks"
                  description="Add subtasks to this task"
                  icon={List}
                />
              )}
              {task.projectSubTasks.map((subTask) => (
                <div key={subTask.id} className="flex gap-2 items-center">
                  <CircleTick
                    checked={subTask.status === "COMPLETED"}
                    onClick={() => {
                      subTaskUpdateMutation.mutate({
                        id: subTask.id,
                        subTask: {
                          status:
                            subTask.status === "OPEN" ? "COMPLETED" : "OPEN",
                        },
                      });
                    }}
                  />
                  <p className="font-normal">{subTask.title}</p>
                </div>
              ))}
            </div>
          )}
          {tab === "timesheets" && (
            <div>
              <div className="flex justify-end mt-3">
                <Button
                  onClick={() => setAddTimesheet(true)}
                  variant="primary"
                  icon={Plus}
                  size="sm"
                >
                  Timesheet
                </Button>
              </div>
              <DataTable
                className="mt-3"
                data={task.timesheets}
                onRowClick={(timesheet) => {
                  setEditTimesheet(timesheet.id);
                }}
                columns={[
                  {
                    title: "Date",
                    render: (timesheet) => {
                      return (
                        <p>{dayjs(timesheet.startTime).format("DD/MM/YYYY")}</p>
                      );
                    },
                  },
                  {
                    title: "User",
                    render: (timesheet) => {
                      return (
                        <p>
                          {timesheet.users.firstName} {timesheet.users.lastName}
                        </p>
                      );
                    },
                  },
                  {
                    title: "Hours",
                    render: (timesheet) => {
                      return (
                        <p>
                          {Math.floor(timesheet.minutesWorked / 60)}h{" "}
                          {timesheet.minutesWorked % 60}m
                        </p>
                      );
                    },
                  },
                  {
                    title: "Status",
                    render: (timesheet) => {
                      return timesheet.approvalStatus === "APPROVED" ? (
                        <Badge variant="solidSuccess" icon={ThumbsUp}>
                          Approved
                        </Badge>
                      ) : (
                        <Badge variant="warning" icon={Clock}>
                          Pending
                        </Badge>
                      );
                    },
                  },
                  {
                    title: "Notes",
                    render: (timesheet) => {
                      return <RenderHtml>{timesheet.notes}</RenderHtml>;
                    },
                  },
                  {
                    title: "Actions",
                    render: (timesheet) => {
                      return (
                        <div className="flex gap-2">
                          {timesheet.approvalStatus === "OPEN" &&
                            currentUser &&
                            ["ADMIN", "SUPER_ADMIN"].includes(
                              currentUser.type
                            ) && (
                              <Button
                                onClick={() => {
                                  timesheetUpdateMutation.mutate({
                                    id: timesheet.id,
                                    timesheet: { approvalStatus: "APPROVED" },
                                  });
                                }}
                                size="sm"
                                icon={ThumbsUp}
                                variant="primary"
                                loading={timesheetUpdateMutation.isLoading}
                              >
                                Approve
                              </Button>
                            )}
                        </div>
                      );
                    },
                  },
                ]}
                rowKey="id"
                empty={{
                  title: "No timesheets",
                  description: "Add timesheets to this task",
                  icon: Timer,
                }}
              />
            </div>
          )}
        </>
      ) : (
        <FullScreenSpinner />
      )}
    </ModalDrawer>
  );
};
