import { RouterOutputs, trpc } from "@/helpers/trpc";
import enums from "@heffl/server/src/schemas/enums";
import Select from "@heffl/ui/components/primitives/creatable-select";
import { DatePicker } from "@heffl/ui/components/primitives/datepicker";
import { FormField } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Input } from "@heffl/ui/components/primitives/input";
import { match } from "ts-pattern";
import { z } from "zod";

export const preventInputMinus = (e: React.KeyboardEvent<HTMLInputElement>) => {
  if (e.code === "Minus") {
    e.preventDefault();
  }
};

type RenderCustomFieldsProps = {
  section: z.infer<typeof enums.customFieldSections>;
  dealPipelineId?: number | null;
  projectPipelineId?: number | null;
};

type CustomField =
  RouterOutputs["customizations"]["customFields"]["list"][number];

export const CustomFieldInput = ({
  field,
  onChange,
  value,
}: {
  field: CustomField;
  onChange?: (value: unknown) => void;
  value?: unknown;
}) => {
  return match(field)
    .with({ dataType: "TEXT" }, () => (
      <Input
        placeholder={field.placeholder}
        onChange={(e) => onChange?.(e.target.value)}
        value={value as string}
      />
    ))
    .with({ dataType: "NUMBER" }, () => (
      <Input
        type="number"
        onKeyDown={preventInputMinus}
        min="0"
        placeholder={field.placeholder}
        // for preventing number increment or decrement on scroll
        onWheel={(e) => e.currentTarget.blur()}
        onChange={(e) =>
          onChange?.(e.target.value ? Number(e.target.value) : undefined)
        }
        value={value as number}
      />
    ))
    .with({ dataType: "DATE" }, () => (
      <DatePicker
        placeholder={field.placeholder}
        onChange={onChange}
        value={value as Date}
      />
    ))
    .with({ dataType: "SINGLE_OPTION" }, () => (
      <Select
        placeholder={field.placeholder}
        options={field.values.map((field) => ({
          label: field,
          value: field,
        }))}
        allowClear={!field.required}
        onChange={onChange}
        value={value as string}
      />
    ))
    .exhaustive();
};

const CustomFieldsInputForm = ({
  section,
  dealPipelineId,
  projectPipelineId,
}: RenderCustomFieldsProps) => {
  const { data: customFields } = trpc.customizations.customFields.list.useQuery(
    {
      section,
      dealPipelineId,
      projectPipelineId,
    }
  );

  if (!customFields) {
    return <FullScreenSpinner />;
  }

  return (
    <>
      {customFields.map((field) => (
        <FormField
          name={`customFields.${field.name}`}
          key={field.id}
          label={field.label}
        >
          <CustomFieldInput key={field.id} field={field} />
        </FormField>
      ))}
    </>
  );
};

export default CustomFieldsInputForm;
