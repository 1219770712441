import FilePicker from "@/components/FilePicker";
import { PageHeight } from "@/components/page";
import { getS3URLLegacy } from "@/helpers/s3Helpers";
import { trpc } from "@/helpers/trpc";
import { DynamicTags } from "@heffl/server/src/helpers/templates/dynamicTags";
import Schemas from "@heffl/server/src/schemas";
import RichTextEditor from "@heffl/ui/components/primitives/RichTextEditor";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@heffl/ui/components/primitives/accordion";
import { Button } from "@heffl/ui/components/primitives/button";
import Select from "@heffl/ui/components/primitives/creatable-select";
import { FormField } from "@heffl/ui/components/primitives/form";
import { Input } from "@heffl/ui/components/primitives/input";
import { Label } from "@heffl/ui/components/primitives/label";
import {
  RadioGroup,
  RadioGroupItem,
} from "@heffl/ui/components/primitives/radio-group";
import {
  RawSelect,
  RawSelectItem,
} from "@heffl/ui/components/primitives/raw-select";
import { ScrollArea } from "@heffl/ui/components/primitives/scroll-area";
import {
  Sortable,
  SortableDragHandle,
  SortableItem,
} from "@heffl/ui/components/sortable";
import { cn } from "@heffl/ui/lib/utils";

import {
  AddCustomFieldModal,
  EditCustomFieldModal,
} from "@/pages/settings/custom-fields";
import {
  defaultLineItemsColumns,
  TemplateSettings,
} from "@heffl/server/src/helpers/templates/templateSettingsManifest";
import FormList from "@heffl/ui/components/primitives/form-list";
import { Switch } from "@heffl/ui/components/primitives/switch";
import { Tooltip } from "@heffl/ui/components/primitives/tooltip";
import TemplateRender from "@heffl/ui/components/template-render";
import axios from "axios";
import {
  Eye,
  EyeOff,
  FileText,
  FileType,
  GripVertical,
  Pencil,
  Plus,
  Trash,
  Type,
  Upload,
} from "lucide-react";
import { useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { z } from "zod";
import { templateTypes } from "../list";
import TemplateRenderEditable from "./TemplateRenderEditable";
import HefflFields from "./heffl-fields";

const TemplateAccordionItem = ({
  title,
  children,
  id,
  contentClassName,
}: {
  title: string;
  children: React.ReactNode;
  id?: string;
  contentClassName?: string;
}) => {
  return (
    <AccordionItem
      value={id || title}
      className="px-1 py-1 mb-2 text-base bg-white rounded-md border"
    >
      <AccordionTrigger iconPosition="end" className="px-2">
        {title}
      </AccordionTrigger>
      <AccordionContent className={cn("border-t py-3 !px-2", contentClassName)}>
        {children}
      </AccordionContent>
    </AccordionItem>
  );
};

export const RadioItem = ({
  value,
  label,
}: {
  value: string;
  label: string;
}) => {
  return (
    <div className="flex items-center space-x-2">
      <RadioGroupItem value={value} id={value} />
      <Label htmlFor={value} className="cursor-pointer">
        {label}
      </Label>
    </div>
  );
};

export const ColorPickerInput = ({
  value,
  onChange,
}: {
  value?: string;
  onChange?: (value: string) => void;
}) => {
  return (
    <input
      type="color"
      className="w-full h-10 cursor-ointer border-3"
      value={value}
      onChange={(e) => onChange && onChange(e.target.value)}
    />
  );
};

const tinymceFonts = [
  { label: "Andale Mono", value: "andale mono,times" },
  { label: "Arial", value: "arial,helvetica,sans-serif" },
  { label: "Arial Black", value: "arial black,avant garde" },
  { label: "Book Antiqua", value: "book antiqua,palatino" },
  { label: "Comic Sans MS", value: "comic sans ms,sans-serif" },
  { label: "Courier New", value: "courier new,courier" },
  { label: "Georgia", value: "georgia,palatino" },
  { label: "Helvetica", value: "helvetica" },
  { label: "Impact", value: "impact,chicago" },
  { label: "Symbol", value: "symbol" },
  { label: "Tahoma", value: "tahoma,arial,helvetica,sans-serif" },
  { label: "Terminal", value: "terminal,monaco" },
  { label: "Times New Roman", value: "times new roman,times" },
  { label: "Trebuchet MS", value: "trebuchet ms,geneva" },
  { label: "Verdana", value: "verdana,geneva" },
  { label: "Webdings", value: "webdings" },
  { label: "Wingdings", value: "wingdings,zapf dingbats" },
];

const FieldColumnCard = ({
  field,
  hidden,
  onVisibilityChange,
  onEdit,
}: {
  field: TemplateSettings["lineItemColumns"][number];
  hidden: boolean;
  onVisibilityChange: () => void;
  onEdit?: () => void;
}) => {
  return (
    <div
      key={field.name}
      className="flex gap-2 justify-between items-center p-2 pr-2 rounded-md border shadow-sm"
    >
      <span className="flex gap-1 items-center">
        {!hidden && (
          <SortableDragHandle variant="ghost" size="xs">
            <GripVertical className="w-4 h-4 cursor-pointer" />
          </SortableDragHandle>
        )}
        <Type className="w-4 h-4" />
        <div className="text-sm">{field.label}</div>
      </span>
      <div className="flex gap-0.5 items-center">
        {onEdit && (
          <Button
            size="xs"
            variant="ghost"
            onClick={onEdit}
            icon={Pencil}
            iconClassName="w-4 h-4"
          />
        )}
        {field.type === "custom" && (
          <Tooltip content="This is a custom field">
            <FileType className="w-4 h-4 text-yellow-500" />
          </Tooltip>
        )}
        <Button
          size="xs"
          variant="ghost"
          onClick={onVisibilityChange}
          icon={hidden ? EyeOff : Eye}
          iconClassName="w-4 h-4"
        />
      </div>
    </div>
  );
};

const TemplateForm = ({
  form,
  id,
}: {
  form: UseFormReturn<z.infer<typeof Schemas.crm.template>, unknown>;
  id: number;
  edit?: boolean;
}) => {
  const contentHtml = form.watch("contentHtml");

  const [dynamicTagsInput, setDynamicTagsInput] = useState<
    Array<keyof DynamicTags>
  >(["Client", "Contact", "Quote"]);
  const [previewMode, setPreviewMode] = useState<boolean>(false);
  const [customizeHeader, setCustomizeHeader] = useState<boolean>(false);
  const [customizeFooter, setCustomizeFooter] = useState<boolean>(false);
  const [editCustomFieldId, setEditCustomFieldId] = useState<number | null>(
    null
  );
  const [showAddCustomField, setShowAddCustomField] = useState<boolean>(false);

  const { data: customFields } = trpc.customizations.customFields.list.useQuery(
    {
      section: "QUOTATION_LINE_ITEM",
      documentTemplateId: id,
    }
  );
  const { data: templateDetails } = trpc.templates.details.useQuery(id);
  const uploadTemplateImageMutation = trpc.templates.uploadImage.useMutation();

  const selectedType = form.watch("type");
  const headerBackgroundImage = form.watch(
    "templateProperties.headerBackgroundImage"
  );
  const footerBackgroundImage = form.watch(
    "templateProperties.footerBackgroundImage"
  );

  useEffect(() => {
    if (selectedType === "QUOTATION") {
      setDynamicTagsInput(["Client", "Contact", "Quote"]);
    } else if (selectedType === "INVOICE") {
      setDynamicTagsInput(["Client", "Contact", "Invoice", "ServiceJob"]);
    } else if (selectedType === "PROFORMA_INVOICE") {
      setDynamicTagsInput(["Client", "Contact", "ProformaInvoice"]);
    } else if (selectedType === "PAYMENT") {
      setDynamicTagsInput(["Client", "Invoice", "Payment"]);
    } else if (selectedType === "JOB") {
      setDynamicTagsInput(["Client", "ServiceJob", "ServiceProperty"]);
    } else if (selectedType === "PURCHASE_ORDER") {
      setDynamicTagsInput(["Vendor", "PurchaseOrder", "Contact"]);
    } else if (selectedType === "BILL") {
      setDynamicTagsInput(["Vendor", "Bill"]);
    } else if (selectedType === "PRODUCT") {
      setDynamicTagsInput([]);
    }
  }, [selectedType]);

  const allFields: TemplateSettings["lineItemColumns"][number][] = [
    ...defaultLineItemsColumns.map((field) => ({
      name: field.name,
      label: field.label,
      type: "default" as const,
      width: field.width,
      contentStyle: field.contentStyle,
      style: field.style,
    })),
    ...(customFields || []).map((field) => ({
      name: field.name,
      label: field.label,
      type: "custom" as const,
      width: null,
      contentStyle: "",
      style: "",
    })),
  ];

  return (
    <div
      className="flex flex-col gap-3 sm:flex-row"
      style={{
        height: PageHeight,
      }}
    >
      <AddCustomFieldModal
        open={showAddCustomField}
        onClose={() => setShowAddCustomField(false)}
        defaultValues={{
          section: "QUOTATION_LINE_ITEM",
          documentTemplateId: id,
        }}
      />
      {editCustomFieldId && (
        <EditCustomFieldModal
          open={true}
          onClose={() => setEditCustomFieldId(null)}
          customFieldId={editCustomFieldId}
        />
      )}
      <ScrollArea className="pb-16 space-y-3 w-full h-full sm:w-1/4">
        <Accordion
          type="single"
          collapsible
          className="w-full"
          defaultValue="Template properties"
        >
          <TemplateAccordionItem
            title="Template properties"
            contentClassName="space-y-3"
          >
            <FormField name="isActive" label="Active">
              <Switch />
            </FormField>
            <FormField name="name" label="Name">
              <Input placeholder="Template name" />
            </FormField>

            <FormField name="type" label="Type">
              <RawSelect>
                {templateTypes.map((type) => (
                  <RawSelectItem key={type.value} value={type.value}>
                    {type.label}
                  </RawSelectItem>
                ))}
              </RawSelect>
            </FormField>
            <FormField name="templateProperties.pageSize" label="Paper size">
              <RadioGroup className="flex gap-3 pt-1">
                <RadioItem value="A4" label="A4" />
                <RadioItem value="A5" label="A5" />
                <RadioItem value="Letter" label="Letter" />
              </RadioGroup>
            </FormField>
            <div>
              <Label>
                Margin{" "}
                <span className="text-xs font-light text-gray-700">
                  (in inches)
                </span>
              </Label>
              <div className="grid grid-cols-4 gap-2 mt-2 !text-xs font-light">
                <FormField
                  name="templateProperties.pageMarginTop"
                  miniLabel="Top"
                >
                  <Input placeholder="Top" className="text-right" />
                </FormField>
                <FormField
                  name="templateProperties.pageMarginBottom"
                  miniLabel="Bottom"
                >
                  <Input placeholder="Bottom" className="text-right" />
                </FormField>
                <FormField
                  name="templateProperties.pageMarginLeft"
                  miniLabel="Left"
                >
                  <Input placeholder="Left" className="text-right" />
                </FormField>
                <FormField
                  name="templateProperties.pageMarginRight"
                  miniLabel="Right"
                >
                  <Input placeholder="Right" className="text-right" />
                </FormField>
              </div>
            </div>
          </TemplateAccordionItem>
          <TemplateAccordionItem
            title="Line items"
            contentClassName="space-y-2"
          >
            <div className="grid grid-cols-5 gap-2">
              <FormField
                name="templateProperties.lineItemFont"
                label="Font"
                className="col-span-3"
              >
                <Select options={tinymceFonts} />
              </FormField>
              <FormField
                name="templateProperties.lineItemFontSize"
                label="Font size"
                className="col-span-2 pb-2"
              >
                <Input
                  placeholder="Font size"
                  type="number"
                  suffix="px"
                  suffixFilled
                />
              </FormField>
            </div>
            <Label>Header</Label>
            <div className="grid grid-cols-2 gap-4 pt-2">
              <FormField
                name="templateProperties.lineItemHeaderBackgroundColor"
                miniLabel="Background color"
              >
                <ColorPickerInput />
              </FormField>
              <FormField
                name="templateProperties.lineItemHeaderTextColor"
                miniLabel="Text color"
              >
                <ColorPickerInput />
              </FormField>
            </div>

            <div className="!mt-4">
              <div className="flex justify-between">
                <Label>Fields</Label>

                {selectedType === "QUOTATION" && (
                  <Button
                    icon={Plus}
                    onClick={() => setShowAddCustomField(true)}
                    size="xs"
                    variant="primaryOutline"
                  >
                    Field
                  </Button>
                )}
              </div>
              <div className="flex flex-col gap-2 mt-3">
                <FormList name="templateProperties.lineItemColumns">
                  {({ append, fields, remove, move }) => (
                    <div className="flex flex-col gap-2">
                      <Sortable
                        value={fields}
                        onMove={({ activeIndex, overIndex }) =>
                          move(activeIndex, overIndex)
                        }
                      >
                        {fields.map((field, index) => (
                          <SortableItem key={field.id} value={field.id}>
                            <FieldColumnCard
                              field={
                                field as unknown as TemplateSettings["lineItemColumns"][number]
                              }
                              hidden={false}
                              onVisibilityChange={() => remove(index)}
                              onEdit={
                                // @ts-ignore
                                field.type === "default"
                                  ? undefined
                                  : () => {
                                      const customField = customFields?.find(
                                        // @ts-ignore
                                        (f) => f.name === field.name
                                      );
                                      if (customField) {
                                        setEditCustomFieldId(customField.id);
                                      }
                                    }
                              }
                            />
                          </SortableItem>
                        ))}
                      </Sortable>
                      <p className="mt-2 text-sm font-medium">Hidden columns</p>
                      {allFields
                        .filter(
                          (field) =>
                            // @ts-ignore
                            !fields.find((f) => f.name === field.name)
                        )
                        .map((field) => (
                          <FieldColumnCard
                            key={field.name}
                            field={field}
                            hidden={true}
                            onVisibilityChange={() => append(field)}
                            onEdit={
                              field.type === "default"
                                ? undefined
                                : () => {
                                    const customField = customFields?.find(
                                      (f) => f.name === field.name
                                    );
                                    if (customField) {
                                      setEditCustomFieldId(customField.id);
                                    }
                                  }
                            }
                          />
                        ))}
                    </div>
                  )}
                </FormList>
              </div>
            </div>
          </TemplateAccordionItem>
          <TemplateAccordionItem title="Header" contentClassName="space-y-2">
            <FormField name="templateProperties.showHeader" label="Show header">
              <Switch />
            </FormField>

            {headerBackgroundImage && (
              <div className="flex gap-2 items-center">
                <img
                  src={getS3URLLegacy("templates", headerBackgroundImage)}
                  alt="header"
                  className="object-contain w-4/5 h-10 rounded-lg border-2 border-primary"
                />
                <Button
                  size="icon"
                  onClick={() => {
                    uploadTemplateImageMutation.mutate({
                      templateId: id,
                      type: "headerBackgroundImage",
                      action: "delete",
                      format: "image/jpeg",
                    });
                  }}
                  loading={uploadTemplateImageMutation.isLoading}
                >
                  <Trash className="text-red-500" />
                </Button>
              </div>
            )}
            <FormField
              name="templateProperties.headerBackgroundImage"
              miniLabel="Background image"
            >
              <FilePicker
                onPick={async (file) => {
                  if (file[0]) {
                    const { presignedUrl } =
                      await uploadTemplateImageMutation.mutateAsync({
                        format: file[0].type,
                        templateId: id,
                        type: "headerBackgroundImage",
                        action: "add",
                      });
                    if (presignedUrl) {
                      await axios.put(presignedUrl, file[0], {
                        headers: { "Content-Type": file[0].type },
                      });
                    }
                  }
                }}
                accept="image/*"
              >
                <Button className="w-full">
                  <Upload className="h-4" />
                  {headerBackgroundImage ? "Change image" : "Upload image"}
                </Button>
              </FilePicker>
            </FormField>
            <FormField
              name="templateProperties.headerBackgroundImagePosition"
              miniLabel="Position"
              className="!z-50"
            >
              <Select
                className="!z-50"
                allowClear={false}
                options={[
                  {
                    label: "Top",
                    value: "top",
                  },
                  {
                    label: "Center",
                    value: "center",
                  },
                  {
                    label: "Bottom",
                    value: "bottom",
                  },
                  {
                    label: "Left",
                    value: "left",
                  },
                  {
                    label: "Right",
                    value: "right",
                  },
                ]}
              />
            </FormField>
            <FormField
              name="templateProperties.headerFirstPageOnly"
              label="First page only"
            >
              <Switch />
            </FormField>

            <Button
              onClick={() => setCustomizeHeader(true)}
              className="w-full"
              icon={FileText}
              variant="primaryOutline"
            >
              Customize header content
            </Button>
          </TemplateAccordionItem>
          <TemplateAccordionItem title="Footer" contentClassName="space-y-2">
            <FormField name="templateProperties.showFooter" label="Show footer">
              <Switch />
            </FormField>

            {footerBackgroundImage && (
              <div className="flex gap-2 items-center">
                <img
                  src={getS3URLLegacy("templates", footerBackgroundImage)}
                  alt="footer"
                  className="object-contain w-4/5 h-10 rounded-lg border-2 border-primary"
                />
                <Button
                  size="icon"
                  onClick={() => {
                    uploadTemplateImageMutation.mutate({
                      templateId: id,
                      type: "footerBackgroundImage",
                      action: "delete",
                      format: "image/jpeg",
                    });
                  }}
                  loading={uploadTemplateImageMutation.isLoading}
                >
                  <Trash className="text-red-500" />
                </Button>
              </div>
            )}

            <FormField
              name="templateProperties.footerBackgroundImage"
              miniLabel="Background image"
            >
              <FilePicker
                onPick={async (file) => {
                  if (file[0]) {
                    const { presignedUrl } =
                      await uploadTemplateImageMutation.mutateAsync({
                        format: file[0].type,
                        templateId: id,
                        type: "footerBackgroundImage",
                        action: "add",
                      });
                    if (presignedUrl) {
                      await axios.put(presignedUrl, file[0], {
                        headers: { "Content-Type": file[0].type },
                      });
                    }
                  }
                }}
                accept="image/*"
              >
                <Button className="w-full">
                  <Upload className="h-4" />
                  {footerBackgroundImage ? "Change image" : "Upload image"}
                </Button>
              </FilePicker>
            </FormField>
            <FormField
              name="templateProperties.footerBackgroundImagePosition"
              miniLabel="Position"
              className="z-10"
            >
              <Select
                allowClear={false}
                options={[
                  {
                    label: "Top",
                    value: "top",
                  },
                  {
                    label: "Center",
                    value: "center",
                  },
                  {
                    label: "Bottom",
                    value: "bottom",
                  },
                  {
                    label: "Left",
                    value: "left",
                  },
                  {
                    label: "Right",
                    value: "right",
                  },
                ]}
              />
            </FormField>
            <Button
              onClick={() => setCustomizeFooter(true)}
              className="w-full"
              icon={FileText}
              variant="primaryOutline"
            >
              Customize footer content
            </Button>
          </TemplateAccordionItem>
        </Accordion>
        <HefflFields types={dynamicTagsInput} />
      </ScrollArea>

      <ScrollArea className="flex flex-col items-center w-full sm:w-3/4">
        <div className="flex items-end mb-4 w-full">
          <Button
            variant="primary"
            onClick={() => setPreviewMode(!previewMode)}
            className=""
            icon={previewMode ? Pencil : Eye}
          >
            {previewMode ? "Edit" : "Preview"}
          </Button>
        </div>
        {customizeHeader && (
          <FormField name="headerHtml" className="mt-3" label="Header content">
            <RichTextEditor height="250px" />
          </FormField>
        )}
        {customizeFooter && (
          <FormField name="footerHtml" className="mt-3" label="Footer content">
            <RichTextEditor height="250px" />
          </FormField>
        )}
        {previewMode && templateDetails ? (
          <TemplateRender
            contentHtml={contentHtml}
            template={templateDetails}
            className="h-auto bg-white shadow-xl"
          />
        ) : (
          <TemplateRenderEditable
            contentHtml={contentHtml}
            templateId={id}
            className="h-auto bg-white shadow-xl"
          />
        )}
      </ScrollArea>
    </div>
  );
};

export default TemplateForm;
