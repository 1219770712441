import FilterBar from "@/components/filters";
import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import { Card } from "@heffl/ui/components/primitives/card";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import {
  Tabs,
  TabsList,
  TabsTrigger,
} from "@heffl/ui/components/primitives/tabs";
import { DonutChart, Legend } from "@tremor/react";
import dayjs from "dayjs";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ActivityRow, TaskRow } from "../details/components/List";
import { EditProjectActivityModal } from "../details/components/project-activity-modals";
import Projectcard from "../list/components/projectCard";
import { EditProjectTaskModal } from "../tasks/components/project-task-modals";

type Filters = {
  dates: [Date, Date] | undefined;
};

const ProjectDashboard = () => {
  const [filters, setFilters] = useParamsState<Filters>({
    dates: [dayjs().subtract(30, "day").toDate(), dayjs().toDate()],
  });

  const { data: currentUser } = trpc.users.currentUser.useQuery();

  const [activityId, setActivityId] = useState<number | undefined>(undefined);
  const [taskId, setTaskId] = useState<number | undefined>(undefined);
  const { data } = trpc.projects.dashboard.personal.useQuery({
    startDate: filters.dates?.[0],
    endDate: filters.dates?.[1],
  });

  const navigate = useNavigate();

  if (!data || !currentUser) return <FullScreenSpinner />;
  return (
    <Page>
      {["ADMIN", "SUPER_ADMIN"].includes(currentUser.type) && (
        <Tabs value="personal" className="mb-3 w-fit">
          <TabsList className="grid grid-cols-2 w-full">
            <TabsTrigger
              value="personal"
              onClick={() => navigate("/projects/dashboard")}
            >
              Personal
            </TabsTrigger>
            <TabsTrigger
              value="admin"
              onClick={() => navigate("/projects/admin-dashboard")}
            >
              Admin
            </TabsTrigger>
          </TabsList>
        </Tabs>
      )}
      <FilterBar
        className=""
        filters={[
          {
            key: "date",
            type: "date-range",
            label: "Date",
            value: filters.dates,
            onChange: (value) =>
              setFilters({
                dates: value,
              }),
          },
        ]}
      />
      <div className="mt-4 space-y-4">
        {taskId && (
          <EditProjectTaskModal
            open={!!taskId}
            onClose={() => setTaskId(undefined)}
            id={taskId}
          />
        )}
        {activityId && (
          <EditProjectActivityModal
            open={true}
            onClose={() => setActivityId(undefined)}
            id={activityId}
          />
        )}
        <div className="grid grid-cols-2 gap-4 md:grid-cols-4 sm:grid-cols-4">
          <Card>
            <p className="text-sm font-medium text-gray-500">Total Projects</p>
            <p className="text-xl font-semibold sm:text-xl">
              {data.projects.total}
            </p>
          </Card>
          <Card>
            <p className="text-sm font-medium text-gray-500">
              Pending Projects
            </p>
            <p className="text-xl font-semibold sm:text-xl">
              {data.projects.pending}
            </p>
          </Card>
          <Card>
            <p className="text-sm font-medium text-gray-500">
              Completed Projects
            </p>
            <p className="text-xl font-semibold sm:text-xl">
              {data.projects.completed}
            </p>
          </Card>
          <Card>
            <p className="text-sm font-medium text-gray-500">Pending Tasks</p>
            <p className="text-xl font-semibold sm:text-xl">
              {data.projects.pendingTasks}
            </p>
          </Card>
        </div>

        <div className="grid grid-cols-1 gap-4 mt-4 sm:grid-cols-2">
          <Card className="flex flex-col justify-center items-center">
            <p className="self-start mb-4 text-sm font-medium text-gray-500">
              Tasks by status
            </p>
            <DonutChart
              data={data.tasks}
              category="value"
              index="name"
              colors={["blue", "red", "green"]}
            />
            <Legend
              categories={["Open", "In Progress", "Completed"]}
              colors={["blue", "red", "green"]}
              className="mt-4"
            />
          </Card>

          <Card className="flex flex-col justify-center items-center">
            <p className="self-start mb-4 text-sm font-medium text-gray-500">
              Activity by status
            </p>
            <DonutChart
              data={data.activities}
              category="value"
              index="name"
              colors={["blue", "green"]}
            />
            <Legend
              categories={["Pending", "Completed"]}
              colors={["blue", "green"]}
              className="mt-4"
            />
          </Card>
        </div>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
          <Card className="sm:h-[600px] h-auto overflow-auto">
            <div>
              <h1 className="text-lg font-semibold">Pending Projects</h1>
              <div className="text-sm text-gray-600">
                You have {data.pendingProjects.length} pending projects
              </div>
            </div>
            <div className="flex flex-col gap-3 mt-4">
              {data.pendingProjects.length ? (
                data.pendingProjects?.map((project) => (
                  <Projectcard
                    key={project.id}
                    project={project}
                    dragOverlay={false}
                    className="!w-full !max-w-none"
                  />
                ))
              ) : (
                <div className="flex flex-col gap-2 justify-center items-center px-4 py-4 text-center">
                  <p className="text-sm text-gray-600">No pending projects</p>
                  <p className="w-1/2 text-sm text-center text-gray-500">
                    You have no pending projects
                  </p>
                </div>
              )}
            </div>
          </Card>
          <Card className="sm:h-[600px] h-auto overflow-auto">
            <div>
              <h1 className="text-lg font-semibold">Pending Activities</h1>
              <div className="text-sm text-gray-600">
                You have {data.pendingActivities.length} pending activities
              </div>
            </div>
            <div className="flex flex-col gap-3 mt-4">
              {data.pendingActivities.length ? (
                data.pendingActivities?.map((activity) => (
                  <ActivityRow
                    activity={activity}
                    onActivityClick={(id) => setActivityId(id)}
                    mobileView={true}
                    key={activity.id}
                  />
                ))
              ) : (
                <div className="flex flex-col gap-2 justify-center items-center px-4 py-4 text-center">
                  <p className="text-sm text-gray-600">No pending activities</p>
                  <p className="w-1/2 text-sm text-center text-gray-500">
                    You have no pending activities
                  </p>
                </div>
              )}
            </div>
          </Card>
          <Card className="sm:h-[600px] h-auto overflow-auto">
            <div>
              <h1 className="text-lg font-semibold">Pending Tasks</h1>
              <div className="text-sm text-gray-600">
                You have {data.pendingTasks.length} pending tasks
              </div>
            </div>
            <div className="flex flex-col gap-3 mt-4">
              {data.pendingTasks.length ? (
                data.pendingTasks?.map((task) => (
                  <TaskRow
                    task={task}
                    onTaskClick={(id) => setTaskId(id)}
                    mobileView={true}
                    key={task.id}
                  />
                ))
              ) : (
                <div className="flex flex-col gap-2 justify-center items-center px-4 py-4 text-center">
                  <p className="text-sm text-gray-600">No pending tasks</p>
                  <p className="w-1/2 text-sm text-center text-gray-500">
                    You have no pending tasks
                  </p>
                </div>
              )}
            </div>
          </Card>
        </div>
      </div>
    </Page>
  );
};

export default ProjectDashboard;
