import DropMenu from "@/components/DropMenu";
import Page from "@/components/page";
import { RouterOutputs, trpc } from "@/helpers/trpc";
import { Button } from "@heffl/ui/components/primitives/button";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import dayjs from "dayjs";
import {
  Ban,
  Box,
  Building2,
  Calendar,
  CalendarCheck,
  Check,
  CheckCircle2,
  CircleDollarSign,
  Home,
  Link,
  LucideIcon,
  MapPinIcon,
  MessageCircle,
  Minus,
  MoreVertical,
  Navigation,
  NotebookText,
  Pencil,
  PencilLine,
  Phone,
  Play,
  Plus,
  StickyNote,
  Truck,
  Wrench,
} from "lucide-react";
import { useEffect, useState } from "react";

import Empty from "@/components/Empty";
import { SearchInput } from "@/components/FormComponents";
import RenderHtml from "@heffl/ui/components/render-html";
import { Icons } from "@heffl/ui/components/icons";
import { getS3URLLegacy } from "@/helpers/s3Helpers";
import { formatCurrency, formatValue } from "@heffl/ui/lib/utils";
import ActivityTimeline from "@/pages/crm/components/activity-timeline";
import { ClientCard, ContactCard } from "@/pages/crm/deals/details";
import { CommentInput } from "@/pages/crm/leads/details";
import InvoiceCard from "@/pages/sales/invoices/components/InvoiceCard";
import { MobileLineItem } from "@/pages/sales/quotations/clientShare/MobileQuote";
import Schemas from "@heffl/server/src/schemas";
import enums from "@heffl/server/src/schemas/enums";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@heffl/ui/components/primitives/avatar";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Card } from "@heffl/ui/components/primitives/card";
import { Input } from "@heffl/ui/components/primitives/input";
import NextTabs from "@heffl/ui/components/tabs";
import { UAE_TAX } from "@heffl/ui/lib/constants";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { match } from "ts-pattern";
import { useImmer } from "use-immer";
import { z } from "zod";
import { openGoogleMaps } from "../../components/property-selector";
import { JobTypeBadge, ScheduleInvoiceBadge, getJobId } from "../../jobs/list";
import {
  DateTimePickerNew,
  EditScheduleDrawer,
} from "../../schedules/components/schduleDetailsModal";
import { getJobName } from "../../schedules/calendar";
import { calculateDuration } from "../dashboard";
import messageToMobileApp from "@heffl/ui/lib/messageToMobileApp";
import { isMobileApp } from "@heffl/ui/lib/utils";

export const scheduleStatuses: {
  name: z.infer<typeof enums.scheduleStatus>;
  icon: LucideIcon;
  label: string;
}[] = [
  {
    name: "SCHEDULED",
    icon: Calendar,
    label: "Scheduled",
  },
  {
    name: "CONFIRMED",
    icon: CalendarCheck,
    label: "Confirmed",
  },
  {
    name: "IN_PROGRESS",
    icon: Play,
    label: "In Progress",
  },
  {
    name: "COMPLETED",
    icon: CheckCircle2,
    label: "Completed",
  },
  {
    name: "CANCELLED",
    icon: Ban,
    label: "Cancelled",
  },
];

type UpdateScheduleStatusProps = {
  schedule: RouterOutputs["fieldService"]["schedules"]["details"];
  triggerShowAddParts: () => void;
};

const dateText: Record<z.infer<typeof enums.scheduleStatus>, string> = {
  CONFIRMED: "Started at",
  ARRIVED: "Arrived at",
  ON_MY_WAY: "Work started at",
  IN_PROGRESS: "Work completed at",
  SCHEDULED: "Scheduled at",
  CANCELLED: "Cancelled at",
  COMPLETED: "Completed at",
};

// TODO get location on drawer open
export const getCurrentLocation = (): Promise<{
  latitude: number | undefined;
  longitude: number | undefined;
}> => {
  return new Promise((resolve) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        () => {
          resolve({
            latitude: undefined,
            longitude: undefined,
          });
        },
        {
          enableHighAccuracy: false,
          timeout: 5000,
          maximumAge: 10000,
        }
      );
    } else {
      resolve({
        latitude: undefined,
        longitude: undefined,
      });
    }
  });
};

const UpdateScheduleStatus = ({
  schedule,
  triggerShowAddParts,
}: UpdateScheduleStatusProps) => {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [date, setDate] = useState<Date | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const updateScheduleStatus =
    trpc.fieldService.schedules.updateStatusWorker.useMutation({
      onSuccess: () => {
        toast.success("Schedule status updated");
        setShowDatePicker(false);
      },
    });

  const timeSelector = (
    <div className="flex flex-col gap-1 w-full">
      <p>{dateText[schedule.status]}</p>
      <DateTimePickerNew value={date} onChange={setDate} />
      <div className="flex gap-2 mt-3">
        <Button
          onClick={() => setShowDatePicker(false)}
          variant="destructiveOutline"
          className="w-1/3"
        >
          Cancel
        </Button>
        <Button
          loading={loading || updateScheduleStatus.isLoading}
          onClick={async () => {
            setLoading(true);
            const statusMap: {
              [key in
                | "CONFIRMED"
                | "ON_MY_WAY"
                | "IN_PROGRESS"
                | "ARRIVED"]: z.infer<typeof enums.scheduleStatus>;
            } = {
              CONFIRMED: "ON_MY_WAY",
              ON_MY_WAY: "ARRIVED",
              ARRIVED: "IN_PROGRESS",
              IN_PROGRESS: "COMPLETED",
            };
            if (!date) return;
            const { latitude = undefined, longitude = undefined } =
              await getCurrentLocation();
            updateScheduleStatus.mutate({
              fsScheduleId: schedule.id,
              newStatus:
                statusMap[
                  schedule.status as
                    | "CONFIRMED"
                    | "ON_MY_WAY"
                    | "IN_PROGRESS"
                    | "ARRIVED"
                ],
              date: date,
              oldStatus: schedule.status,
              latitude,
              longitude,
            });
            setLoading(false);
            if (schedule.status === "IN_PROGRESS") {
              triggerShowAddParts();
            }
          }}
          variant="primary"
          className="w-2/3"
        >
          Confirm
        </Button>
      </div>
    </div>
  );

  const calculateTravelTime = () => {
    const startDate = schedule.fsScheduleStatusTimeline.find(
      (status) =>
        status.oldStatus === "CONFIRMED" && status.newStatus === "ON_MY_WAY"
    )?.date;

    if (!startDate) return null;
    return calculateDuration(startDate, new Date());
  };

  const calculateWorkingTime = () => {
    const startDate = schedule.fsScheduleStatusTimeline.find(
      (status) =>
        status.oldStatus === "ARRIVED" && status.newStatus === "IN_PROGRESS"
    )?.date;

    if (!startDate) return null;
    return calculateDuration(startDate, new Date());
  };

  const getWorkingTime = () => {
    const startDate = schedule.fsScheduleStatusTimeline.find(
      (status) =>
        status.oldStatus === "ON_MY_WAY" && status.newStatus === "IN_PROGRESS"
    )?.date;
    const endDate = schedule.fsScheduleStatusTimeline.find(
      (status) =>
        status.oldStatus === "IN_PROGRESS" && status.newStatus === "COMPLETED"
    )?.date;

    if (!startDate || !endDate) return 0;
    return calculateDuration(startDate, endDate);
  };

  return (
    <div className="flex fixed right-0 bottom-0 z-30 justify-center items-center pt-6 pb-4 w-full bg-white rounded-t-2xl px-4 shadow-[-10px_-10px_30px_4px_rgba(0,0,0,0.1),_10px_10px_30px_4px_rgba(45,78,255,0.15)]">
      {showDatePicker
        ? timeSelector
        : match(schedule.status)
            .with("CONFIRMED", () => (
              <Button
                onClick={() => {
                  setShowDatePicker(true);
                  setDate(new Date());
                }}
                className="w-full text-white bg-orange-400"
                icon={Truck}
              >
                Mark on my way
              </Button>
            ))
            .with("ON_MY_WAY", () => (
              <div className="flex flex-col gap-2 items-start w-full">
                <Badge icon={Truck} variant="warning">
                  Travelling for {calculateTravelTime()}
                </Badge>
                <Button
                  onClick={() => {
                    setShowDatePicker(true);
                    setDate(new Date());
                  }}
                  variant="primary"
                  className="w-full bg-blue-500"
                  icon={Home}
                >
                  Mark arrived
                </Button>
              </div>
            ))
            .with("ARRIVED", () => (
              <div className="flex flex-col gap-2 items-start w-full">
                <Button
                  onClick={() => {
                    setShowDatePicker(true);
                    setDate(new Date());
                  }}
                  variant="primary"
                  className="w-full"
                  icon={Play}
                >
                  Start working
                </Button>
              </div>
            ))
            .with("IN_PROGRESS", () => (
              <div className="flex flex-col gap-2 items-start w-full">
                <Badge icon={Play} variant="neutral">
                  Working for {calculateWorkingTime()}
                </Badge>
                <Button
                  onClick={() => {
                    setShowDatePicker(true);
                    setDate(new Date());
                  }}
                  variant="primary"
                  className="w-full"
                  icon={Check}
                >
                  Mark as completed
                </Button>
              </div>
            ))
            .with("COMPLETED", () => (
              <div className="flex flex-col gap-2 items-start w-full">
                <Badge icon={Check} variant="success">
                  Work completed in {getWorkingTime()}
                </Badge>
                <Button
                  variant="primaryOutline"
                  className="w-full"
                  icon={Check}
                >
                  Completed
                </Button>
              </div>
            ))
            .otherwise(() => null)}
    </div>
  );
};
type MobileProductSelectorProps = {
  onSelect: (product: z.infer<typeof Schemas.fieldService.fsService>[]) => void;
  value: z.infer<typeof Schemas.fieldService.fsService>[];
  onClose: () => void;
};

export const messageOnWhatsApp = (phone: string, message: string) => {
  if (isMobileApp()) {
    return messageToMobileApp({
      action: "openExternalLink",
      data: `https://wa.me/${phone}?text=${message}`,
    });
  }
  window.open(`https://wa.me/${phone}?text=${message}`, "_blank");
};

export const callMobile = (phone: string) => {
  if (isMobileApp()) {
    return messageToMobileApp({
      action: "openExternalLink",
      data: `tel:${phone}`,
    });
  }
  window.open(`tel:${phone}`, "_blank");
};

export const openEmail = (email: string) => {
  if (isMobileApp()) {
    return messageToMobileApp({
      action: "openExternalLink",
      data: `mailto:${email}`,
    });
  }
  window.open(`mailto:${email}`, "_blank");
};

const MobileProductSelector = ({
  onClose,
  onSelect,
  value = [],
}: MobileProductSelectorProps) => {
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useImmer<
    z.infer<typeof Schemas.fieldService.fsService>[]
  >([]);

  const { data: products, isLoading } = trpc.products.list.useQuery({
    include: value.map((s) => s.productId).filter((id): id is number => !!id),
    pageNo: 1,
    pageSize: 30,
    search,
  });

  const onModalClose = () => {
    setSelected([]);
    setSearch("");
    onClose();
  };

  useEffect(() => {
    value && setSelected(value);
  }, [value]);

  return (
    <ModalDrawer
      title="Select products"
      open={true}
      onClose={onModalClose}
      drawerClassName="min-h-[90%]"
    >
      <div className="flex relative flex-col gap-2">
        <SearchInput
          value={search}
          onChange={setSearch}
          className="sticky top-0 w-full"
        />
        {/* add on status change */}
        {!!selected.length && !isLoading && (
          <div className="fixed left-0 bottom-4 px-4 w-full">
            <Button
              onClick={() => {
                const filtered = selected.filter((item) => item.quantity > 0);
                onSelect(filtered);
                onModalClose();
              }}
              className="w-full"
              variant="primary"
            >
              Update Parts{" "}
              <span className="pl-1 text-white">({selected.length})</span>
            </Button>
          </div>
        )}
        <div className="flex flex-col gap-2 pt-4">
          {!products?.products.length && (
            <Empty
              title="No products found"
              description="Try changing your search term or filter"
              icon={Box}
              className="mt-5"
            />
          )}
          {products?.products
            .sort((a, b) => {
              const aInValue = value?.some((v) => v.productId === a.id)
                ? -1
                : 1;
              const bInValue = value?.some((v) => v.productId === b.id)
                ? -1
                : 1;
              return aInValue - bInValue;
            })
            .map((product) => {
              const isSelected = selected.find(
                (s) => s.productId === product.id
              );
              return (
                <div
                  key={product.id}
                  className="flex gap-1 items-center border-b"
                >
                  <div
                    onClick={() => {
                      setSelected((draft) => {
                        const existing = draft.find(
                          (s) => s.productId === product.id
                        );
                        if (existing) {
                          draft.splice(draft.indexOf(existing), 1);
                        } else {
                          draft.push({
                            ...product,
                            tax: UAE_TAX,
                            productId: product.id,
                            quantity: 1,
                            price: product.price,
                            type: "PRODUCT",
                            viewType: "LINE_ITEM",
                          });
                        }
                      });
                    }}
                    className="flex flex-col gap-1 p-1 w-full"
                  >
                    <span className="flex gap-2 justify-between items-center text-sm font-medium">
                      <div className="flex gap-2 items-center w-2/3">
                        {isSelected && (
                          <div className="flex justify-center items-center p-1 w-5 h-5 bg-green-600 rounded-full pointer-events-none">
                            <Check className="!w-4 !h-4 rounded-full text-white pointer-events-none" />
                          </div>
                        )}
                        {product.name}
                      </div>
                      <p className="self-end text-sm text-gray-700">
                        {formatCurrency(product.price, "AED")}
                      </p>
                    </span>
                    <p className="w-4/5 text-xs font-normal text-gray-400">
                      {product.description}
                    </p>

                    {isSelected && (
                      <div className="flex gap-2 justify-end pt-2 pb-2">
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelected((draft) => {
                              const existingIndex = draft.findIndex(
                                (s) => s.productId === product.id
                              );
                              if (existingIndex !== -1) {
                                const existing = draft[existingIndex];
                                if (existing.quantity > 1) {
                                  existing.quantity -= 1;
                                } else {
                                  draft.splice(existingIndex, 1);
                                }
                              }
                            });
                          }}
                          icon={Minus}
                          size="xs"
                          variant="primaryOutline"
                          className="h-7"
                        />
                        <Input
                          value={
                            selected.find((s) => s.productId === product.id)
                              ?.quantity
                          }
                          onChange={(e) => {
                            setSelected((draft) => {
                              const existingIndex = draft.findIndex(
                                (s) => s.productId === product.id
                              );
                              if (existingIndex !== -1) {
                                draft[existingIndex].quantity = Number(
                                  e.target.value
                                );
                              }
                            });
                          }}
                          parentClassName="w-16"
                          className="h-7"
                          placeholder="Qty"
                          onClick={(e) => e.stopPropagation()}
                        />
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelected((draft) => {
                              const existingIndex = draft.findIndex(
                                (s) => s.productId === product.id
                              );
                              if (existingIndex !== -1) {
                                draft[existingIndex].quantity += 1;
                              }
                            });
                          }}
                          icon={Plus}
                          size="xs"
                          className="h-7"
                          variant="primaryOutline"
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </ModalDrawer>
  );
};

type TTab = "details" | "messages" | "services" | "linked";

const StaffAppJobDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const scheduleId = Number(params.id);

  const [showEdit, setShowEdit] = useState(false);
  const [showContactDrawer, setShowContactDrawer] = useState(false);
  const [showProductSelector, setShowProductSelector] = useState(false);
  const [tab, setTab] = useState<TTab>("details");

  const { data: schedule } = trpc.fieldService.schedules.details.useQuery({
    id: scheduleId,
  });

  const { data: messages, isLoading: isMessagesLoading } =
    trpc.fieldService.schedules.messages.useQuery(
      {
        scheduleId,
      },
      {
        enabled: tab === "messages",
      }
    );

  const updateScheduleMutation = trpc.fieldService.schedules.update.useMutation(
    {
      onMutate: async () => {
        toast.loading("Updating schedule");
      },
      onSuccess: () => {
        toast.dismiss();
        toast.success("Schedule updated successfully");
      },
      onError: (error) => {
        toast.dismiss();
        toast.error(error.message);
      },
    }
  );

  if (!schedule) return <FullScreenSpinner />;

  return (
    <Page
      className="relative pb-32"
      title={`${getJobId(schedule.fsJobs)}`}
      description={`${dayjs(schedule.startDate).format("DD MMM YYYY HH:mm A")}`}
      showBack
      suffix={
        <DropMenu
          items={[
            {
              label: "Create invoice",
              onClick: () => {
                navigate(
                  `/sales/invoices/add?fsJobId=${schedule.fsJobs.id}&fsScheduleId=${schedule.id}`
                );
              },
              icon: CircleDollarSign,
            },
            {
              label: "Edit",
              onClick: () => setShowEdit(true),
              icon: Pencil,
            },
          ]}
        >
          <Button variant="ghost" className="p-0">
            <MoreVertical className="w-6 h-6" />
          </Button>
        </DropMenu>
      }
    >
      {tab === "details" && (
        <UpdateScheduleStatus
          schedule={schedule}
          triggerShowAddParts={() => setShowProductSelector(true)}
        />
      )}
      {tab === "messages" && (
        <CommentInput
          fsScheduleId={schedule.id}
          className="fixed left-0 bottom-2 z-50 px-2 w-full"
        />
      )}
      <ModalDrawer
        open={showContactDrawer}
        onClose={() => setShowContactDrawer(false)}
        title="Contact Information"
      >
        <div className="flex flex-col gap-4">
          {schedule.fsJobs.siteContactPerson && (
            <ContactCard
              title="Site Contact Person"
              showCall
              contact={schedule.fsJobs.siteContactPerson}
            />
          )}
          {schedule.fsJobs.contactPerson && (
            <ContactCard showCall contact={schedule.fsJobs.contactPerson} />
          )}
        </div>
      </ModalDrawer>
      {showEdit && (
        <EditScheduleDrawer
          open={showEdit}
          onClose={() => setShowEdit(false)}
          scheduleId={schedule.id}
        />
      )}

      <NextTabs
        value={tab}
        onChange={setTab as (tab: TTab) => void}
        className="!px-2 w-full fixed top-18 left-0 z-10"
        contentClassName="pt-10"
        tabs={[
          {
            id: "details",
            label: "Details",
            content: (
              <div className="mt-3">
                <div className="flex flex-col items-center">
                  <div className="flex gap-1">
                    <JobTypeBadge type={schedule.fsJobs.type} />
                    <ScheduleInvoiceBadge schedule={schedule} />
                    {schedule.fsScheduleTags.map((tag) => (
                      <Badge
                        key={tag.id}
                        className="text-sm"
                        background={tag.tags.color}
                      >
                        {tag.tags.name}
                      </Badge>
                    ))}
                  </div>
                  <p
                    className="flex items-center mt-2 text-lg font-medium cursor-pointer hover:underline hover:text-primary-500"
                    onClick={() => {
                      navigate(
                        `/field-service/jobs/details/${schedule.fsJobs.id}`
                      );
                    }}
                  >
                    {getJobName(schedule.fsJobs.fsJobServices)}{" "}
                    <Link className="ml-2 h-4 text-primary-500" />
                  </p>

                  <div className="flex items-center mt-2 text-sm text-gray-700">
                    <Calendar className="mr-2 w-4 h-4" />
                    {dayjs(schedule.startDate).format("DD MMM YYYY hh:mm A")}
                    {schedule.endDate &&
                    !dayjs(schedule.startDate).isSame(schedule.endDate, "day")
                      ? ` - ${dayjs(schedule.endDate).format(
                          "DD MMM YYYY hh:mm A"
                        )}`
                      : ` - ${dayjs(schedule.endDate).format("hh:mm A")}`}
                  </div>
                  <p className="flex gap-1 items-center">
                    <Building2 className="w-4 h-4 text-red-600" />
                    {schedule.fsJobs.fsProperties.clients.name}
                    <MapPinIcon className="ml-3 w-4 h-4 text-purple-600" />
                    {formatValue.propertyName(schedule.fsJobs.fsProperties)}
                  </p>
                </div>
                <div className="grid grid-cols-4 gap-3 mt-4">
                  <div className="space-y-1 text-center">
                    <Button
                      onClick={() => setShowContactDrawer(true)}
                      variant="secondary"
                      className="rounded-full"
                      size="icon"
                    >
                      <Phone className="w-5 h-5 text-primary-800" />
                    </Button>
                    <p>Call</p>
                  </div>
                  <div className="space-y-1 text-center">
                    <Button
                      onClick={() => {
                        openGoogleMaps(schedule.fsJobs.fsProperties);
                      }}
                      variant="secondary"
                      className="rounded-full"
                      size="icon"
                    >
                      <Navigation className="w-5 h-5 text-primary-800" />
                    </Button>
                    <p>Map</p>
                  </div>
                  <div className="space-y-1 text-center">
                    <Button
                      variant="secondary"
                      className="rounded-full"
                      size="icon"
                      onClick={() => {
                        setTab("messages");
                      }}
                    >
                      <StickyNote className="w-5 h-5 text-primary-800" />
                    </Button>

                    <p>Add note</p>
                  </div>
                  <div className="space-y-1 text-center">
                    <DropMenu
                      items={[
                        {
                          iconClassName: "text-green-600",
                          icon: CircleDollarSign,
                          label: "Add invoice",
                          onClick: () => {
                            navigate(
                              `/sales/invoices/add?fsJobId=${schedule.fsJobs.id}&fsScheduleId=${schedule.id}`
                            );
                          },
                        },
                      ]}
                    >
                      <Button
                        variant="secondary"
                        className="rounded-full"
                        size="icon"
                      >
                        <Plus className="w-5 h-5 text-primary-800" />
                      </Button>
                    </DropMenu>
                    <p>New</p>
                  </div>
                </div>
                <div className="flex gap-1 items-center mt-4">
                  <MapPinIcon className="w-4 h-4 text-green-600" /> Address
                </div>
                <div className="flex justify-between">
                  <div className="mt-1 w-2/3 text-sm text-gray-700">
                    {formatValue.propertyName(schedule.fsJobs.fsProperties)}
                    <p className="font-normal text-gray-500">
                      {formatValue.address(schedule.fsJobs.fsProperties)}
                    </p>
                  </div>
                </div>
                <div className="flex gap-1 items-center mt-2">
                  <NotebookText className="w-4 h-4 text-purple-500" />
                  Instructions
                </div>
                <RenderHtml>
                  {schedule.fsJobs.instructions || "No instructions"}
                </RenderHtml>
                <div className="flex gap-1 items-center mt-2">
                  <StickyNote className="w-4 h-4 text-yellow-500" />
                  Notes
                </div>
                <RenderHtml>
                  {schedule.fsJobs.internalNotes || "No notes"}
                </RenderHtml>
                <p className="mt-2 mb-1 font-medium">Client</p>
                <ClientCard company={schedule.fsJobs.fsProperties.clients} />
                <p className="mt-2 mb-1 font-medium">Contacts</p>
                <div className="flex flex-col gap-2">
                  {schedule.fsJobs.siteContactPerson && (
                    <ContactCard
                      showCall
                      contact={schedule.fsJobs.siteContactPerson}
                    />
                  )}
                  {schedule.fsJobs.contactPerson && (
                    <ContactCard
                      showCall
                      contact={schedule.fsJobs.contactPerson}
                    />
                  )}
                </div>
                <p className="mt-4 mb-1 font-medium">Assignees</p>
                <div className="flex flex-col gap-4 mt-3">
                  {schedule.fsScheduleAssignees.map((assignee) => (
                    <div key={assignee.id} className="flex gap-2">
                      <Avatar>
                        {assignee.users.profileImage && (
                          <AvatarImage
                            src={getS3URLLegacy(
                              "users/profileImages",
                              assignee.users.profileImage
                            )}
                            alt={`${assignee.users.firstName} ${assignee.users.lastName}`}
                          />
                        )}
                        <AvatarFallback>
                          {assignee.users.firstName[0]}
                        </AvatarFallback>
                      </Avatar>
                      <div className="flex justify-between w-full">
                        <div>
                          <p className="font-medium capitalize">{`${assignee.users.firstName} ${assignee.users.lastName}`}</p>
                          <p className="text-sm text-gray-500">
                            {assignee.users.phone || "No phone number"}
                          </p>
                        </div>
                        <div className="flex gap-">
                          <Button
                            onClick={() => {
                              assignee.users.phone &&
                                messageOnWhatsApp(assignee.users.phone, "Call");
                            }}
                            size="icon"
                            variant="ghost"
                            disabled={!assignee.users.phone}
                          >
                            <Icons.whatsapp className="w-5 h-5 text-primary-800" />
                          </Button>
                          <Button
                            disabled={!assignee.users.phone}
                            onClick={() => {
                              assignee.users.phone &&
                                callMobile(assignee.users.phone);
                            }}
                            size="icon"
                            variant="ghost"
                          >
                            <Phone className="w-5 h-5 text-primary-800" />
                          </Button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ),
          },
          {
            id: "messages",
            label: "Messages",
            content: (
              <div className="">
                {!messages?.length && !isMessagesLoading && (
                  <Empty
                    title="No messages"
                    description="Add a message to get started"
                    icon={MessageCircle}
                  />
                )}
                {isMessagesLoading && <FullScreenSpinner />}
                {messages && <ActivityTimeline activities={messages} />}
              </div>
            ),
          },
          {
            id: "services",
            label: "Services",
            content: (
              <div className="flex flex-col gap-2">
                {showProductSelector && (
                  <MobileProductSelector
                    value={schedule.fsScheduleParts}
                    onClose={() => setShowProductSelector(false)}
                    onSelect={(updatedParts) => {
                      updateScheduleMutation.mutate({
                        skipJobStatusCheck: true,
                        id: schedule.id,
                        schedule: {
                          fsScheduleParts: updatedParts,
                        },
                      });
                    }}
                  />
                )}
                <Card
                  className="flex flex-col gap-3"
                  parentClassName="mt-1"
                  title={`Parts (${
                    schedule.fsScheduleParts.filter(
                      (service) => service.type === "PRODUCT"
                    ).length
                  })`}
                  suffix={
                    <div className="flex">
                      <Button
                        onClick={() => setShowProductSelector(true)}
                        variant="ghost"
                        icon={PencilLine}
                        size="xs"
                        iconClassName="h-5"
                      />
                    </div>
                  }
                >
                  {schedule.fsScheduleParts
                    .filter((service) => service.type === "PRODUCT")
                    .map((product, index) => (
                      <MobileLineItem
                        onClick={() => {
                          setShowProductSelector(true);
                        }}
                        key={index}
                        product={product}
                      />
                    ))}
                  {schedule.fsScheduleParts.filter(
                    (service) => service.type === "PRODUCT"
                  ).length === 0 && (
                    <Empty
                      title="No parts added"
                      description="Try changing your search term or filter"
                      icon={Box}
                      actionText="Add Parts"
                      onAction={() => setShowProductSelector(true)}
                    />
                  )}
                </Card>
                {schedule.fsScheduleParts.filter(
                  (service) => service.type === "SERVICE"
                ).length > 0 && (
                  <Card
                    className="flex flex-col gap-3"
                    parentClassName="mt-1"
                    title={`Schedule Services (${
                      schedule.fsScheduleParts.filter(
                        (service) => service.type === "SERVICE"
                      ).length
                    })`}
                  >
                    {schedule.fsScheduleParts
                      .filter((service) => service.type === "SERVICE")
                      .map((product, index) => (
                        <MobileLineItem key={index} product={product} />
                      ))}
                    {schedule.fsScheduleParts.filter(
                      (service) => service.type === "SERVICE"
                    ).length === 0 && (
                      <Empty
                        title="No services added"
                        description="Try changing your search term or filter"
                        icon={Wrench}
                        className="mt-5"
                      />
                    )}
                  </Card>
                )}
                <Card
                  className="flex flex-col gap-3"
                  parentClassName="mt-1"
                  title={`Job Services (${
                    schedule.fsJobs.fsJobServices.filter(
                      (service) => service.type === "SERVICE"
                    ).length
                  })`}
                >
                  {schedule.fsJobs.fsJobServices
                    .filter((service) => service.type === "SERVICE")
                    .map((product, index) => (
                      <MobileLineItem key={index} product={product} />
                    ))}
                  {schedule.fsJobs.fsJobServices.filter(
                    (service) => service.type === "SERVICE"
                  ).length === 0 && (
                    <Empty
                      title="No services found"
                      description="Try changing your search term or filter"
                      icon={Box}
                      className="mt-5"
                    />
                  )}
                </Card>
              </div>
            ),
          },
          {
            id: "linked",
            label: "Linked",
            content: (
              <div className="flex flex-col gap-2">
                <p>Invoices ({schedule.fsJobInvoices.length}) </p>
                {schedule.fsJobInvoices.map((invoice) => (
                  <InvoiceCard key={invoice.id} data={invoice.invoices} />
                ))}
              </div>
            ),
          },
        ]}
      />
    </Page>
  );
};

export default StaffAppJobDetails;
