import DataGrid from "@/components/dataGrid/DataGrid";
import { SearchInput } from "@/components/FormComponents";
import Page from "@/components/page";
import { getS3URLLegacy } from "@/helpers/s3Helpers";
import { trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Button } from "@heffl/ui/components/primitives/button";
import ResponsiveActionButton from "@heffl/ui/components/primitives/responsive-action-button";
import { formatCurrency } from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import { DollarSign, Paperclip } from "lucide-react";
import { capitalize, map } from "radash";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import FilterBar from "@/components/filters";
import { UserInput } from "@/components/FormComponents";
import { DocumentViewer } from "@/pages/files";
import Schemas from "@heffl/server/src/schemas";
import enums, { enumsToOptions } from "@heffl/server/src/schemas/enums";
import FilePicker from "@heffl/ui/components/file-picker";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import Select from "@heffl/ui/components/primitives/creatable-select";
import { DatePicker } from "@heffl/ui/components/primitives/datepicker";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Input } from "@heffl/ui/components/primitives/input";
import { Textarea } from "@heffl/ui/components/primitives/textarea";
import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";
import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import { Trash2 } from "lucide-react";
import { useEffect } from "react";
import { UseFormReturn, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";

export const ExpenseForm = ({
  form,
  edit = false,
}: {
  form: UseFormReturn<z.infer<typeof Schemas.expenses.expense>, unknown>;
  defaultValues?: Partial<z.infer<typeof Schemas.expenses.expense>>;
  edit?: boolean;
}) => {
  const [showAddExpenseCategoryDrawer, setShowAddExpenseCategoryDrawer] =
    useState(false);

  const { data: expenseCategories } = trpc.expenses.categories.list.useQuery();
  const { data: currentUser } = trpc.users.currentUser.useQuery();

  useEffect(() => {
    if (currentUser && !edit) {
      form.setValue("userId", currentUser.id);
    }
  }, [currentUser, form]);

  return (
    <>
      <AddExpenseCategoryDrawer
        open={showAddExpenseCategoryDrawer}
        onClose={(id) => {
          id && form.setValue("expenseCategoryId", id);
          setShowAddExpenseCategoryDrawer(false);
        }}
      />
      <div className="grid grid-cols-2 gap-3">
        <FormField name="date" label="Date">
          <DatePicker />
        </FormField>
        <UserInput name="userId" label="User" />
      </div>

      <FormField name="expenseCategoryId" label="Category">
        <Select
          placeholder="Select category"
          options={expenseCategories?.map((category) => ({
            label: category.name,
            value: category.id,
            systemGenerated: category.systemGenerated,
          }))}
          render={({ label, systemGenerated }) => (
            <div className="flex gap-2 items-center">
              <p>{label}</p>
              {systemGenerated && <Badge variant="warning">System</Badge>}
            </div>
          )}
          createButton={{
            label: "Add Category",
            onClick: () => setShowAddExpenseCategoryDrawer(true),
          }}
        />
      </FormField>

      <div className="grid grid-cols-2 gap-3">
        <FormField name="paidBy" label="Paid By" className="mb-4">
          <Select options={enums.expenses.paidBy.values} />
        </FormField>
        <FormField name="amount" label="Amount" className="mb-4">
          <Input type="number" placeholder="00.00" prefix="AED" prefixFilled />
        </FormField>
      </div>
      <FormField name="files.expense_reciept" label="Receipt" className="mb-4">
        <FilePicker />
      </FormField>
      <FormField name="notes" label="Notes" className="mb-4">
        <Textarea placeholder="Notes" />
      </FormField>
    </>
  );
};

export const AddExpenseCategoryDrawer = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: (id?: number) => void;
}) => {
  const form = useForm<
    z.infer<typeof Schemas.expenses.expenseCategories>,
    unknown
  >({
    defaultValues: {
      accountType: "EXPENSE",
    },
    resolver: zodResolver(Schemas.expenses.expenseCategories),
  });

  const addExpenseCategoryMutation = trpc.expenses.categories.add.useMutation({
    onSuccess: ({ id }) => {
      toast.success("Expense category added successfully");
      onClose(id);
      form.reset();
    },
  });

  return (
    <ModalDrawer
      open={open}
      onClose={() => {
        onClose();
        form.reset();
      }}
      className="w-full sm:w-1/4"
      title="Add Expense Category"
      footer={
        <Button
          variant="primary"
          size="lg"
          type="submit"
          className="w-full"
          loading={addExpenseCategoryMutation.isLoading}
        >
          Add Category
        </Button>
      }
    >
      <Form
        {...form}
        onSubmit={(values) => addExpenseCategoryMutation.mutate(values)}
      >
        <FormField name="accountType" label="Account Type">
          <Select
            options={enumsToOptions(enums.financeAccountTypes, (label) =>
              capitalize(label.replaceAll("_", " "))
            )}
          />
        </FormField>
        <FormField name="name" label="Category Name">
          <Input type="text" placeholder="Enter category name" />
        </FormField>
      </Form>
    </ModalDrawer>
  );
};

export const defaultFilesInput = (
  files: z.infer<typeof Schemas.files.fileItem>[]
) => {
  return {
    new: [],
    deleted: [],
    existing: files ? files : [],
  };
};

export const AddExpenseDrawer = ({
  open,
  onClose,
  defaultValues,
}: {
  open: boolean;
  onClose: () => void;
  defaultValues?: Partial<z.infer<typeof Schemas.expenses.expense>> & {
    aggregatorCharge?: number;
  };
}) => {
  const [loading, setLoading] = useState(false);

  const { mutateAsync: getPresignedUrls } =
    trpc.files.presignedUrls.useMutation();

  const form = useForm<z.infer<typeof Schemas.expenses.expense>, unknown>({
    resolver: zodResolver(Schemas.expenses.expense),
    defaultValues: {
      date: new Date(),
      paidBy: "COMPANY",
      files: {
        expense_reciept: defaultFilesInput([]),
      },
      fsJobId: defaultValues?.fsJobId,
    },
  });

  useEffect(() => {
    if (defaultValues) {
      form.reset({
        ...form.getValues(),
        ...defaultValues,
      });
    }
  }, [defaultValues]);

  const onSubmit = async (values: z.infer<typeof Schemas.expenses.expense>) => {
    setLoading(true);
    const presignedUrls = values?.files?.expense_reciept.new.length
      ? await getPresignedUrls({
          files: values?.files?.expense_reciept.new,
          section: "expenses/receipts",
        })
      : [];
    addExpenseMutation.mutate({
      ...values,
      files: {
        expense_reciept: { new: presignedUrls, deleted: [] },
      },
    });
    await map(presignedUrls, async (file, index) => {
      const fileToUpload = values?.files?.expense_reciept.new[index].file;
      await axios.put(file.presignedUrl, fileToUpload, {
        headers: {
          "Content-Type": file.format,
        },
      });
    });
    setLoading(false);
  };

  const addExpenseMutation = trpc.expenses.add.useMutation({
    onSuccess: () => {
      toast.success("Expense added successfully");
      onClose();
      form.reset();
    },
    onError: () => {
      setLoading(false);
      toast.error("Something went wrongg");
    },
  });

  return (
    <ModalDrawer
      open={open}
      onClose={() => {
        onClose();
        form.reset();
      }}
      className="w-full sm:w-1/4"
      title="Add Expense"
      footer={
        <Button
          variant="primary"
          size="lg"
          type="submit"
          className="w-full"
          loading={loading || addExpenseMutation.isLoading}
          onClick={() => {
            form.handleSubmit(onSubmit)();
          }}
        >
          Add Expense
        </Button>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <ExpenseForm form={form} defaultValues={defaultValues} />
      </Form>
    </ModalDrawer>
  );
};

export const EditExpenseDrawer = ({
  open,
  onClose,
  expenseId,
}: {
  open: boolean;
  onClose: () => void;
  expenseId: number;
}) => {
  const confirm = useConfirm();

  const [loading, setLoading] = useState(false);

  const { data: expense } = trpc.expenses.details.useQuery({ id: expenseId });

  const { mutateAsync: getPresignedUrls } =
    trpc.files.presignedUrls.useMutation();

  const form = useForm<z.infer<typeof Schemas.expenses.expense>, unknown>({
    resolver: zodResolver(Schemas.expenses.expense),
    defaultValues: {
      date: new Date(),
      paidBy: "COMPANY",
    },
  });

  useEffect(() => {
    if (expense) {
      form.reset({
        ...expense,
        files: {
          expense_reciept: defaultFilesInput(
            expense.files?.fields?.expense_reciept
              ? [expense.files?.fields?.expense_reciept]
              : []
          ),
        },
      });
    }
  }, [expense]);

  const updateExpenseMutation = trpc.expenses.update.useMutation({
    onSuccess: () => {
      toast.success("Expense updated successfully");
      onClose();
      form.reset();
    },
    onError: () => {
      setLoading(false);
      toast.error("Something went wrong");
    },
  });

  const expenseDeleteMutation = trpc.expenses.delete.useMutation({
    onSuccess: () => {
      toast.success("Expense deleted successfully");
      onClose();
      form.reset();
    },
  });

  const onSubmit = async (values: z.infer<typeof Schemas.expenses.expense>) => {
    setLoading(true);
    const presignedUrls = values?.files?.expense_reciept.new.length
      ? await getPresignedUrls({
          files: values?.files?.expense_reciept.new,
          section: "expenses/receipts",
        })
      : [];
    updateExpenseMutation.mutate({
      id: expenseId,
      expense: {
        ...values,
        files: {
          expense_reciept: {
            deleted: [],
            ...values?.files?.expense_reciept,
            new: presignedUrls,
          },
        },
      },
    });
    await map(presignedUrls, async (file, index) => {
      const fileToUpload = values?.files?.expense_reciept.new[index].file;
      await axios.put(file.presignedUrl, fileToUpload, {
        headers: {
          "Content-Type": file.format,
        },
      });
    });
    setLoading(false);
  };

  return (
    <ModalDrawer
      open={open}
      onClose={() => {
        onClose();
        form.reset();
      }}
      className="w-full sm:w-1/4"
      title="Update Expense"
      footer={
        <div className="flex gap-2 justify-between pt-2 w-full">
          <Button
            icon={Trash2}
            variant="destructiveOutline"
            loading={expenseDeleteMutation.isLoading}
            onClick={async () => {
              const confirmed = await confirm({
                title: "Are you sure you want to delete this expense?",
              });
              if (confirmed) {
                expenseDeleteMutation.mutate(expenseId);
              }
            }}
          />
          <Button
            variant="primary"
            size="lg"
            type="submit"
            className="w-full"
            loading={loading || updateExpenseMutation.isLoading}
            onClick={() => {
              form.handleSubmit(onSubmit)();
            }}
          >
            Update Expense
          </Button>
        </div>
      }
    >
      {!expense ? (
        <FullScreenSpinner />
      ) : (
        <Form {...form} onSubmit={onSubmit}>
          <ExpenseForm form={form} edit />
        </Form>
      )}
    </ModalDrawer>
  );
};

type PageFilters = {
  search: string;
  pageNo: number;
  dates?: [Date, Date];
  payees: number[];
  categories: number[];
  pageSize: number;
};

const ExpensesList = () => {
  const navigate = useNavigate();

  const [filters, setFilters] = useParamsState<PageFilters>({
    search: "",
    pageNo: 1,
    dates: undefined,
    payees: [],
    categories: [],
    pageSize: 30,
  });

  const [showReceipt, setShowReceipt] = useState<{
    name: string;
    link: string;
  } | null>(null);
  const [editExpenseId, setEditExpenseId] = useState<number | null>(null);
  const [showAdd, setShowAdd] = useState(false);

  const { data: expenses, isLoading } = trpc.expenses.list.useQuery({
    pageNo: filters.pageNo,
    pageSize: filters.pageSize,
    search: filters.search,
    dates: filters.dates,
    payees: filters.payees,
    categories: filters.categories,
  });

  const { data: expenseCategories } = trpc.expenses.categories.list.useQuery();
  const { data: payees } = trpc.users.list.useQuery();

  return (
    <Page title="Expenses" className="sm:p-0" fullWidth>
      {showReceipt && (
        <DocumentViewer
          open={true}
          onClose={() => setShowReceipt(null)}
          doc={showReceipt}
        />
      )}
      <AddExpenseDrawer open={showAdd} onClose={() => setShowAdd(false)} />
      {editExpenseId && (
        <EditExpenseDrawer
          open={!!editExpenseId}
          onClose={() => setEditExpenseId(null)}
          expenseId={editExpenseId}
        />
      )}
      <div className="flex flex-col gap-3 justify-between w-full border-gray-200 sm:border-b sm:p-3 sm:flex-row sm:gap-0">
        <SearchInput
          value={filters.search}
          onChange={(e: string) => setFilters({ search: e })}
        />

        <ResponsiveActionButton
          onClick={() => setShowAdd(true)}
          text="Expense"
        />
      </div>
      <div className="sm:px-3">
        <FilterBar
          onChange={() => {
            setFilters({
              pageNo: 1,
            });
          }}
          className="py-3"
          filters={[
            {
              key: "date",
              type: "date-range",
              label: "Date",
              value: filters.dates,
              onChange: (value) => setFilters({ dates: value }),
            },
            {
              key: "categories",
              type: "checkbox",
              label: "Categories",
              multiple: true,
              value: filters.categories,
              onChange: (value) =>
                setFilters({ categories: value as number[] }),
              options:
                expenseCategories?.map((category) => ({
                  label: category.name,
                  value: category.id,
                })) || [],
            },
            {
              key: "payees",
              type: "checkbox",
              label: "Payees",
              multiple: true,
              value: filters.payees,
              onChange: (value) => setFilters({ payees: value as number[] }),
              options:
                payees?.map((payee) => ({
                  label: payee.firstName,
                  value: payee.id,
                })) || [],
            },
          ]}
        />
      </div>
      <DataGrid
        rowKey="id"
        name="expensesListMain"
        className="h-[calc(100vh-117px-var(--header-height))]"
        label="Expenses"
        loading={isLoading}
        onCellClick={({ row }) => {
          setEditExpenseId(row.id);
        }}
        empty={{
          icon: DollarSign,
          title: "No expenses added",
          description: "Please add an expense",
          actionText: "Add expense",
          onAction: () => navigate("/purchases/expenses/add"),
          buttonSize: "sm",
        }}
        pagination={{
          pageNo: filters.pageNo,
          pageSize: filters.pageSize,
          count: expenses?.count,
          setPageNo: (pageNo) => setFilters({ pageNo }),
          setPageSize: (pageSize) => setFilters({ pageSize }),
        }}
        rows={expenses?.expenses}
        columns={[
          {
            key: "date",
            name: "Date",
            width: 140,
            renderCell: ({ row }) => dayjs(row.date).format("DD/MM/YYYY"),
          },
          {
            key: "payee",
            name: "Payee",
            width: 200,
            renderCell: ({ row }) => row.users.firstName,
          },
          {
            key: "category",
            name: "Category",
            width: 200,
            renderCell: ({ row }) => row.accountingCategories.name,
          },
          {
            key: "amount",
            name: "Amount",
            width: 200,
            renderCell: ({ row }) => (
              <div className="flex gap-2 items-center">
                <p className="w-24 font-medium text-right">
                  {formatCurrency(row.amount, "AED")}
                </p>
                {row.paidBy === "EMPLOYEE" && <Badge>To be Reimbursed</Badge>}
                {row.files?.fields?.expense_reciept && (
                  <Button
                    size="sm"
                    icon={Paperclip}
                    onClick={(e) => {
                      e.stopPropagation();
                      row.files?.fields?.expense_reciept &&
                        setShowReceipt({
                          name: row.files?.fields?.expense_reciept.name || "",
                          link: getS3URLLegacy(
                            "expenses/receipts",
                            row.files?.fields?.expense_reciept.link
                          ),
                        });
                    }}
                  >
                    Receipt
                  </Button>
                )}
              </div>
            ),
          },
          {
            key: "notes",
            name: "Notes",
            width: 200,
          },
        ]}
      />
    </Page>
  );
};

export default ExpensesList;
