import heffl from "@/helpers/heffl";
import { trpc } from "@/helpers/trpc";
import ActivityTimeline from "@/pages/crm/components/activity-timeline";
import { CommentInput } from "@/pages/crm/leads/details";
import { getJobId } from "@/pages/field-service/jobs/list";
import { QuotationStatusBadge } from "@/pages/sales/quotations/list";
import { THistory } from "@heffl/server/src/routes/crm/deals";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Button } from "@heffl/ui/components/primitives/button";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@heffl/ui/components/primitives/collapsible";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import StripeTabs, {
  TabsProps,
} from "@heffl/ui/components/primitives/stripe-tabs";
import { formatName } from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import {
  Activity,
  ChevronDown,
  ChevronLeft,
  ChevronUp,
  Eye,
  Folder,
  Hammer,
  Handshake,
  LucideIcon,
  Mail,
  MessageSquare,
  Pencil,
  Text,
} from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import InfoItemsHZ, { InfoItemHZProps } from "./InfoItemHZ";
import enums, { enumsToOptions } from "@heffl/server/src/schemas/enums";
import { AddActivityModal } from "./actionsModals/activities-modals";

type DetailsPageProps<T extends string> = {
  icon?: LucideIcon;
  title: string;
  description?: string;
  tabs: TabsProps<T>;
  details: InfoItemHZProps["items"];
  quotationId?: number;
  activities: THistory[];
};

type AcitivtyTabProps = {
  activities: THistory[];
  quotationId?: number;
};

const AcitivtyTab = (props: AcitivtyTabProps) => {
  const [focusOpen, setFocusOpen] = useState(true);
  const [addActivityOpen, setAddActivityOpen] = useState(false);

  const plannedActivities = props.activities
    .filter((activity) =>
      enumsToOptions(enums.activityTypes).find(
        // @ts-ignore
        (type) =>
          // @ts-ignore
          type.value === activity.type && activity.value.completed === false
      )
    )
    .sort((a, b) => b.value.date.getTime() - a.value.date.getTime());
  const entityHistory = props.activities.sort(
    (a, b) => b.value.date.getTime() - a.value.date.getTime()
  );

  return (
    <div className="">
      <AddActivityModal
        open={addActivityOpen}
        onClose={() => setAddActivityOpen(false)}
        quotationId={props.quotationId}
      />
      <div className="flex gap-2">
        <Button size="sm" variant="primary" icon={MessageSquare}>
          Message
        </Button>
        <Button size="sm">Add note</Button>
        <Button size="sm" onClick={() => setAddActivityOpen(true)}>
          Activity
        </Button>
      </div>
      <div className="mt-4">
        <CommentInput quotationId={props.quotationId} />
      </div>
      <Collapsible
        open={focusOpen}
        onOpenChange={setFocusOpen}
        className="mt-6"
      >
        <CollapsibleTrigger className="w-full">
          <div className="flex gap-1 items-center px-2">
            {focusOpen ? (
              <ChevronUp className="w-4 h-4" />
            ) : (
              <ChevronDown className="w-4 h-4" />
            )}
            <h1 className="text-sm font-semibold">Planned Activities</h1>
          </div>
        </CollapsibleTrigger>
        <CollapsibleContent>
          <ActivityTimeline activities={plannedActivities} />
        </CollapsibleContent>
      </Collapsible>
      <ActivityTimeline activities={entityHistory} />
    </div>
  );
};

const DetailsPage = <T extends string>(props: DetailsPageProps<T>) => {
  const navigate = useNavigate();

  const Icon = props.icon;

  return (
    <div className="flex overflow-hidden w-full h-screen bg-gray-50">
      <div className="w-64"></div>
      <div className="w-full bg-white border-l border-gray-200">
        <div className="flex gap-2 items-center p-2 w-full border-t border-b border-gray-200">
          <Button
            className="p-0 w-8 h-8 rounded-full"
            variant="outline"
            onClick={() => {
              navigate(-1);
            }}
          >
            <ChevronLeft className="h-5" />
          </Button>
          {Icon && (
            <div className="grid place-items-center p-2 bg-gray-100 rounded-full border border-gray-300 shadow-md">
              <Icon className="text-gray-500" size={18} />
            </div>
          )}
          <span className="flex justify-between items-center pr-4 w-full">
            <div>
              <div className="flex gap-1 items-center">
                <p className="text-sm font-semibold text-gray-800 line-clamp-1">
                  {props.title}
                </p>
              </div>
              {props.description?.length && (
                <p className="font-normal text-gray-500 text-[11px] -mt-1 line-clamp-1">
                  {props.description}
                </p>
              )}
            </div>
            <div>
              <Button icon={Pencil} size="sm">
                Edit
              </Button>
            </div>
          </span>
        </div>
        <div className="flex items-center w-full h-full">
          <div className="overflow-auto pt-2 w-2/3 h-full">
            <StripeTabs {...props.tabs} tabParentClassName="pl-4" />
          </div>
          <div className="overflow-hidden pt-2 w-1/3 h-full border-l border-gray-200">
            <StripeTabs
              tabParentClassName="pl-4 "
              contentClassName="p-3 !overflow-auto h-screen"
              items={[
                {
                  key: "details",
                  label: "Details",
                  icon: Text,
                  children: (
                    <InfoItemsHZ className="p-3" items={props.details} />
                  ),
                },
                {
                  key: "activity",
                  label: "Activity",
                  icon: Activity,
                  count: 2,
                  children: (
                    <AcitivtyTab
                      activities={props.activities}
                      quotationId={props.quotationId}
                    />
                  ),
                },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const DemoPage = () => {
  const navigate = useNavigate();

  const { data: quotation } = trpc.quotations.details.useQuery(334);

  if (!quotation) return <FullScreenSpinner />;

  return (
    <DetailsPage
      title="#QT-0034900"
      quotationId={quotation.id}
      description={`Created by ${heffl.format.name(
        quotation.createdBy
      )} on ${heffl.format.dateTime(quotation.date)}`}
      activities={quotation.activities}
      details={[
        {
          label: "Date",
          value: dayjs(quotation.date).format("DD/MM/YYYY"),
        },
        {
          label: "Status",
          value: <QuotationStatusBadge status={quotation.status} />,
        },
        {
          label: "Client",
          value: quotation.clients.name,
          onClick: () =>
            navigate(`/sales/clients/details/${quotation.clients.id}`),
        },

        {
          label: "Expiry",
          value: quotation.expiryDate
            ? dayjs(quotation.expiryDate).format("DD/MM/YYYY")
            : "No Expiry Date",
        },
        {
          hidden: quotation.fsJobQuotations.length === 0,
          label: "Jobs",
          value: (
            <div className="flex flex-wrap gap-2 items-center">
              {quotation.fsJobQuotations.map(({ fsJobs: job }) => (
                <Badge
                  onClick={() =>
                    navigate(`/field-service/jobs/details/${job.id}`)
                  }
                  key={job.id}
                  background="gray"
                  icon={Hammer}
                  variant="unique"
                  small
                  className="cursor-pointer"
                >
                  {getJobId(job)}
                </Badge>
              ))}
              {!quotation.fsJobQuotations.length && "No jobs linked"}
            </div>
          ),
        },
        {
          hidden: !quotation.deals,
          label: "Deal",
          value: quotation.deals ? (
            <Badge
              onClick={() =>
                navigate(`/crm/deals/details/${quotation.deals?.id}`)
              }
              background="blue"
              icon={Handshake}
              variant="unique"
              small
              className="cursor-pointer"
            >
              {quotation.deals.title}
            </Badge>
          ) : (
            "No deal linked"
          ),
        },
        {
          label: "Sales person",
          value: quotation.salesPerson
            ? quotation.salesPerson.firstName
            : "No Sales Person",
        },
        { label: "Created by", value: formatName(quotation.createdBy) },
      ]}
      tabs={{
        items: [
          {
            key: "preview",
            label: "Preview",
            icon: Eye,
          },
          {
            key: "files",
            label: "Files",
            icon: Folder,
          },
          {
            key: "emails",
            label: "Emails",
            icon: Mail,
          },
        ],
      }}
    />
  );
};

export default DemoPage;
