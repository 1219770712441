import { clsx, type ClassValue } from "clsx";
import dayjs from "dayjs";
import { twMerge } from "tailwind-merge";
import React from "react";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
export function cloneElement(element: React.ReactElement, classNames: string) {
  return React.cloneElement(element, {
    className: cn(element.props.className, classNames),
  });
}

export const getNextStatus = <T extends { label: string; value: string }>(
  currentStatus: string,
  statusArray: T[]
): T | undefined => {
  const currentIndex = statusArray.findIndex(
    (status) => status.value === currentStatus
  );
  if (currentIndex === -1 || currentIndex === statusArray.length - 1) {
    return undefined;
  }
  return statusArray[currentIndex + 1];
};

export const sleep = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLocaleLowerCase();
};

export function formatAmountToK(amount: number): string {
  if (amount >= 1_000_000_000) {
    return (amount / 1_000_000_000).toFixed(1).replace(/\.0$/, "") + "B";
  }
  if (amount >= 1_000_000) {
    return (amount / 1_000_000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (amount >= 1_000) {
    return (amount / 1_000).toFixed(1).replace(/\.0$/, "") + "k";
  }
  return amount.toString();
}

export const capitalize = (str: string) => {
  if (!str) return str;
  const text = str.toLowerCase();
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const capitalizeWords = (str: string) => {
  if (!str) return str;
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const formatAddress = ({
  address,
  city,
  countryStates,
  countries,
  exclude = {
    country: false,
  },
}: {
  address?: string | null;
  city?: string | null;
  countries?: {
    name: string;
  } | null;
  countryStates?: {
    name: string;
  } | null;
  exclude?: {
    country?: boolean;
  };
}) => {
  return [
    address,
    city,
    countryStates?.name,
    !exclude?.country && countries?.name,
  ]
    .filter(
      (item) =>
        item && item.trim() !== "" && item.toLowerCase() !== "not available"
    )
    .join(", ");
};

export function findPrimaryContact<T extends { isPrimary: boolean }>(
  data: T[]
) {
  const primary = data.find((item) => item.isPrimary);
  return primary || data[0] || undefined;
}

export function formatCurrency(
  amount: number,
  symbol: string,
  excludeSymbol?: boolean
) {
  if (excludeSymbol) {
    return new Intl.NumberFormat("en-US", {
      style: "decimal",
      minimumFractionDigits: 2,
    }).format(amount);
  }
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: symbol,
  }).format(amount);
}

export function formatName({
  firstName,
  lastName,
}: {
  firstName: string;
  lastName?: string | null;
}) {
  const formattedFirstName = firstName
    ? firstName.charAt(0)?.toUpperCase() + firstName.slice(1)
    : "";
  const formattedLastName = lastName
    ? ` ${lastName.charAt(0)?.toUpperCase() + lastName.slice(1)}`
    : "";

  if (!firstName && !lastName) return "No name";
  return `${formattedFirstName}${formattedLastName}`;
}

export const isMobileApp = () => {
  const userAgent = navigator.userAgent;
  const [appName, os] = userAgent.split("-");
  if (appName === "hefflApp" && os === "ios") {
    return "ios";
  }
  if (appName === "hefflApp" && os === "android") {
    return "android";
  }
  return undefined;
};

export const isMobile = () => {
  const mobileThreshold = 768; // Set the threshold for considering the screen as mobile
  return window.innerWidth < mobileThreshold;
};

export const objectToParams = (
  obj: Record<string, string | number | undefined>
) => {
  const filteredObj = Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(obj).filter(([_, value]) => value !== undefined)
  );
  // @ts-ignore
  return new URLSearchParams(filteredObj).toString();
};

export const convertFileSize = (sizeInBytes: number) => {
  const kilobyte = 1024;
  const megabyte = kilobyte * 1024;
  if (sizeInBytes < kilobyte) {
    return sizeInBytes + " B";
  } else if (sizeInBytes < megabyte) {
    return (sizeInBytes / kilobyte).toFixed(1) + " KB";
  } else {
    return (sizeInBytes / megabyte).toFixed(2) + " MB";
  }
};

export const dynamicDateFormatting = (
  rawDate: Date,
  hideTime: boolean = false,
  separator: string = " - "
) => {
  const date = dayjs(rawDate);
  const today = dayjs();
  const tomorrow = today.add(1, "day");
  const timeFormat = `${separator} hh:mm A`;
  const timeText = hideTime ? "" : date.format(timeFormat);

  if (date.isSame(today, "day")) return `Today${timeText}`;
  if (date.isSame(tomorrow, "day")) return `Tommorrow${timeText}`;
  if (date.isSame(today, "week"))
    return `${date.format("ddd, MMM DD")}${timeText}`;

  if (date.isSame(today, "year")) return `${date.format("MMMM DD")}${timeText}`;
  return `${date.format("MMM DD, YYYY")}${timeText}`;
};

export const dynamicTwoDateFormatting = ({
  startDate,
  endDate,
}: {
  startDate: Date;
  endDate: Date;
}) => {
  return ` ${dynamicDateFormatting(startDate, false, ", ")} 
                        - 
                        ${
                          !dayjs(startDate).isSame(endDate, "day")
                            ? dynamicDateFormatting(endDate, false, ", ")
                            : dayjs(endDate).format("hh:mm A")
                        }`;
};

export function calculateTimeDifference(
  startTime: string,
  endTime: string
): number {
  const start = dayjs(startTime);
  const end = dayjs(endTime);
  const duration = end.diff(start, "hour", true);

  return duration;
}

export const formatValue = {
  propertyName: ({ name, city }: { name?: string | null; city: string }) => {
    return name ? `${name}, ${city}` : city;
  },
  personName: ({
    firstName,
    lastName,
  }: {
    firstName: string;
    lastName: string | null;
  }) => {
    const formattedFirstName =
      firstName.charAt(0)?.toUpperCase() + firstName.slice(1);
    const formattedLastName = lastName
      ? lastName.charAt(0)?.toUpperCase() + lastName.slice(1)
      : "";
    return `${formattedFirstName} ${formattedLastName}`;
  },
  address: ({
    address,
    city,
    countryStates,
    countries,
  }: {
    address?: string | null;
    name?: string | null;
    city?: string | null;
    countries: {
      name: string;
    };
    countryStates: {
      name: string;
    };
  }) => {
    return `${address}, ${city}, ${countryStates.name}, ${countries.name}`;
  },
  fsClientName: ({
    companies,
    contacts,
  }: {
    companies: {
      name: string;
    } | null;
    contacts: {
      firstName: string;
      lastName: string | null;
    } | null;
  }) => {
    if (contacts) return formatValue.personName(contacts);
    return companies?.name || "";
  },
};
export const websiteFavicon = (website: string, size = 16) => {
  return `https://www.google.com/s2/favicons?domain=${website}&sz=${size}`;
};

export const formatString = (str: string) => {
  return str.replace(/(\r\n|\n|\r)/gm, "");
};

export const avatarName = ({
  firstName,
  lastName,
}: {
  firstName: string;
  lastName?: string | null;
}) => {
  return lastName
    ? `${firstName[0]?.toUpperCase()}${lastName[0]?.toUpperCase()}`
    : `${firstName[0]?.toUpperCase()}${
        firstName[1] ? firstName[1]?.toUpperCase() : ""
      }`;
};

export const downloadFile = (fileName: string, url: string) => {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const fileURL = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(fileURL);
    })
    .catch((error) => {
      console.error("Error fetching the file:", error);
    });
};

export const addNumberSuffix = (num: number) => {
  const suffixes = ["th", "st", "nd", "rd"];
  const suffixIndex =
    num % 100 > 10 && num % 100 < 20 ? 0 : num % 10 < 4 ? num % 10 : 0;

  return num + suffixes[suffixIndex];
};

export const calculateCompleted = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tasks: any[],
  key: string,
  conditon: string | boolean
) => {
  return tasks.reduce((acc, task) => {
    if (task[key] === conditon) acc++;
    return acc;
  }, 0);
};

export const generateUniqueColor = (
  str: string = "",
  shade: 300 | 400 | 600 | 200 | 100 | 500 | 700 = 400
) => {
  let hash = 0;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < str.length; i++) {
    // eslint-disable-next-line no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const index = Math.abs(hash % tailwindColors[shade].length);
  return tailwindColors[shade][index];
};

export const makeEllipsis = (text: string, charCount: number): string => {
  if (text.length <= charCount) return text;
  return text.slice(0, charCount) + "...";
};

export const tailwindColors = {
  100: [
    "#ebf8ff",
    "#fff5f5",
    "#f0fff4",
    "#fffff0",
    "#faf5ff",
    "#fff5f7",
    "#ebf4ff",
    "#ffe4e6",
    "#f3f4f6",
    "#e6fffa",
  ],
  200: [
    "#bee3f8",
    "#fed7d7",
    "#c6f6d5",
    "#fefcbf",
    "#e9d8fd",
    "#fed7e2",
    "#c3dafe",
    "#fed4e6",
    "#e2e8f0",
    "#b2f5ea",
  ],
  300: [
    "#90cdf4",
    "#feb2b2",
    "#9ae6b4",
    "#faf089",
    "#d6bcfa",
    "#fbb6ce",
    "#a3bffa",
    "#fda4af",
    "#cbd5e0",
    "#81e6d9",
  ],
  400: [
    "#63b3ed",
    "#fc8181",
    "#68d391",
    "#f6e05e",
    "#b794f4",
    "#f687b3",
    "#7f9cf5",
    "#fb7185",
    "#a0aec0",
    "#4fd1c5",
  ],
  500: [
    "#4299e1",
    "#f56565",
    "#48bb78",
    "#ecc94b",
    "#9f7aea",
    "#ed64a6",
    "#667eea",
    "#f687b3",
    "#718096",
    "#38b2ac",
  ],
  600: [
    "#3182ce",
    "#e53e3e",
    "#38a169",
    "#d69e2e",
    "#805ad5",
    "#d53f8c",
    "#5a67d8",
    "#e53e3e",
    "#4a5568",
    "#319795",
  ],
  700: [
    "#2b6cb0",
    "#c53030",
    "#2f855a",
    "#b7791f",
    "#6b46c1",
    "#b83280",
    "#4c51bf",
    "#c53030",
    "#2d3748",
    "#2c7a7b",
  ],
};
