import DataTable from "@/components/DataTable";
import Page from "@/components/page";
// import { trpc } from "@/helpers/trpc";
import { Card } from "@heffl/ui/components/primitives/card";
import React from "react";
import { useNavigate } from "react-router-dom";

interface Report {
  title: string;
  description: string;
  slug: string;
}

const reports: Report[] = [
  {
    title: "Invoice Summary Report",
    description: "Overview of invoice status and key financial metrics.",
    slug: "invoice-summary-report",
  },
  {
    title: "Payments Received Report",
    description: "Detailed report on payments received.",
    slug: "payments-received-report",
  },
  {
    title: "Quotes Summary Report",
    description: "Overview of quote status and key financial metrics.",
    slug: "quotes-summary-report",
  },
  {
    title: "Vendor Summary Report",
    description: "Overview of vendor status and key financial metrics.",
    slug: "vendor-summary-report",
  },
  {
    title: "Customer Balances Report",
    description: "Overview of customer balances.",
    slug: "customer-balances-report",
  },
];

const InvoiceReports: React.FC = () => {
  const navigate = useNavigate();
  //   const { data: currentUser } = trpc.users.currentUser.useQuery();

  //   if (currentUser?.type !== "ADMIN" && currentUser?.type !== "SUPER_ADMIN")
  //     return <div>You are not authorized to view this page.</div>;

  return (
    <Page title="Invoice Reports">
      <div className="container py-8 mx-auto">
        <h1 className="mb-6 text-2xl font-bold">All Reports</h1>
        <Card>
          <DataTable
            data={reports}
            columns={[
              {
                title: "Report Title",
                render: (report: Report) => (
                  <span
                    className="font-medium text-blue-400 cursor-pointer"
                    onClick={() => {
                      navigate(`/sales/reports/${report.slug}`);
                    }}
                  >
                    {report.title}
                  </span>
                ),
              },
              {
                title: "Description",
                render: (report: Report) => (
                  <span className="text-sm text-gray-500">
                    {report.description}
                  </span>
                ),
              },
              {
                title: "Created By",
                render: () => (
                  <span className="text-sm text-gray-500">
                    System generated
                  </span>
                ),
              },
            ]}
            rowKey="slug"
          />
        </Card>
      </div>
    </Page>
  );
};

export default InvoiceReports;
