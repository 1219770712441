import { trpc } from "@/helpers/trpc";
import { CSS } from "@dnd-kit/utilities";
import { Button } from "@heffl/ui/components/primitives/button";
import FormList from "@heffl/ui/components/primitives/form-list";
import { Input } from "@heffl/ui/components/primitives/input";
import { useDebounce } from "use-debounce";

import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { formatCurrency, formatString } from "@heffl/ui/lib/utils";

import { AddProductDrawer } from "@/pages/sales/products/components/addProductDrawer";
import { EditProductDrawer } from "@/pages/sales/products/components/editProductDrawer";
import enums from "@heffl/server/src/schemas/enums";
import InputWithDropDown from "@heffl/ui/components/InputWithDropDown";
import { Label } from "@heffl/ui/components/primitives/label";
import MiniRichTextEditor from "@heffl/ui/components/primitives/mini-rich-text-editor";
import { AlignLeft, GripVertical, Plus, Trash2, X } from "lucide-react";
import { useState } from "react";
import { UseFieldArrayRemove, useFormContext } from "react-hook-form";
import { z } from "zod";

export type LineItem = {
  id?: number;
  name?: string;
  productId?: number;
  quantity: number;
  price: number;
  description?: string | null;
  tax?: number;
  viewType: z.infer<typeof enums.lineItemViewType>;
};

export const defaultProduct: LineItem = {
  productId: undefined,
  quantity: 1,
  price: 0,
  tax: 5,
  description: "",
  viewType: "LINE_ITEM",
};

export const defaultHeading: LineItem = {
  productId: undefined,
  quantity: 0,
  price: 0,
  tax: 0,
  description: "",
  viewType: "HEADING",
};

type HeadingLineItemProps = {
  id: string;
  remove: UseFieldArrayRemove;
  index: number;
  name: string;
};

const HeadingLineItem = ({ id, remove, index, name }: HeadingLineItemProps) => {
  const form = useFormContext();

  const setValue = (value: unknown) => {
    form.setValue(`${name}.${index}.name`, value, {
      shouldDirty: true,
    });
  };
  const value = form.watch(`${name}.${index}.name`);

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      className="flex flex-col gap-3 items-center p-3 w-full bg-gray-50 rounded-lg border border-gray-200 sm:p-4 sm:flex-row"
      style={style}
    >
      <Button
        variant="ghost"
        size="icon"
        className="self-start touch-none"
        ref={setNodeRef}
        {...attributes}
        {...listeners}
      >
        <GripVertical className="w-6 h-6" />
      </Button>
      <div className="w-4/5">
        <Label>Heading</Label>
        <Input
          className="mt-1 w-full"
          placeholder="Heading"
          value={value}
          onChange={(v) => setValue(v.target.value)}
        />
      </div>
      <Button variant="ghost" onClick={() => remove(index)} size="icon">
        <Trash2 className="!w-5 !h-5 text-red-400" />
      </Button>
    </div>
  );
};

type SingleLineItemProps = {
  index: number;
  remove: UseFieldArrayRemove;
  fieldsLength: number;
  required: boolean;
  name: string;
  id: string;
  isEdit?: boolean;
  disabled?: boolean;
  isInvoice: boolean;
};

const SingleLineItem = ({
  index,
  remove,
  fieldsLength,
  required,
  id,
  name,
  disabled = false,
  isInvoice,
}: SingleLineItemProps) => {
  const [editProduct, setEditProduct] = useState(false);
  const [productSearch, setProductSearch] = useState("");
  const [debouncedSearch] = useDebounce(productSearch, 500);
  const [showAddProductIndex, setShowAddProductIndex] = useState<
    number | undefined
  >(undefined);

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const form = useFormContext();

  const value = form.watch(`${name}.${index}`);

  const { data, isLoading } = trpc.products.list.useQuery({
    pageNo: 1,
    pageSize: 20,
    search: debouncedSearch,
    include: value.productId ? [value.productId] : [],
  });

  const getTotal = () => {
    const quantity = value.quantity;
    const price = value.price;
    const tax = value.tax;

    if (quantity && price && tax) {
      return Number(
        (quantity * price + (quantity * price * tax) / 100).toFixed(2)
      );
    }
    if (quantity && price) {
      return Number((quantity * price).toFixed(2));
    }
    return 0;
  };

  const getSubtotal = () => {
    const quantity = value.quantity;
    const price = value.price;

    if (quantity && price) {
      return Number((quantity * price).toFixed(2));
    }
    return 0;
  };

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const setValue = (fieldName: string, value: unknown) => {
    form.setValue(`${name}.${index}.${fieldName}`, value, {
      shouldDirty: true,
    });
  };

  return (
    <div
      className="flex flex-col items-center p-3 w-full bg-gray-50 rounded-lg border border-gray-200 sm:p-3 sm:flex-row"
      style={style}
    >
      {showAddProductIndex !== undefined && (
        <AddProductDrawer
          open={true}
          onClose={(newProduct) => {
            console.log("gheereer");

            if (showAddProductIndex !== undefined && newProduct && name) {
              setValue("name", newProduct?.name || "");
              setValue("productId", Number(newProduct?.id));
              setValue("description", newProduct?.description || "");
              setValue("price", newProduct?.price || 0);
              setValue("quantity", 1);
              setValue("tax", 5);
              if (isInvoice) {
                setValue("buyPrice", newProduct?.buyPrice || 0);
              }
            }
            setShowAddProductIndex(undefined);
          }}
        />
      )}
      <div
        className="self-start touch-none"
        ref={setNodeRef}
        {...attributes}
        {...listeners}
      >
        <GripVertical className="w-5 h-5" />
      </div>
      <div className="flex flex-col w-full">
        <div className="sm:flex grid gap-2 !w-full">
          <div className="col-span-2 sm:w-6/12">
            <div className="flex relative justify-between mt-0.5 mb-1">
              {editProduct && value.productId && (
                <EditProductDrawer
                  productId={value.productId}
                  onClose={() => setEditProduct(false)}
                />
              )}
              <Label>Product</Label>
              <div className="flex absolute right-0 bottom-0 gap-1">
                {value.productId && (
                  <Button
                    disabled={disabled}
                    onClick={() => setEditProduct(true)}
                    size="xs"
                    variant="primaryOutline"
                  >
                    Edit
                  </Button>
                )}
                {!value.productId && (
                  <Button
                    disabled={disabled}
                    onClick={() => setShowAddProductIndex(index)}
                    size="xs"
                    variant="primaryOutline"
                  >
                    Add
                  </Button>
                )}
              </div>
            </div>
            {value.productId ? (
              <div className="flex gap-2 justify-between items-center p-2 bg-white rounded-lg border">
                {
                  data?.products?.find(
                    (product) => product.id === value.productId
                  )?.name
                }
                <Button
                  onClick={() => {
                    setValue("productId", undefined);
                    setValue("name", "");
                  }}
                  size="icon"
                  disabled={disabled}
                  className="w-6 h-6"
                >
                  <X className="w-4 h-4" />
                </Button>
              </div>
            ) : (
              <InputWithDropDown
                disabled={disabled}
                onSearch={setProductSearch}
                isLoading={isLoading}
                placeholder="Type or select product/service"
                className="w-full"
                allowClear={false}
                value={value?.name || ""}
                onChange={(v) => setValue("name", v)}
                onOptionSelected={(v) => {
                  if (v) {
                    const selectedProduct = data?.products?.find(
                      (product) => product.id === Number(v)
                    );
                    setValue("name", selectedProduct?.name || "");
                    setValue("productId", Number(v));
                    setValue("description", selectedProduct?.description || "");
                    setValue("price", selectedProduct?.price || 0);
                    if (isInvoice) {
                      setValue("buyPrice", selectedProduct?.buyPrice || 0);
                    }
                  }
                }}
                options={
                  data?.products?.map((product) => ({
                    ...product,
                    label: formatString(product.name),
                    value: product.id,
                  })) || []
                }
                render={(product) => (
                  <div className="flex flex-col gap-1">
                    <p className="text-sm font-semibold">{product.name}</p>
                    <div className="flex gap-2">
                      <p className="text-xs">
                        Buy: {formatCurrency(product.buyPrice || 0, "AED")}
                      </p>
                      <p className="text-xs font-medium">
                        Price: {formatCurrency(product.price, "AED")}
                      </p>
                    </div>
                  </div>
                )}
              />
            )}
          </div>
          <div className="w-full sm:w-2/12">
            <Label>Qty</Label>
            <Input
              placeholder=""
              value={value.quantity}
              onChange={(v) => setValue("quantity", v.target.value)}
              disabled={disabled}
            />
          </div>
          <div className="w-full sm:w-2/12">
            <Label>Price</Label>
            <Input
              placeholder=""
              value={value.price}
              onChange={(v) => setValue("price", v.target.value)}
              disabled={disabled}
            />
          </div>
          <div className="w-full sm:w-2/12">
            <Label>Tax</Label>
            <Input
              placeholder=""
              suffix="%"
              value={value.tax}
              onChange={(v) => setValue("tax", v.target.value)}
              disabled={disabled}
            />
          </div>
          <div className="w-full sm:w-2/12">
            <Label>Subtotal</Label>
            <p className="mt-2 font-medium">{getSubtotal().toFixed(2)}</p>
          </div>
          <div className="w-full sm:w-2/12">
            <Label>Total</Label>
            <p className="mt-2 font-medium">{getTotal().toFixed(2)}</p>
          </div>
          <Button
            disabled={(required && fieldsLength === 1) || disabled}
            variant="ghost"
            onClick={() => remove(index)}
            size="icon"
          >
            <Trash2 className="!w-5 !h-5 text-red-400" />
          </Button>
        </div>
        <div className="mt-2 w-full sm:w-7/12">
          <MiniRichTextEditor
            key={`${id}-${index}`}
            maxHeight={130}
            value={value.description || ""}
            onChange={(v) => setValue("description", v)}
            placeholder="Add description"
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};

type LineItemProps = {
  isInvoice?: boolean;
  name?: string;
  required?: boolean;
  isEdit?: boolean;
  disabled?: boolean;
};

const LineItemSelector = (props: LineItemProps) => {
  const { getValues } = useFormContext();
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(TouchSensor, {
      // Press delay of 250ms, with tolerance of 5px of movement
      activationConstraint: {
        delay: 600,
        tolerance: 5,
      },
    })
  );

  const currentLineItems = getValues(props.name || "") || [];

  return (
    <FormList name={props.name || ""}>
      {({ append, fields, remove, move }) => {
        return (
          <div className="flex-wrap space-y-2">
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={(event) => {
                const { active, over } = event;
                if (over && active.id !== over?.id) {
                  const activeIndex = active.data.current?.sortable?.index;
                  const overIndex = over.data.current?.sortable?.index;
                  if (activeIndex !== undefined && overIndex !== undefined) {
                    move(activeIndex, overIndex);
                  }
                }
              }}
            >
              <SortableContext
                items={fields}
                strategy={verticalListSortingStrategy}
              >
                {fields.map((field, index) => {
                  const lineItemValue = currentLineItems[index];

                  return lineItemValue.viewType === "LINE_ITEM" ? (
                    <SingleLineItem
                      key={field.id}
                      id={field.id}
                      index={index}
                      remove={remove}
                      fieldsLength={currentLineItems.length}
                      required={props.required || false}
                      isEdit={props.isEdit}
                      name={props.name || ""}
                      disabled={props.disabled}
                      isInvoice={props.isInvoice || false}
                    />
                  ) : (
                    <HeadingLineItem
                      key={field.id}
                      name={props.name || ""}
                      id={field.id}
                      index={index}
                      remove={remove}
                    />
                  );
                })}
              </SortableContext>
            </DndContext>
            <div className="flex gap-2 pt-2">
              <Button
                onClick={() => append(defaultProduct)}
                variant="primary"
                icon={Plus}
                size="md"
                disabled={props.disabled}
              >
                Add line item
              </Button>
              <Button
                onClick={() => append(defaultHeading)}
                size="md"
                icon={AlignLeft}
                disabled={props.disabled}
              >
                Add heading
              </Button>
            </div>
          </div>
        );
      }}
    </FormList>
  );
};

export default LineItemSelector;
