import DataGrid from "@/components/dataGrid/DataGrid";
import Empty from "@/components/Empty";
import FilterBar from "@/components/filters";
import { SearchInput } from "@/components/FormComponents";
import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import usePermissions from "@/lib/hooks/usePermissions";
import { calculateLineItems } from "@heffl/server/src/helpers/lineItems/calculateLineItems";
import enums from "@heffl/server/src/schemas/enums";
import DropMenu from "@heffl/ui/components/DropMenu";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Button } from "@heffl/ui/components/primitives/button";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import ResponsiveActionButton from "@heffl/ui/components/primitives/responsive-action-button";
import TagsInput from "@heffl/ui/components/TagInput";
import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";
import { formatCurrency, isMobile } from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import {
  ArrowDownToLine,
  CircleDollarSignIcon,
  Copy,
  MoreHorizontal,
  ScrollText,
  Tag,
  Trash,
} from "lucide-react";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

type PageFilters = {
  search: string;
  pageNo: number;
  statuses: z.infer<typeof enums.purchaseOrderStatus>[];
  dates?: [Date, Date];
  vendors: number[];
  tags: number[];
};

export const PurchaseOrderStatusBadge: React.FC<{
  status: z.infer<typeof enums.purchaseOrderStatus>;
  small?: boolean;
}> = ({ status, small }) => {
  const badgeProps = {
    small,
    variant: {
      DRAFT: "neutral",
      SENT: "default",
      CLOSED: "success",
      CANCELLED: "error",
    }[status] as "neutral" | "default" | "success" | "error",
  };

  return (
    <Badge {...badgeProps}>
      {status.charAt(0) + status.slice(1).toLowerCase()}
    </Badge>
  );
};

type PurchaseOrderListProps = {
  filters: Partial<PageFilters>;
  pagination?: {
    setPageNo: (pageNo: number) => void;
  };
  classes?: {
    dataGrid?: string;
  };
};

export const PurchaseOrderList: React.FC<PurchaseOrderListProps> = ({
  filters,
  classes,
}: PurchaseOrderListProps) => {
  const navigate = useNavigate();
  const confirm = useConfirm();

  const { data: tags } = trpc.tags.list.useQuery({
    type: "PURCHASE_ORDER",
  });
  const { data: purchaseOrders, isLoading } =
    trpc.purchases.purchaseOrders.list.useQuery({
      statuses: filters.statuses,
      dates: filters.dates,
      pageNo: filters.pageNo,
      pageSize: 30,
      vendors: filters.vendors,
      tags: filters.tags,
      search: filters.search,
    });

  const updatePurchaseOrderMutation =
    trpc.purchases.purchaseOrders.update.useMutation({
      onError(error) {
        toast.error(error.message);
      },
    });

  const deletePurchaseOrderMutation =
    trpc.purchases.purchaseOrders.delete.useMutation({
      onSuccess() {
        toast.success("Purchase Order deleted successfully");
      },
      onError(error) {
        toast.error(error.message);
      },
    });

  return (
    <>
      {isMobile() ? (
        <div className="flex flex-col gap-2 mt-4 mb-[100px]">
          {/* {purchaseOrders?.purchaseOrders.map((purchaseOrder) => (
            <PurchaseOrderCard key={purchaseOrder.id} data={purchaseOrder} />
          ))}
          <Pagination
            pageNo={filters.pageNo}
            totalPages={purchaseOrders?.totalPages}
            setPageNo={(pageNo: number) => setFilters({ pageNo })}
            className="justify-start"
          /> */}
        </div>
      ) : (
        <DataGrid
          rowKey="id"
          name="purchaseOrdersListMain"
          className={classes?.dataGrid}
          label="Purchase Orders"
          loading={isLoading}
          onCellClick={({ row }) => {
            navigate(`/purchases/purchase-orders/details/${row.id}`);
          }}
          empty={{
            icon: ScrollText,
            title: "No purchase orders added",
            description: "Please add a purchase order",
            buttonSize: "sm",
          }}
          rows={purchaseOrders?.purchaseOrders || []}
          columns={[
            {
              key: "date",
              name: "Date",
              width: 140,
              renderCell: ({ row }) => dayjs(row.date).format("DD/MM/YYYY"),
            },
            {
              key: "number",
              name: "Number",
              width: 120,
              renderCell: ({ row }) => (
                <p
                  className="cursor-pointer hover:underline hover:text-primary-600"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/purchases/purchase-orders/details/${row.id}`);
                  }}
                >
                  {row.number}
                </p>
              ),
            },

            {
              key: "vendor",
              name: "Vendor",
              width: 300,
              renderCell: ({ row }) => (
                <div
                  className="cursor-pointer hover:text-primary-600 hover:underline"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/purchases/vendors/details/${row.vendors?.id}`);
                  }}
                >
                  {row.vendors?.name || "-"}
                </div>
              ),
            },
            {
              key: "status",
              name: "Status",
              width: 100,
              renderCell: ({ row }) => (
                <PurchaseOrderStatusBadge status={row.status} />
              ),
            },
            {
              key: "tags",
              name: "Tags",
              width: 150,
              renderCell: ({ row }) => (
                <TagsInput
                  empty={
                    <Empty
                      className="w-44"
                      iconSize={24}
                      actionText="Add tag"
                      title=""
                      description="Add tags to categorize this item"
                      icon={Tag}
                      onAction={() => navigate("/settings/tags")}
                    />
                  }
                  value={row.purchaseOrderTags.map((tag) => tag.tagId)}
                  onChange={(tags: number[]) => {
                    updatePurchaseOrderMutation.mutate({
                      id: row.id,
                      purchaseOrder: { purchaseOrderTags: tags },
                    });
                  }}
                  tags={tags}
                />
              ),
            },

            {
              key: "amount",
              name: "Amount",
              width: 130,
              renderCell: ({ row }) =>
                formatCurrency(
                  calculateLineItems({
                    lineItems: row.purchaseOrderItems,
                    discount: row.discount,
                  }).total,
                  row.currencies.symbol,
                  true
                ),
            },
            {
              key: "actions",
              name: "Actions",
              width: 100,
              renderCell: ({ row }) => (
                <DropMenu
                  items={[
                    {
                      icon: Trash,
                      className: "text-red-500",
                      label: "Delete",
                      onClick: async () => {
                        const confirmed = await confirm(
                          "Are you sure to delete this purchase order?"
                        );
                        if (confirmed) {
                          deletePurchaseOrderMutation.mutate(row.id);
                        }
                      },
                    },
                    {
                      label: "Duplicate",
                      icon: Copy,
                      onClick: () =>
                        navigate(
                          `/purchases/purchase-orders/add?purchaseOrderId=${row.id}`
                        ),
                    },
                    {
                      label: "Download",
                      icon: ArrowDownToLine,
                      onClick: async () => {},
                    },
                  ]}
                >
                  <Button variant="ghost">
                    <MoreHorizontal className="h-4 text-gray-700" />
                  </Button>
                </DropMenu>
              ),
            },
          ]}
        />
      )}
    </>
  );
};

const PurchaseOrderListPage: React.FC = () => {
  const navigate = useNavigate();

  const permissions = usePermissions();

  const [filters, setFilters] = useParamsState<PageFilters>({
    search: "",
    pageNo: 1,
    statuses: [],
    dates: undefined,
    vendors: [],
    tags: [],
  });

  const [vendorSearch, setVendorSearch] = useState("");

  const { data: purchaseOrders } = trpc.purchases.purchaseOrders.list.useQuery({
    statuses: filters.statuses,
    dates: filters.dates,
    pageNo: filters.pageNo,
    pageSize: 30,
    vendors: filters.vendors,
    tags: filters.tags,
    search: filters.search,
  });

  const { data: tags } = trpc.tags.list.useQuery({
    type: "PURCHASE_ORDER",
  });
  const { data: vendors } = trpc.purchases.vendors.list.useQuery({
    search: vendorSearch,
  });

  if (!permissions) return <FullScreenSpinner />;
  if (!permissions.VIEW_PURCHASE_ORDERS.allowed) {
    return (
      <Page fullWidth title="Purchase Orders">
        <div className="flex flex-col justify-center items-center h-screen">
          <div className="flex flex-col gap-2 justify-center items-center p-3">
            <h1 className="text-3xl font-bold">
              You don&apos;t have permission to view purchase orders
            </h1>
            <p className="text-base font-medium">
              Please contact the admin to request access.
            </p>
          </div>
        </div>
      </Page>
    );
  }

  return (
    <Page fullWidth title="Purchase Orders" className="sm:p-0">
      <div className="flex flex-col gap-3 justify-between w-full border-gray-200 sm:border-b sm:p-3 sm:flex-row sm:gap-0">
        <SearchInput
          value={filters.search}
          onChange={(e: string) => setFilters({ search: e })}
        />

        {permissions.CREATE_PURCHASE_ORDERS.allowed && (
          <ResponsiveActionButton
            onClick={() => navigate("/purchases/purchase-orders/add")}
            text="Purchase Order"
          />
        )}
      </div>

      <div className="sm:px-3">
        <FilterBar
          suffix={
            <Button size="sm" icon={CircleDollarSignIcon}>
              Total:{" "}
              {formatCurrency(purchaseOrders?.meta?.aggregate.sum || 0, "AED")}
            </Button>
          }
          onChange={() => {
            setFilters({
              pageNo: 1,
            });
          }}
          className="py-3"
          filters={[
            {
              key: "date",
              type: "date-range",
              label: "Date",
              value: filters.dates,
              onChange: (value) => setFilters({ dates: value }),
            },
            {
              key: "status",
              type: "checkbox",
              label: "Status",
              value: filters.statuses,
              onChange: (value) =>
                setFilters({
                  statuses: value as z.infer<
                    typeof enums.purchaseOrderStatus
                  >[],
                }),
              options: [
                { label: "Draft", value: "DRAFT" },
                { label: "Sent", value: "SENT" },
                { label: "Closed", value: "CLOSED" },
                { label: "Cancelled", value: "CANCELLED" },
              ],
            },
            {
              key: "vendors",
              type: "checkbox",
              label: "Vendors",
              showSearch: true,
              value: filters.vendors,
              onChange: (value) => {
                setFilters({ vendors: value as number[] });
              },
              options: vendors?.vendors.map((vendor) => ({
                label: vendor.name,
                value: vendor.id,
              })),
              onSearch: (e: string) => setVendorSearch(e),
            },
            {
              key: "tags",
              label: "Tags",
              type: "checkbox",
              value: filters.tags,
              options:
                tags?.map((tag) => ({
                  label: tag.name,
                  value: tag.id,
                })) ?? [],
              onChange: (updatedTags) => {
                setFilters({ tags: updatedTags as number[] });
              },
            },
          ]}
        />
      </div>
      <PurchaseOrderList
        filters={filters}
        classes={{
          dataGrid: "h-[calc(100vh-117px-var(--header-height))]",
        }}
      />
    </Page>
  );
};

export default PurchaseOrderListPage;
