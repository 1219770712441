import { trpc } from "@/helpers/trpc";
import { UniqueIdentifier } from "@dnd-kit/core";
import { Button, buttonVariants } from "@heffl/ui/components/primitives/button";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@heffl/ui/components/primitives/popover";
import RightClickMenu from "@heffl/ui/components/right-click-menu";
import {
  Sortable,
  SortableDragHandle,
  SortableItem,
} from "@heffl/ui/components/sortable";
import { cn } from "@heffl/ui/lib/utils";
import { VariantProps } from "class-variance-authority";
import { produce } from "immer";
import { debounce } from "lodash";
import {
  GripVertical,
  LucideIcon,
  Plus,
  Settings,
  Sheet,
  TextCursorInput,
  Trash2,
  X,
} from "lucide-react";
import { useEffect, useMemo, useRef, useState } from "react";
import ReactDataGrid, {
  Column,
  DataGridHandle,
  SelectColumn,
  textEditor,
} from "react-data-grid";
import "react-data-grid/lib/styles.css";
import { create } from "zustand";
import Empty, { EmptyProps } from "../Empty";
import Pagination from "../Pagination";
import "./dataGridStyles.css";
import { exportToExcel } from "./exportUtils";

// dont change this, stored in db
type DataGridNames =
  | "tasksListMain"
  | "leadsListMain"
  | "jobsListMain"
  | "schedulesListMain"
  | "quotationsListMain"
  | "projectsListMain"
  | "vendorsListMain"
  | "clientsListMain"
  | "crmTasksListMain"
  | "dealsListMain"
  | "timesheetsListMain"
  | "purchaseOrdersListMain"
  | "billsListMain"
  | "expensesListMain"
  | "projectSummaryReport"
  | "invoicesPaymentsListMain"
  | "invoiceSummaryReport"
  | "invoicesListMain"
  | "paymentsReceivedReport"
  | "quoteSummaryReport"
  | "vendorSummaryReport"
  | "serviceProductUtilization"
  | "customerBalancesReport";

type ViewSettingsProps = {
  showingColumns: Column<unknown, unknown>[];
  restColumns: Column<unknown, unknown>[];
  onChange: (columns: Column<unknown, unknown>[]) => void;
  defaultColumns?: Column<unknown, unknown>["key"][];
  open: boolean;
  onOpenChange: () => void;
};

const ViewSettingsPopover = ({
  restColumns,
  showingColumns,
  onChange,
  open,
  onOpenChange,
}: ViewSettingsProps) => {
  const [showAddColumns, setShowAddColumns] = useState(false);

  return (
    <Popover
      open={open}
      onOpenChange={() => {
        onOpenChange();
        setShowAddColumns(false);
      }}
    >
      <PopoverTrigger>
        <Button variant="outline" size="sm" icon={Settings}>
          View settings
        </Button>
      </PopoverTrigger>
      <PopoverContent
        align="end"
        className="overflow-auto relative !p-0 max-h-96"
      >
        <p className="sticky top-0 left-0 p-2 w-full text-sm text-gray-600 bg-white">
          {showAddColumns ? "Add columns" : "View settings"}
        </p>
        <div className="flex flex-col gap-1">
          {showAddColumns && !restColumns.length && (
            <Empty
              icon={TextCursorInput}
              iconSize={20}
              description="No more columns"
              title=""
            />
          )}
          {showAddColumns &&
            restColumns.map((column) => (
              <div
                key={column.key}
                className="flex gap-3 items-center p-1 rounded-sm cursor-pointer hover:bg-gray-100"
                onClick={() => {
                  onChange([...showingColumns, column]);
                  setShowAddColumns(false);
                }}
              >
                <TextCursorInput className="w-4 h-4 text-gray-400" />
                {column.name}
              </div>
            ))}
          {!showAddColumns && (
            <Sortable
              value={showingColumns.map((c) => ({
                id: c.key as UniqueIdentifier,
              }))}
              onValueChange={(order) => {
                onChange(
                  // @ts-ignore
                  order.map((o) => showingColumns.find((c) => c.key === o.id))
                );
              }}
            >
              {showingColumns.map((column) => (
                <SortableItem
                  key={column.key}
                  value={column.key}
                  className="flex gap-2 justify-between items-center px-1 rounded-sm cursor-pointer hover:bg-gray-100"
                >
                  <div className="flex gap-2 items-center">
                    <SortableDragHandle
                      variant="ghost"
                      size="sm"
                      iconClassName="text-gray-400"
                      className="px-0"
                    >
                      <GripVertical className="w-4 h-4 text-gray-400" />
                    </SortableDragHandle>
                    <p>{column.name}</p>
                  </div>
                  <Button
                    onClick={() => {
                      onChange(
                        showingColumns.filter((c) => c.key !== column.key)
                      );
                      setShowAddColumns(false);
                    }}
                    variant="ghost"
                    size="sm"
                    icon={Trash2}
                    className="hover:text-red-500"
                  />
                </SortableItem>
              ))}
            </Sortable>
          )}
          {!showAddColumns && (
            <div className="sticky bottom-0 left-0 p-1 w-full bg-white">
              <div className="mt-1 h-px bg-gray-200" />
              <Button
                onClick={() => setShowAddColumns(true)}
                variant="ghost"
                size="sm"
                icon={Plus}
                className="w-full"
              >
                Add column
              </Button>
            </div>
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
};

export type PaginationProps = {
  pageNo: number;
  count?: number | null;
  pageSize: number;
  setPageNo: (pageNo: number) => void;
  setPageSize?: (pageSize: number) => void;
  className?: string;
};

type BulkActions = {
  icon?: LucideIcon;
  label: string;
  onClick: (selectedRows: number[]) => void;
  variant?: VariantProps<typeof buttonVariants>["variant"];
  loading?: boolean;
  disabled?: boolean;
};

type DataGridProps<T extends object> = {
  bulkActions?: BulkActions[];
  name: DataGridNames;
  rows?: T[];
  columns: (Omit<Column<T>, "frozen"> & { icon?: LucideIcon })[];
  label: string;
  defaultColumns?: Column<T>["key"][];
  rowKey: Column<T>["key"];
  className?: string;
  onCellClick?: ({ row }: { row: T }) => void;
  loading?: boolean;
  rowHeight?: number;
  pagination?: PaginationProps;
  empty?: EmptyProps;
  allowExport?: boolean;
  allowSelect?: boolean;
  rightClickMenuItems?: (row: T) => (
    | {
        type: "item";
        icon?: LucideIcon;
        label: string;
        onClick: (row: T) => void;
        hidden?: boolean;
        className?: string;
      }
    | {
        type: "separator";
      }
  )[];
};

interface DataTableStoreState {
  lastClickedRow: string | null;
  setLastClickedRow: (id: string) => void;
  scrollPosition: number;
  setScrollPosition: (position: number) => void;
  lastClickedRowIdx: number | undefined;
  setLastClickedRowIdx: (idx: number | undefined) => void;
}

const useDataTableStore = create<DataTableStoreState>((set) => ({
  lastClickedRow: null,
  setLastClickedRow: (id: string) => set({ lastClickedRow: id }),
  lastClickedRowIdx: undefined,
  setLastClickedRowIdx: (idx: number | undefined) =>
    set({ lastClickedRowIdx: idx }),
  scrollPosition: 0,
  setScrollPosition: (position: number) => set({ scrollPosition: position }),
}));

const DataGrid = <T extends object>({
  rows = [],
  columns: allColumns,
  name: tableName,
  label: tableLabel,
  defaultColumns,
  className,
  onCellClick,
  loading = false,
  pagination,
  empty,
  rowKey,
  rowHeight = 35,
  allowExport = true,
  allowSelect = false,
  bulkActions,
  rightClickMenuItems,
}: DataGridProps<T>) => {
  const trpcUtils = trpc.useUtils();
  const gridRef = useRef<DataGridHandle>(null);

  const [selectedRows, setSelectedRows] = useState<Set<number>>(
    (): Set<number> => new Set()
  );
  const [contextMenu, setContextMenu] = useState<{
    row: T;
  } | null>(null);

  const lastClickedRow = useDataTableStore((state) => state.lastClickedRow);
  const setLastClickedRow = useDataTableStore(
    (state) => state.setLastClickedRow
  );
  const lastClickedRowIdx = useDataTableStore(
    (state) => state.lastClickedRowIdx
  );
  const setLastClickedRowIdx = useDataTableStore(
    (state) => state.setLastClickedRowIdx
  );

  const [showViewSettings, setShowViewSettings] = useState(false);

  const { data: columnsProperties, isLoading } =
    trpc.customizations.tables.details.useQuery(tableName);

  const updateTableRealtime = trpc.customizations.tables.update.useMutation({
    onSuccess() {
      return { invalidate: false };
    },
  });
  const updateTableDebounced = debounce(updateTableRealtime.mutate, 1000);

  const processColumns = () => {
    const dbColumns = columnsProperties?.settings.columns || [];
    let updated = allColumns;
    if (dbColumns.length) {
      updated = dbColumns
        .map((dbColumn) => {
          const localColumn = allColumns.find((c) => c.key === dbColumn.key);
          if (localColumn) {
            return { ...localColumn, ...dbColumn } as Column<T>;
          }
          return undefined;
        })
        .filter((column): column is Column<T> => column !== undefined);
    }
    if (defaultColumns?.length && !dbColumns.length) {
      updated = updated.filter((column) => defaultColumns.includes(column.key));
    }
    updated = updated.map((c, index) => ({
      ...c,
      resizable: true,
      cellClass: cn(c.cellClass, index === 0 && "pl-8", "items-center flex"),
      renderHeaderCell: () => (
        <div
          className={cn(
            c.headerCellClass,
            index === 0 && "pl-6",
            "items-center flex gap-1"
          )}
        >
          {c.icon && (
            <c.icon className="h-3.5 w-3.5 text-gray-600 flex-shrink-0" />
          )}
          <p className="truncate">{c.name}</p>
        </div>
      ),
    }));

    if (allowSelect) {
      updated.unshift(SelectColumn);
    }

    return updated;
  };

  const rowsWithIdx = useMemo(() => {
    return rows.map((row, idx) => ({ ...row, idx }));
  }, [rows]);

  const datagrid = (
    <ReactDataGrid
      onCellContextMenu={(cell) => {
        setContextMenu({ row: cell.row });
      }}
      ref={gridRef}
      className="rdg-light"
      rowHeight={rowHeight}
      headerRowHeight={40}
      selectedRows={selectedRows}
      // @ts-ignore
      rowKeyGetter={(r) => r[rowKey]}
      onSelectedRowsChange={setSelectedRows}
      // full height minus pagination height
      style={{ height: `calc(100% - ${pagination ? "40px" : "0px"})` }}
      onCellClick={({ row }) => {
        onCellClick && onCellClick({ row });
        // @ts-ignore
        setLastClickedRow(`${tableName}${row[rowKey].toString()}`);
        setLastClickedRowIdx(row.idx);
      }}
      rowClass={(row) => {
        // @ts-ignore
        return lastClickedRow === `${tableName}${row[rowKey].toString()}`
          ? "bg-[#d5f7e9]"
          : "";
      }}
      renderers={{
        noRowsFallback: (
          //  Cant get correct full width, approximated
          <div className="pt-12" style={{ width: `calc(100vw - 250px)` }}>
            {loading && <FullScreenSpinner />}
            {!loading && rows && rows.length === 0 && empty && (
              <Empty {...empty} className="w-full" />
            )}
          </div>
        ),
      }}
      columns={[
        // @ts-ignore
        ...processColumns(),
        {
          key: "plus",
          name: "",
          // @ts-ignore
          renderHeaderCell: () => (
            <div className="flex items-center h-full">
              <Button
                variant="ghost"
                size="sm"
                onClick={() => setShowViewSettings(true)}
              >
                <Plus className="w-3.5 h-3.5 text-gray-5000" />
              </Button>
            </div>
          ),
          editable: false,
          resizable: false,
          frozen: false,
        },
      ]}
      rows={rowsWithIdx}
      onColumnResize={(index, width) => {
        const columns = produce(processColumns(), (draft) => {
          draft[index].width = width;
        });
        if (columns) {
          updateTableDebounced({
            tableName,
            table: {
              name: tableName,
              label: tableLabel,
              settings: {
                columns: columns.map((c, index) => ({
                  key: c.key,
                  name: c.name.toString(),
                  position: index + 1,
                  hidden: false,
                  frozen: false,
                  width: Number(c.width) || 100,
                })),
              },
            },
          });
        }
      }}
    />
  );

  const datagridWrapped = (
    <RightClickMenu
      contentClassName="w-56"
      disabled={!rightClickMenuItems}
      items={
        rightClickMenuItems && contextMenu
          ? rightClickMenuItems(contextMenu?.row)
              .filter((item) => item.type === "item" && !item.hidden)
              .map((item) => {
                if (item.type === "separator") {
                  return { type: "separator" as const };
                }
                return {
                  className: item.className,
                  type: "item" as const,
                  icon: item.icon,
                  label: item.label,
                  onClick: () => {
                    contextMenu && item.onClick(contextMenu.row);
                    setContextMenu(null);
                  },
                };
              })
          : []
      }
    >
      {datagrid}
    </RightClickMenu>
  );

  useEffect(() => {
    const scrollToLastClickedRow = () => {
      const index = lastClickedRowIdx;
      setLastClickedRowIdx(undefined);
      if (
        gridRef.current &&
        index !== undefined &&
        lastClickedRow &&
        lastClickedRow.includes(tableName)
      ) {
        const maxIndex = rowsWithIdx.length - 1;
        gridRef.current.scrollToCell({
          rowIdx: index + 6 > maxIndex ? maxIndex : index + 6,
          idx: 0,
        });
      }
    };

    // Attempt to scroll immediately
    scrollToLastClickedRow();

    // If immediate scroll fails, try again after a short delay
    const timeoutId = setTimeout(scrollToLastClickedRow, 100);

    return () => clearTimeout(timeoutId);
  }, [lastClickedRowIdx, lastClickedRow, tableName]);

  return isLoading ? (
    <FullScreenSpinner className="h-full" />
  ) : (
    <div className={cn("relative h-full", className)}>
      {!!selectedRows.size && selectedRows.size < 30 && (
        <div className="fixed bottom-9 left-1/2 !z-50 p-3 px-5 border bg-white  rounded-xl shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px] transform flex items-center gap-2 animate-flip-up animate-duration-[500ms]">
          <div className="flex justify-center items-center w-5 h-5 text-sm text-white rounded-md bg-primary-600">
            {selectedRows.size}
          </div>
          {bulkActions?.map((action) => (
            <Button
              className="shadow-md"
              loading={action.loading}
              disabled={action.disabled}
              key={action.label}
              variant={action.variant}
              size="sm"
              icon={action.icon}
              onClick={() => action.onClick(Array.from(selectedRows))}
            >
              {action.label}
            </Button>
          ))}
          <Button
            onClick={() => setSelectedRows(new Set())}
            size="sm"
            variant="ghost"
            className="ml-4"
            icon={X}
          />
        </div>
      )}
      <div className="flex absolute right-5 -top-10 gap-2">
        <Button
          disabled={!allowExport}
          onClick={async () => {
            await exportToExcel(datagrid, tableName);
          }}
          size="sm"
          icon={Sheet}
        >
          Export excel
        </Button>
        <ViewSettingsPopover
          open={showViewSettings}
          onOpenChange={() => setShowViewSettings((prev) => !prev)}
          // @ts-ignore
          restColumns={allColumns.filter(
            (c) => !processColumns().some((pc) => pc.key === c.key)
          )}
          //  @ts-ignore
          showingColumns={processColumns()}
          dbColumns={columnsProperties?.settings.columns || []}
          onChange={(columns) => {
            const updatedValues = {
              tableName,
              table: {
                name: tableName,
                label: tableLabel,
                settings: {
                  columns: columns.map((c, index) => ({
                    key: c.key,
                    name: c.name.toString(),
                    position: index + 1,
                    hidden: false,
                    frozen: c.frozen ?? false,
                    width: Number(c.width) || 100,
                  })),
                },
              },
            };
            trpcUtils.customizations.tables.details.setData(
              tableName,
              (oldData) => {
                return {
                  ...oldData,
                  ...updatedValues.table,
                  id: oldData?.id ?? 0, // Ensure id is always a number
                };
              }
            );
            updateTableDebounced(updatedValues);
          }}
        />
      </div>
      {datagridWrapped}
      {pagination && (
        <div className="flex justify-between items-center pl-5 w-full">
          <div className="w-full">
            {pagination.count} <span className="text-gray-600">count</span>
          </div>
          <Pagination
            className="py-1.5 mt-0"
            {...pagination}
            totalPages={Math.ceil(
              (pagination.count || 1) / pagination.pageSize
            )}
          />
        </div>
      )}
    </div>
  );
};

export default DataGrid;

export { textEditor };
